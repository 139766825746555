import React from "react";

export const Dropdown = ({ options, onChange, disabled, placeholder, label, initialValue,valueProperty = "id", property = "name", required, value }) => {

    return <div className={`form-control skynet-form-input${required ? " required" :""}`}>
            {label && <label>{label}</label>}
            <select disabled={disabled} className="form-control" defaultValue={initialValue ?? -1} value={value} onChange={(e) => onChange(e.target.value)}>
            <option disabled hidden value={-1}>{placeholder}</option>
            {options && Object.values(options)?.map((item, index) => <option key={item[valueProperty]} value={item[valueProperty]}>{item[property]}</option>)}
    </select>
    </div>
}