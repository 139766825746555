
import Card from "./Card";
import {ContentTabs} from "../Tabs/ContentTabs";
export function TabsCard({ tabs, initialTab }) {

    return <>
        <Card>
            <ContentTabs tabs={tabs} initialTab={initialTab} />
        </Card>
    </>
}