import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import VbProductsContainer from './Products/VbProductsContainer'
import VonProductsContainer from './Products/VonProductsContainer'
import ThirdPartyProductsContainer from './Products/ThirdPartyProductsContainer'
import VismaVceModal from '../../Components/Customer/VismaVceModal'
import customerActions from '../../Actions/customerActions'
import commonActions from '../../Actions/commonActions'
import { withTranslation } from 'react-i18next';
import LeadSendEmailModal from '../../Components/Customer/LeadSendEmailModal'
import InternalLeadSendEmailModal from '../../Components/Customer/SendInternalLeadEmailModal'
import actionLogActions from '../../Actions/actionLogActions';
import AOCustomerInformationModal from './AOCustomerInformationModal';
import {
    LonSmart,
    VismaFinanceProductContainer,
    VonAccounts,
    RelatedCustomers,
    CustomerActionHistory,
} from "../../Components/Customer/CustomerComponents";

import AoCustomerInformation from "../../Components/Customer/CustomerComponents/AoCustomerInformation";
import { TabsCard } from '../../Components/Card/TabsCard';
import InyettDetails from '../../Components/Customer/CustomerComponents/InyettDetails';
import ConnectedCustomers from '../../Components/Customer/CustomerComponents/ConnectedCustomers';

import helpers from "../../Utils/helpers";
import { CardContainer, CardRow, DefaultCard} from '../../Components/Card/Card';

import {TransactionServices } from "../../Components/Customer/CustomerComponents/TransactionServices";
import SubscriptionContainer from "./Products/SubscriptionContainer";
import {FeatureFlags, isFeatureEnabled} from "../../Utils/featureFlags";
import {MainInfoContainer} from "./MainInfoContainer";
import InternalInfoContainer from "./InternalInfoContainer";

const availableComponents = {
    "main": { id: "main", title: "maininfocontainer.header" },
    "internal": { id: "internal", title: "internalinfocontainer.header" },
    "inyettdetails": { id: "inyettdetails", title: "inyettdetailscontainer.header" },
    "connectedcustomers": { id: "connectedcustomers", title: "connectedcustomercontainer.header" },
    "vbproducts": { id: "vbproducts", title: "vbproductscontainer.header" },
    "saasproducts": { id: "saasproducts", title: "vonproductcontainer.header" },
    //"subscriptions": { id: "subscriptions", title: "subscriptions.header" }, uncomment when VOSS is enabled
    "lonsmart": { id: "lonsmart", title: "connectedcustomervoncontainer.header" },
    "transactions": { id: "transactions", title: "transactionservices.header" },
    "thirdpartyproducts": { id: "thirdpartyproducts", title: "thirdpartyproductscontainer.thirdpartyproducts" },
    "aocustomerinfo": { id: "aocustomerinfo", title: "aocustomerinformation.cardheader"},
    "vismaFinance": { id: "vismaFinance", title: "vismafinance.header" },
    //"collaborations": { id: "collaborations", title: "vonproducts.collaboration" },
    //"actionhistory": { id: "actionhistory", title: "actionhistory.actionhistory" },
    //"relatedcustomers": { id: "relatedcustomers", title: "relatedcustomerscontainer.relatedcustomer" },
    //"vonaccounts": { id: "vonaccounts", title: "vonaccountscontainer.vonaccounts" },
    "tabscard": { id: "tabscard", title: "tabscard", tabs: [] }
}

const teamLayouts = {
    9196: "smb",
    9583: "smb",
}

const defaultLayout = {
    layoutName: 'default',
    cards: [
        availableComponents.main,
        [
            availableComponents.internal,
            {...availableComponents.tabscard, tabs: [
                    availableComponents.transactions,
                    availableComponents.inyettdetails,
                    availableComponents.connectedcustomers,
                    availableComponents.aocustomerinfo
                ]
            }
        ],
        availableComponents.saasproducts, //Replace with availableComponents.subscriptions when VOSS is enabled
        availableComponents.vbproducts,
        availableComponents.thirdpartyproducts
    ]
}

const smbLayout = {
    layoutName: 'smb',
    cards: [
        availableComponents.main,
        [
            availableComponents.internal,
            {...availableComponents.tabscard, tabs: [
                    availableComponents.connectedcustomers,
                    availableComponents.transactions,
                    availableComponents.inyettdetails,
                    availableComponents.aocustomerinfo
                ]
            }
        ],
        availableComponents.saasproducts, //Replace with availableComponents.subscriptions when VOSS is enabled
        availableComponents.vbproducts,
        availableComponents.thirdpartyproducts
    ]
}

export const agreementsLayout = {
    layoutName: 'agreements',
    cards:
        [
            availableComponents.transactions,
            availableComponents.saasproducts, //Replace with availableComponents.subscriptions when VOSS is enabled
            availableComponents.vbproducts,
            availableComponents.thirdpartyproducts,
        ]

}
const layouts = [defaultLayout, smbLayout, agreementsLayout]

const saveSearchHistory = customer => {
    const storage = localStorage.getItem("CustomerSearchHistory");
    const history = storage ? JSON.parse(storage) : [];
    const customerSearchHistory = {
        customerNumber: customer.customerNumber,
        organizationNumber: customer.organizationNumber,
        name: customer.name
    }

    const index = history.findIndex(item => item.customerNumber === customer.customerNumber)

    if (index >= 0)
        history.splice(index, 1);

    if (history.length === 10)
        history.splice(0, 1);

    history.push(customerSearchHistory);

    localStorage.setItem("CustomerSearchHistory", JSON.stringify(history))
}

class MainContainer extends Component {

    constructor(props) {
        super(props);

        this.initState = {
            showAoLeadEmailModal: false,
            showLeadSendEmailModal: false,
            showVismaVceModal: false,
            showInternalLeadEmailModal: false,
            editInternalInfo: false,
            editContact: false,
            editAOCustomerInfo: false,
            supplier: false,
            assignments: false,
            eekonomi: false
        }

        this.state = this.initState;
    }

    componentDidMount() {
        this.props.customerActions.getCustomer(this.props.customer.customerNumber);
        //this.props.customerActions.getCollaborations(this.props.customer.customerNumber);
        this.props.actionLogActions.getCustomerActionLog(this.props.customer.customerNumber);
        this.props.customerActions.getRelatedCustomers(this.props.customer.customerNumber);
        //this.props.customerActions.getVismaFinanceProducts(this.props.customer.customerNumber); // not working

        if (this.props.customer.hasVonAccount) {
            this.props.customerActions.getVonAccounts(this.props.customer.customerNumber);
        }

        if (helpers.isAoCustomer(this.props.customer)) {
            this.props.commonActions.loadDropdownList("AoSuppliers");
            this.props.customerActions.getAOCustomerInfo(this.props.customer.customerNumber);
        }

        saveSearchHistory(this.props.customer);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.customer.customerNumber !== this.props.customer.customerNumber && helpers.isAoCustomer(this.props.customer)) {
            this.props.customerActions.getAOCustomerInfo(this.props.customer.customerNumber);
        }
    }

    toggleModal = (modalName) => {

        this.setState({
            [modalName]: !this.state[modalName]
        });
    };

    changeEditInternalInfo = () => {
        this.setState({ editInternalInfo: !this.state.editInternalInfo });
    }

    updateContact = () => {
        this.setState({ editContact: !this.state.editContact });
    }

    setHoverState = (hoverType) => {
        this.setState({ [hoverType]: !this.state[hoverType] });
    }

    getTabs(block) {
        let tabs = []
        block.tabs?.map(tab => {
            let component = this.renderTabs(tab);
            if (component)
                tabs.push({
                    name: tab.id,
                    label: this.props.t(tab.title),
                    content: component
                })
        })

        return tabs;
    }
    renderTabs(block) {
        switch(block.id) {
            case "inyettdetails": return <InyettDetails inyettData={this.props.customer.inyettData}/>
            case "transactions": return <TransactionServices customer={this.props.customer} customerActions={this.props.customerActions} commonActions={this.props.commonActions} featureToggles={this.props.featureToggles} toggleModal={this.toggleModal.bind(this)} isProduction={this.props.isProduction}/>
            case "connectedcustomers": return <ConnectedCustomers key={block.id} {...this.props} />
            case "aocustomerinfo": return helpers.isAoCustomer(this.props.customer) && <AoCustomerInformation key={block.id} {...this.props} toggleModal={this.toggleModal.bind(this)} setHoverState={this.setHoverState.bind(this)} hoverState={this.state} />
            default: return <></>
        }
    }
    renderCards(block) {
        switch (block.id) {
            case "main": return <MainInfoContainer key={block.id} customer={this.props.customer} {...this.props} toggleModal={this.toggleModal.bind(this)} />
            case "internal": return <InternalInfoContainer key={block.id} customer={this.props.customer}  {...this.props} editInternalInfo={this.state.editInternalInfo} changeEditInternalInfo={this.changeEditInternalInfo} />
            case "transactions": return <DefaultCard title={this.props.t(block.title)}><TransactionServices customer={this.props.customer} customerActions={this.props.customerActions} commonActions={this.props.commonActions} featureToggles={this.props.featureToggles} toggleModal={this.toggleModal.bind(this)} isProduction={this.props.isProduction}/></DefaultCard>
            //case "vismaFinance": return <VismaFinanceProductContainer key={block.id} {...this.props} />
            case "vbproducts": return <VbProductsContainer key={block.id} />
            case "lonsmart": return <LonSmart key={block.id} {...this.props} />
            case "saasproducts": return !isFeatureEnabled(this.props.user, FeatureFlags.VOSS) ? <VonProductsContainer key={block.id} /> : <SubscriptionContainer key={block.id} /> // TODO: Remove this row when VOSS is enabled
            case "subscriptions": return <SubscriptionContainer key={block.id} />
            case "thirdpartyproducts": return <ThirdPartyProductsContainer key={block.id} />
            //case "collaborations": return <CollaborationsList key={block.id} {...this.props} />
            case "actionhistory": return <CustomerActionHistory key={block.id} {...this.props} />
            case "relatedcustomers": return <RelatedCustomers key={block.id} {...this.props} />
            case "vonaccounts": return <VonAccounts key={block.id} {...this.props} />
            case "tabscard": {
                let tabs = this.getTabs(block)
                return <TabsCard key={block.id} tabs={tabs} initialTab={tabs[0].name}></TabsCard>
            }
            default: return <></>
        }
    }
    renderCardRow(row, index) {
        const hasColumns = row instanceof Array

        let id = row.id ? row.id : row.map(c => c.id)?.join('');
        return (<CardRow orientation={hasColumns ? "horizontal" : ""} key={id}>
            {hasColumns ? row.map((card) => { return this.renderCards(card)}): this.renderCards(row)}
        </CardRow>)
    }
    renderContainer(row) {
        return (<>
                <CardContainer>
                    {row.map((row, index) => this.renderCardRow(row, index))}
                </CardContainer>
            </>
        )
    }

    getLayoutName() {
        if (!this.props.customerInfoOption)
            return teamLayouts[this.props.user?.teamId];

        return this.props.customerInfoOption
    }

    selectLayout() {

        let layoutName = this.getLayoutName();
        if (!layoutName)
            return defaultLayout

        let selectedLayout = layouts.find(e => e.layoutName === layoutName)

        if (selectedLayout)
            return selectedLayout
        else
            return defaultLayout
    }

    render() {

        const layout = this.selectLayout();

        return (
            <>
                {this.state.showLeadSendEmailModal && <LeadSendEmailModal onClose={this.toggleModal.bind(this, "showLeadSendEmailModal")} />}
                {this.state.showVismaVceModal && <VismaVceModal onClose={this.toggleModal.bind(this, "showVismaVceModal")} />}
                {this.state.showInternalLeadEmailModal && <InternalLeadSendEmailModal onClose={this.toggleModal.bind(this, "showInternalLeadEmailModal")} />}
                {this.state.editAOCustomerInfo && <AOCustomerInformationModal {...this.props} onClose={this.toggleModal.bind(this, "updateAOCustomerInfoModal")} />}
                {this.renderContainer(layout.cards)}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
        customer: state.CustomerReducer.selectedCustomer,
        isCustomerValid: state.CustomerReducer.isValid,
        dropdownlists: state.CommonReducer.dropdownlists,
        userSalesNumber: state.CommonReducer.user && state.CommonReducer.user.salesNumber,
        //collaborations: state.CustomerReducer.collaborations,
        actionLogs: state.ActionLogReducer.actionLogs,
        relatedCustomers: state.CustomerReducer.relatedCustomers,
        vonAccounts: state.CustomerReducer.vonAccounts,
        customerForm: state.CommonReducer.forms && state.CommonReducer.forms.customerFormData,
        updatedInfo: state.CommonReducer.forms && state.CommonReducer.forms.updateCustomerForm,
        aoCustomerInfo: state.CustomerReducer.aoCustomerInfo,
        vismaFinance: state.CustomerReducer.vismaFinance,
        vismaFinanceProductLoading: state.CustomerReducer.vismaFinanceProductLoading,
        isProduction: state.CommonReducer.isProduction,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        actionLogActions: bindActionCreators(actionLogActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MainContainer));