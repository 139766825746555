import React from 'react';
import PastActionInfo from '../PastActionInfo'
import { useTranslation } from 'react-i18next';
import AdditionalVonLicensesDropdown from './AdditionalVonLicensesDropdown';
import {NoInformation} from "../Panel";
import SkynetIcon from "../Fundamentals/SkynetIcon";

const VonProducts = ({ products, pendingTerminationRequests, onButtonClick, customerGroup, vonAccounts, getEndDate }) => {
    const { t } = useTranslation();

    if (!products || products.length < 1)
        return <NoInformation />

    let key = 1;

    const showCollaborationColumn = products.some(item => item.isCollaboration);

    const disableTerminationButton = (item) => {

        if (customerGroup == 1800 || customerGroup == 1040 || item.isCollaboration || !item.allowToTerminate) {
            return true;
        }

        return false;
    }
    const productUsers = (product) => {

        var userList = [];

        if (vonAccounts) {
            vonAccounts.map((vonAccount) => {
                var role = vonAccount.roles?.find(x => x.productName === product.product);
                if (role) {
                    var productUser = { name: vonAccount.name, email: vonAccount.email, role: role.role, isPrimaryContact: vonAccount.isPrimaryContact }
                    userList.push(productUser)
                }
            })

            return userList;
        }
    }

    const getTeminationColumn = (item) => {

        if (item.vonApplicationTerminatedAfterCurrentPeriod)
            return "Terminated after current period"


        if (disableTerminationButton(item) || item.isTrial) {
            return "Termination not allowed"
        }

        return <a onClick={() => onButtonClick('terminate', item)}>{t('vonproducts.cancelagreement')} {renderLastActionInfo(pendingTerminationRequests, item)}</a>
    }


    return products &&
        <div className="table-responsive">
            <table className="table table-stripe table-condensed">
                <thead>
                <tr>
                    <th className="text-left">{t('vonproducts.program')}</th>
                            <th className="text-left">{t('vonproducts.module')}</th>
                            <th width="130" className="text-left">{t('vonproducts.users')}</th>
                            <th width="130" className="text-left">{t('vonproducts.purchasedate')}</th>
                            <th width="130" className="text-left">{t('vonproducts.enddate')}</th>
                            {showCollaborationColumn && <th width="130" className="text-left">{t('vonproducts.collaboration')}</th>}
                            <th width="200" className="text-left">{t('vonproducts.period')}</th>
                            <th width="50" className="text-left">{t('vonproducts.termination')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map(item =>
                            <tr key={key++}>
                                <td className="text-left text-nowrap">{item.product}</td>
                                <td className="text-left text-nowrap">{item.module}</td>
                                <td className="text-left">{item.licenses > 0 &&
                                    <AdditionalVonLicensesDropdown users={productUsers(item)} title={item.licenses} defaultStyle={true}/>}</td>
                                <td className="text-left text-nowrap">{item.isTrial ? "Trial" : item.purchaseDate}</td>
                                <td className="text-left text-nowrap">{getEndDate(item)}</td>
                                {showCollaborationColumn &&
                                    <td className="text-center pt-4">
                                        {item.isCollaboration && <SkynetIcon icon="vismaicon-filled vismaicon-success" title={t('vonproducts.mustendcollaboration')}/>}
                                    </td>
                                }
                                {!item.isTrial && item.period ?
                                    <td className="text-left" width="100">
                                        {item.period}
                                        {item.allowToChangePeriod && <SkynetIcon icon="vismaicon-sm vismaicon-edit ml-8 icon-neutral" onClick={() => onButtonClick('period', { number: item.productNumber, name: item.module, period: item.period })} />}
                                    </td>
                                    :
                                    <td></td>
                                }
                                <td className="text-left text-nowrap">{getTeminationColumn(item)}</td>
                            </tr>)
                        }
                    </tbody>
            </table>
        </div>
}

const renderLastActionInfo = (pendingTerminationRequests, product) => {
    const activity = pendingTerminationRequests?.find((r) => r.productNumber === product.productNumber);
    if (!activity) {
        return null;
    }

    return <PastActionInfo date={activity.sentDate} sender={activity.sender} />
}

export default VonProducts;