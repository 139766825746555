import React from 'react'
import { connect } from 'react-redux';
import customerActions from '../../Actions/customerActions'
import { bindActionCreators } from 'redux'
import LeftNavigationBarItem from '../LeftNavigationBarItem'
import routes from '../../Constants/routes'
import { withTranslation } from 'react-i18next'
import CheckAddressModal from '../Customer/CheckAddressModal'
import { useLocation } from 'react-router-dom';

function CustomerLinks({customerActions, customer: {customerNumber, isNonInteractive, organizationNumber}, customer, openHeader, updatedInfo, t, setHeader}) {

    const location = useLocation();

    function openCheckAddressModal() {
        customerActions.getCustomerInfoForUpdate(organizationNumber)
    }

    function getLinks() {
        return [
            {
                title: t('leftnavigation.main'), id: "main", onClick: () => setHeader("main"), subItems: [
                    {  title: t('leftnavigation.customer'), path: routes.customerDetails(customerNumber)},
                    !isNonInteractive && { title: t('leftnavigation.editcustomer'), path: routes.customerEdit(customerNumber) },
                    { title: t('leftnavigation.relatedcustomers'), path: routes.relatedCustomers(customerNumber) },
                    !isNonInteractive && { title: t('leftnavigation.updateaddress'), onClick: () => openCheckAddressModal() },
                    { title: t('leftnavigation.agreements'), path: routes.customerAgreements(customerNumber) }
                ]
            }
        ];
    }
    
    return <>
            {getLinks().filter(link => !link.disabled).map((item, index) => {
                return <LeftNavigationBarItem key={index} link={item} location={location} openHeader={openHeader} />
            })}
            <CheckAddressModal customer={customer} updatedInfo={updatedInfo} />
            </>
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.selectedCustomer,
        updatedInfo: state.CommonReducer.forms && state.CommonReducer.forms.updateCustomerForm,
        openHeader: state.CommonReducer.openHeader
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerLinks));