import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import routes from "../../Constants/routes";
import SendDemoEmailModal from "../../Components/Customer/SendDemoEmailModal";
import { withTranslation } from "react-i18next";
import UserLinks from "../NavigationLinks/UserLinks";
import { bindActionCreators } from "redux";
import SendDesktopVersionModal from "../Customer/SendDesktopVersionModal";
import UserSearchModal from "../Customer/UserSearchModal";
import NonCustomerEmailModal from "../NonCustomerEmailModal";
import SendMovieModal from "../Customer/SendMovieModal";
import TopNavigationBarItem from "../TopNavigationBarItem";
import helpers from "../../Utils/helpers";
import customerActions from "../../Actions/customerActions";
import TopNavigationSearch from "../Search/TopNavigationSearch";
import {UserRoles, Access} from '../../Constants/userRoles';
import {hasAccess, hasAdminAccess} from "../../Utils/permissionUtils";

function TopNavigation({ openAppointmentsCount, openLeadsCount, user, t, customerActions }) {

    const navigate = useNavigate();

    const leftNav = useRef(null);
    const rightNav = useRef(null);
    const thresholdsRef = useRef(null);
    const leftThresholdRef = useRef(null);
    const rightThresholdRef = useRef(null);

    const hamburgerNavWidth = 370;
    const mobileThreshold = 590;
    const extraWidth = 30;
    const initialLinkIndex = 10;

    const [activeModal, setActiveModal] = useState("");
    const [linkIndex, setLinkIndex] = useState(initialLinkIndex);
    const [mobileView, setMobileView] = useState(false);

    function handleResize() {
        if (rightThresholdRef.current && leftThresholdRef.current && thresholdsRef.current) {
            const innerWidth = window.innerWidth;

            let linkIndex = thresholdsRef.current.findIndex(element => innerWidth <= (element + hamburgerNavWidth));
            setLinkIndex(linkIndex);

            setMobileView(innerWidth <= mobileThreshold);
        }
    }

    useEffect(() => {
        setThresholds();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [])

    function setThresholds() {
        setTimeout(() => {
            setLinkIndex(initialLinkIndex);
            setMobileView(false);

            let thresholds = [];

            let brandheader = document.getElementById("navbar-header-id");
            let current = brandheader.getBoundingClientRect().width + extraWidth;

            if (leftNav.current) {
                let links = leftNav.current.getElementsByClassName('text-nowrap');
                for (let i = 0; i < links.length; i++) {
                    current += links[i].getBoundingClientRect().width
                    thresholds.push(current)
                    leftThresholdRef.current = current;
                }
                thresholds.push(Number.MAX_SAFE_INTEGER);
                thresholdsRef.current = thresholds
            }

            if (rightNav.current) {
                let rightNavRect = rightNav.current.getBoundingClientRect().width + extraWidth;
                rightThresholdRef.current = rightNavRect;
            }
            handleResize();
        }, 500);
    }

    function getLinks() {
        let items = [
            {
                title: t("topnavigation.appointments"),
                path: routes.myAppointments,
                badgeNumber: openAppointmentsCount
            },
            { title: t("topnavigation.actionlog"), path: routes.myActions },
            {
                title: t("topnavigation.leads"),
                path: routes.leads,
                badgeNumber: openLeadsCount,
                disabled: !hasAccess(user, UserRoles.Spcs, Access.ReadWrite)
            },
            { title: t("topnavigation.quotation"), path: routes.quotation },
            { title: t("topnavigation.internalleads"), path: routes.internalLeads },
            { title: t("topnavigation.sendmail"), dropdownItems: getSendMailLinks() },
            { title: t("topnavigation.customerdirectory"), path: routes.customerDirectoryContainer, disabled: !hasAccess(user, UserRoles.AOSales, Access.ReadWrite) },
            { title: t("topnavigation.discounts"), path: routes.discounts, disabled: !hasAccess(user, UserRoles.Discounts, Access.Admin) }

        ];

        return items.filter((link) => !link.disabled);
    }

    function getSendMailLinks() {
        let items = [
            {
                title: t("topnavigation.senddemo"),
                link: window.location.pathname,
                modalName: "showSendDemoEmailModal",
                onClick: () => toggleModal("showSendDemoEmailModal")
            },
            {
                title: t("topnavigation.senddesktopversion"),
                link: window.location.pathname,
                modalName: "showSendDesktopVersionModal",
                onClick: () => toggleModal("showSendDesktopVersionModal")
            },
            {
                title: t("topnavigation.noncustomermail"),
                link: window.location.pathname,
                modalName: "showNonCustomerEmailModal",
                onClick: () => toggleModal("showNonCustomerEmailModal")
            },
            {
                title: t("topnavigation.sendmovie"),
                link: window.location.pathname,
                modalName: "showSendMovieModal",
                onClick: () => toggleModal("showSendMovieModal")
            },
        ];
        return items;
    }

    function getIconLinks() {
        let items = [
            {
                title: t("topnavigation.alert"),
                dropdownItems: getNotifications().map((item, index) => {
                    item.classList = item.read ? "" : "bold"
                    item.path = item.link
                    item.onClick = () => onHandleNotification(index, item)
                    return item;
                }),
                badgeNumber: notificationsCount(),
                icon: "vismaicon-alert"
            },
            {
                title: t("topnavigation.usersearch"),
                icon: "vismaicon-users",
                path: window.location.pathname,
                modalName: "showUserSearchModal",
                onClick: () => toggleModal("showUserSearchModal")
            },
            {
                title: t("topnavigation.productoverview"),
                icon: "vismaicon-library",
                path: routes.productOverview,
                disabled: !hasAdminAccess(user, UserRoles.ProductOverview)
            },
            {
                title: t("topnavigation.status"),
                icon: "vismaicon-info",
                path: routes.status
            }
        ]

        if (Object.values(UserRoles).some(role => hasAccess(user, role, Access.Admin))) {
            items.push({
                title: t("topnavigation.settings"),
                icon: "vismaicon-settings",
                path: routes.settings
            });
        }

        return items.filter(item => !item.disabled);
    }

    function getBurgerMenu() {
        let items = getBurgerLinks();

        let itemsWithBadgeNumber = items.filter((item) => item.badgeNumber && item.badgeNumber > 0);
        let badgeNumber = helpers.getArrayPropertyTotals(itemsWithBadgeNumber, "badgeNumber");

        let burgerMenu = [{
            icon: "vismaicon-burger",
            dropdownItems: items,
            badgeNumber: badgeNumber,
            className: "skynet-w-35"
        }]
        return burgerMenu;
    }

    function getBurgerLinks() {
        let links = getLinks()
            .filter((link, index) => index >= linkIndex);
        let iconLinks = getIconLinks();

        return links.concat(iconLinks);
    }

    const toggleModal = (modalName) => {
        if (activeModal === modalName) {
            setActiveModal("");
        } else {
            setActiveModal(modalName);
        }
    };

    function onHandleNotification(index, notification) {
        updateReadNotification(notification.id);
        navigate(notification.link);
    }

    const updateReadNotification = (id) => {
        let notifications = getNotifications();
        let index = notifications.findIndex(i => i.id === id);

        if (index !== -1) {
            notifications[index].read = true;
            saveNotifications(notifications);
        }
    }

    const getNotifications = () => {
        return localStorage.Notifications ? JSON.parse(localStorage.Notifications) : [];
    }

    const notificationsCount = () => {
        return getNotifications().filter(element => element.read === false).length;
    }

    const saveNotifications = (notifications) => {
        localStorage.Notifications = JSON.stringify(notifications);
    }

    const onSearch = (customerNumber) => {
        if (customerNumber) {
            customerActions.getCustomer(customerNumber)
            navigate(routes.customerMain(customerNumber));
        }
    }

    return (
        <header className="navbar navbar-default d-flex" id="vudNavTopMain" role="navigation">
            <div className="navbar-header" id="navbar-header-id">
                <div className="navbar-brand">
                    <Link to='/search/'>SPCS Skynet</Link>
                </div>
            </div>
            {user && (
                <nav className="collapse navbar-collapse d-flex skynet-topnav-search" aria-label="Menu">
                    <ul className="nav navbar-nav navbar-left nav-tabs first-level d-flex" role="menubar" ref={leftNav}>
                        {
                            getLinks()
                                .filter((link, index) => index < linkIndex)
                                .map((item, index) => {
                                    return (<TopNavigationBarItem key={index} item={item} isMainDropDown={!!item.dropdownItems} />);
                                })}

                    </ul>
                    {<ul className="nav navbar-nav nav-tabs navbar-right first-level context-selector d-flex" ref={rightNav}>
                        <TopNavigationSearch onSearch={onSearch} />
                        {getBurgerMenu().map((item, index) => {
                            return (<TopNavigationBarItem key={index} item={item} isTopNav={true} isMainDropDown={true} />
                            )
                        })}
                        {user && <UserLinks location={window.location} showIcon={mobileView} />}
                    </ul>}
                </nav>
            )}
            {activeModal === "showSendDemoEmailModal" && (
                <SendDemoEmailModal
                    onClose={toggleModal.bind(this, "showSendDemoEmailModal")}
                />
            )}
            {activeModal === "showSendDesktopVersionModal" && (
                <SendDesktopVersionModal
                    onClose={toggleModal.bind(
                        this,
                        "showSendDesktopVersionModal"
                    )}
                />
            )}
            {activeModal === "showUserSearchModal" && (
                <UserSearchModal onClose={toggleModal.bind(this, "showUserSearchModal")} />
            )}
            {activeModal === "showNonCustomerEmailModal" && (
                <NonCustomerEmailModal onClose={toggleModal.bind(this, "showNonCustomerEmailModal")} />
            )}
            {activeModal === "showSendMovieModal" && (
                <SendMovieModal onClose={toggleModal.bind(this, "showSendMovieModal")} />
            )}
        </header>
    );
}

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
        openAppointmentsCount: state.AppointmentReducer.openAppointmentsCount,
        openLeadsCount: state.LeadsReducer.openLeadsCount
    };
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TopNavigation));
