import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import customerActions from '../../Actions/customerActions'
import routes from '../../Constants/routes';
import helpers from '../../Utils/helpers';
import commonActions from '../../Actions/commonActions'
import { useParams, useNavigate  } from 'react-router-dom'
import {NoInformation} from "../../Components/Panel";

const AttendedOnboardingsContainer = ({ customerActions, customer, attendedOnboardings, t}) => {

    const { customerNumber } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        customerActions.getCustomerAttendedOnboardings(customerNumber);
    }, [])

    const onOnboardingClick = (onboarding) => {
        customerActions.getOnboardingFromId(onboarding.id);
        navigate(routes.editOnboarding(customer.customerNumber, onboarding.id));
    }

    if (!attendedOnboardings || attendedOnboardings.length === 0) 
        return <NoInformation/>

    return <div className="table-responsive">
            <table className="table table-stripe">
                <thead>
                    <tr>
                        <th className="text-left">{t('attendedonboardingslist.contactperson')}</th>
                        <th className="text-left">{t('attendedonboardingslist.service')}</th>
                        <th className="text-left">{t('attendedonboardingslist.date')}</th>
                        <th className="text-left">{t('attendedonboardingslist.evaluationsent')}</th>
                    </tr>
                </thead>
                <tbody>
                    {attendedOnboardings.map((onboarding, index) => {
                        return <tr key={index} onClick={() => onOnboardingClick(onboarding)}>
                            <td>{onboarding.contactPerson}</td>
                            <td>{onboarding.serviceName}</td>
                            <td>{helpers.formatDate(onboarding.fromDate)}</td>
                            <td>{onboarding.evaluationSent ? helpers.formatDate(onboarding.evaluationSent) : ""}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
}

function mapStateToProps(state) {
    return {
        attendedOnboardings: state.CustomerReducer.attendedOnboardings,
        customer: state.CustomerReducer.selectedCustomer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AttendedOnboardingsContainer));

