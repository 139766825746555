import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { UserRoles} from '../Constants/userRoles';
import './Order/OrderContainer.css'
import ProductSearch from '../Components/Order/ProductSearch';
import ProductDetailsContainer from './Order/ProductDetailsContainer';
import OrderDetailsContainer from './Order/OrderDetailsContainer';
import CustomerProducts from '../Components/Order/CustomerProducts';
import CustomerInfo from '../Components/CustomerInfo';
import OrderActions from '../Components/Order/OrderActions'
import routes from '../Constants/routes'
import orderActions from '../Actions/orderActions'
import withModalForm from '../Components/HOC/withModalForm'
import QuotationModal from '../Components/QuotationModal'
import commonActions from '../Actions/commonActions'
import { useParams, useNavigate } from 'react-router-dom';
import system from '../Constants/system'
import * as calcTypes from '../Constants/priceCalculationTypes'
import Panel, { PanelGrid } from "../Components/Panel";
import MessageAlertLevel from "../Components/MessageAlertLevel";
import { hasReadWriteAccess} from "../Utils/permissionUtils";

const OrderContainer = ({ isModalActive, onFieldChange, onCloseClick, onOpenModal, quotationCustomerForm, order, t, commonActions, orderActions, user, pushedQuotation, isVonEnabled, orderId, customer, searchResults, orderForm, product }) => {

    const { leadId } = useParams();
    const navigate = useNavigate();
    
    useEffect(() => {
        orderActions.startOrder(customer.customerNumber, leadId, pushedQuotation);

        return () => {
            orderActions.clearOrder();
            orderActions.clearPushedQuotation();
        }
    }, [])

    const onSearch = (query) => {
        orderActions.search(order.customerNumber, query, isVonEnabled)
    }

    const onProductClick = (productId, sourceSystem) => {
        if (sourceSystem === system.Von)
            orderActions.getVonProductDetails(productId, customer.customerNumber, 0, orderId);
        else if (sourceSystem === system.Vb)
            orderActions.getProductDetails(productId, customer.customerNumber, 0, null, null, orderId);
        else if (sourceSystem === system.CompanyExpense)
            orderActions.getCompanyExpenseProductDetails(productId, customer.customerNumber, -1, orderId);
        else if (sourceSystem === system.Blikk)
            orderActions.getBlikkProductDetails(productId, customer.customerNumber, orderId);
    }

    const navigateAfterAction = (customerNumber) => {
        return () => navigate(routes.customerDetails(customerNumber));
    }
    
    const onCancel = () => {
        commonActions.setOpenHeader('main')
        orderActions.cancel(order.orderId, order.customerNumber, navigateAfterAction(order.customerNumber));
    }

    const navigateToConfirmationMail = (customerNumber) => {
        return (newOrderId) => navigate(routes.orderConfirmationEmail(customerNumber, newOrderId))
    }

    const onComplete = () => {
        orderActions.complete(order.orderId, order.customerNumber, orderForm, navigateToConfirmationMail(order.customerNumber));
    }

    const onSendApprovalRequest = () => {
        orderActions.sendApprovalRequest(order.orderId, order.customerNumber, orderForm, navigateAfterAction(order.customerNumber))
    }

    const useExistingQuotation = (currentOrder, conflictingId) => {
        orderActions.getQuotationOrder(currentOrder, order.customerNumber, conflictingId);
    }

    const navigateToCustomerMain = (customerNumber) => {
        return () => navigate(routes.customerMain(customerNumber))
    }

    const parseValidationRules = (orderLimitationReason) => {
        
        if (customer.isNonInteractive) 
            return "ordercontainer.noninteractive"

        switch (orderLimitationReason) {
            case 'BadGroupType':
                return 'ordercontainer.badgrouptype'
            case 'BadStatus':
                return 'ordercontainer.badstatus'
            case 'AOProduct':
                if (hasReadWriteAccess(user, UserRoles.AOSales))
                    break;
                return 'ordercontainer.aoproduct'
            case 'AOCentralAgreement':
                return 'ordercontainer.aocentralagreement'
            case 'NoMainCustomer':
                return 'ordercontainer.nomaincustomer'
            default:
                return null;
        }
    }
    
    const message = parseValidationRules(customer.orderLimitationReason);

    if (message) 
        return <Panel><MessageAlertLevel message={t(message)}/></Panel>

    if (!orderId || !customer) {
        return null;
    }

    const existingQuotationInlineStyle = {
        marginBottom: "10px",
        marginLeft: "5px"
    }

    var quotationModalAttr = {
        onChange: onFieldChange,
        onClose: onCloseClick,
        onLoad: () => orderActions.startQuotationModal(order, quotationCustomerForm, true),
        onSave: (form) => orderActions.sendQuotation(form, onCloseClick, true, navigateToCustomerMain(customer.customerNumber))
    }
    
    const orderDetails = order && order.orderlines && order.orderlines.length > 0;
    
    return <div className="row">
            <PanelGrid columnWidth={8}>
                <ProductSearch title={t('ordercontainer.productsearch')} results={searchResults} onSearch={onSearch.bind(this)} onProductClick={onProductClick.bind(this)} isVonEnabled={isVonEnabled} />
                {product && <ProductDetailsContainer title={t('productdetailscontainer.header')} genericActions={orderActions} priceCalcType={calcTypes.priceCalculationTypes.order} />}
                {orderDetails && <OrderDetailsContainer genericActions={orderActions} priceCalcType={calcTypes.priceCalculationTypes.order} />}
                {order && order.conflictingQuotationOrderId > 0 && !order.status !== 4 &&
                    <div className="btn-toolbar">
                        <div style={existingQuotationInlineStyle}>
                            <strong>{t('ordercontainer.usequotation')}</strong>
                        </div>
                        <button type="button" className="btn btn-primary" onClick={() => useExistingQuotation(order, order.conflictingQuotationOrderId)}>{t('ordercontainer.usequotationbutton')}</button>
                    </div>
                }

                <OrderActions onSendApprovalRequest={onSendApprovalRequest.bind(this)} order={order} customer={customer}
                              onSendQuotation={onOpenModal} onComplete={onComplete.bind(this)}
                              onCancel={onCancel.bind(this)}
                              disabled={!order || (order.orderlines && order.orderlines.length === 0)}
                              priceCalcType={calcTypes.priceCalculationTypes.order} pushedQuotation={pushedQuotation}
                              quotationCurrentlyInProgress={order && order.quotationInProgress}
                              orderStatus={order && order.status}/>
                {isModalActive('quotation') && <QuotationModal {...quotationModalAttr} />}

            </PanelGrid>
            <PanelGrid columnWidth={4}>
                <CustomerProducts title={t('customerproducts.products')} customer={customer} />
            </PanelGrid>
        </div>
}

function mapStateToProps(state) {
    return {
        searchResults: state.OrderReducer.searchResults,
        orderId: state.OrderReducer.orderId,
        order: state.OrderReducer.order,
        isVonEnabled: state.OrderReducer.isVonEnabled,
        customer: state.CustomerReducer.selectedCustomer,
        orderForm: state.CommonReducer.forms && state.CommonReducer.forms.orderForm,
        pushedQuotation: state.OrderReducer.pushedQuotation,
        user: state.CommonReducer.user,
        product: state.OrderReducer.product && state.OrderReducer.product
    }
}

function mapDispatchToProps(dispatch) {
    return {
        orderActions: bindActionCreators(orderActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default withModalForm(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderContainer)));