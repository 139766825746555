import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import commonActions from '../../../Actions/commonActions'
import customerActions from '../../../Actions/customerActions'

import withModalForm from '../../../Components/HOC/withModalForm'
import { withTranslation } from 'react-i18next';

import VbProducts from '../../../Components/Customer/VbProducts'
import ProductTerminationModal from '../../../Components/Customer/ProductTerminationModal'
import VbProductDetailsModal from '../../../Components/Customer/VbProductDetailsModal'

import SubscriptionCard from "../../../Components/Card/SubscriptionCard";
import InactiveProducts from '../../../Components/Products/InactiveProducts';

class VbProductsContainer extends Component {

    constructor(props) {
        super(props);
        this.initState = {
        }

        this.state = this.initState;
    }


    getEndDate = (item) => {
        return item.endDate === '0001-01-01' ? 'N/A' : item.endDate;
    }

   filterProducts = (products, active) => {
        let today = new Date(new Date().toDateString());
        return products.filter((product) => active ? product.isProductValid || new Date(product.endDate) >= today : (!product.isProductValid && new Date(product.endDate) < today));
    }

    render() {
        const { customer, customerActions, reasons, terminationForm, t } = this.props;
        const { payload, isModalActive, onFieldChange, onCloseClick, onOpenModal } = this.props;

        const product = payload;

        var modalAttr = {
            form: terminationForm,
            reasons: reasons,
            onChange: onFieldChange,
            onClose: onCloseClick,
            onLoad: () => customerActions.showCancelVbAgreementModal(product, customer),
            onSave: () => customerActions.cancelVbAgreement(customer.customerNumber, terminationForm, () => onCloseClick(terminationForm.id))
        }

        // allowCancelVbAgreement is true for now. Should be solved in backend later
        
        return <>
            <SubscriptionCard 
                title={t("vbproductscontainer.header")}
                activeContent={<VbProducts products={this.filterProducts(customer.vbProducts, true)} onButtonClick={onOpenModal} allowCancelVbAgreement={true} customer={customer} getEndDate={this.getEndDate} />}
                inactiveContent={<InactiveProducts products={this.filterProducts(customer.vbProducts, false)} getEndDate={this.getEndDate} />}
                >
            </SubscriptionCard>
            {isModalActive('terminate') && <ProductTerminationModal {...modalAttr} />}
            {isModalActive('productdetails') && <VbProductDetailsModal onClose={onCloseClick} product={product} />}
        </>
    }
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.selectedCustomer,
        reasons: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists['VbTerminationReasons'],
        terminationForm: state.CommonReducer.forms && state.CommonReducer.forms.cancelVbAgreement,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default withModalForm(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VbProductsContainer)));