import routes from '../Constants/routes'
import moment from 'moment'


const helpers = {
    validateForm(state, returnFieldErrorFlags) {
        let errorMessagesArr = [];
        let fieldErrorFlags = {};

        if (!state.validationRules) {
            return null;
        }

        state.validationRules.map(fieldValidation => {


            helpers.applyMultipleFieldValidation(state, fieldValidation, errorMessagesArr, fieldErrorFlags);

            const { inputId } = fieldValidation;
            const value = state.data[inputId] || '';

            if (typeof value === 'undefined') {
                const errorMessage = `Data model does not match schema. ${inputId} is missing`;
                helpers.addErrMessage(errorMessagesArr, errorMessage);
                fieldErrorFlags[inputId] = errorMessage;
                return false;
            }

            const fieldValue = value.toString().trim();

            helpers.applyRequiredValidation(fieldValue, fieldValidation, errorMessagesArr, fieldErrorFlags);
            helpers.applyRegExValidation(fieldValue, fieldValidation, errorMessagesArr, fieldErrorFlags);

            return true;
        });

        if (!errorMessagesArr.length) {
            return null;
        }

        const errorMessage = errorMessagesArr.join('\n');

        if (returnFieldErrorFlags)
            return { errorMessage, fieldErrorFlags };

        return errorMessage;
    },

    applyRequiredValidation(value, validation, errArr, fieldErrorFlags) {
        const result = (validation.rule === 'required' && !value);

        if (result) {
            const errorMessage = validation.message;
            helpers.addErrMessage(errArr, errorMessage);
            fieldErrorFlags[validation.inputId] = errorMessage;
        }
    },

    applyRegExValidation(value, validation, errArr, fieldErrorFlags) {
        if (validation.rule === 'required' || (value === '' && !validation.require) ) {
            return;
        }

        const re = new RegExp(validation.rule);

        const result = re.test(value.toString());

        if (!result) {
            const errorMessage = validation.message;
            helpers.addErrMessage(errArr, errorMessage);
            fieldErrorFlags[validation.inputId] = errorMessage;
        }
    },

    applyMultipleFieldValidation(state, validation, errArr, fieldErrorFlags) {
        if (validation.rule === "multiple") {
            const hasError = validation.fields.some(field => {
                const value = state.data[field.inputId] || '';
                return !value || value === "";
            });

            if (hasError) {
                validation.fields.forEach(field => {
                    const errorMessage = validation.message;
                    helpers.addErrMessage(errArr, errorMessage);
                    fieldErrorFlags[field.inputId] = errorMessage;
                });
            }
        }
    },


    addErrMessage: (errArr, errMessage) => {
        errArr.push(errMessage);
    },

    getCurrentDate() {
        return this.formatDate(new Date())
    },

    formatDate(date) {
        date = new Date(date)
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        const yyyy = date.getFullYear();

        if (dd < 10) {
            dd = '0' + dd
        }

        if (mm < 10) {
            mm = '0' + mm
        }
        const formatted = yyyy + '-' + mm + '-' + dd;
        return formatted;
    },

    formatDateAndTime(date) {
        date = new Date(date)

        if (date.getTime() <= new Date(0).getTime())
            return ""

        return date.toLocaleDateString("sv-SE") + " " + date.toLocaleTimeString("sv-SE", { hour: '2-digit', minute: '2-digit' });
    },

    formatArrayDates(array, property) {
        if (array && property) {
            return array.map(date => {
                return Object.assign({}, date, {
                    [property]: new Date(date[property])
                });
            });
        }
    },

    createBookingLink(appointment) {
        var date = moment(appointment.time).format().replace(/[^A-Z0-9]+/ig, "")
        var link = `https://www.google.com/calendar/render?action=TEMPLATE&text=Återkomst&details=${window.location.origin + routes.appointmentDetails(appointment.id)}&location=&dates=${date}Z/${date}`;

        return link
    },

    formatPhoneNumberForCountry(phoneNumber) {

        if (phoneNumber === null)
            return null;

        const firstCharacter = phoneNumber.charAt(0);

        if (firstCharacter === "+")
            return phoneNumber;

        const parsed = parseInt(firstCharacter, 10);

        if (isNaN(parsed))
            return false;

        if (parsed !== 0)
            return "+46" + phoneNumber;

        const formated = "+46" + phoneNumber.substr(1);

        return formated;
    },

    checkForInvalidDate(date) {
        if (date === '1900-01-01')
            return '';

        return date;
    },

    currencyFormatter(number) {
        return new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(number);
    },

    getDropdownItemName(list, value) {
        if (!list) {
            return "Error";
        }
        list = list.filter(item => item.id === value || item.guid === value);
        if (list.length === 1)
            return list[0].name;
        return "<Missing value>";
    },

    getFullApiHostname() {
        return `https://${this.getApiHostname()}`;
    },

    getApiHostname() {
        const host = window.location.hostname;
        let port = window.location.port.length > 1 ? `:${window.location.port}` : '';

        if (host === 'localhost') {
            port = ':5001';
        }

        return `${host}${port}`;
    },

    getArrayPropertyTotals(array, property) {
        return array.reduce(function (a, b) {
            return a + b[property];
        }, 0);
    },

    getArrayPropertyMaximum(array, property) {
        return Math.max.apply(Math, array.map(function (o) {
            return o[property];
        }))
    },

    getArrayPropertyMinimum(array, property) {
        return Math.min.apply(Math, array.map(function (o) {
            return o[property];
        }))
    },

    objectArrayContainsValue(array, property, value) {
        return array.filter(e => e[property] === value).length > 0;
    },

    filterObjectArrayByValue(array, property, value) {
        return array.filter(e => e[property].toLowerCase().indexOf(value.toLowerCase()) !== -1);
    },

    filterVonAccountArrayByValue(array, value) {
        return array.filter(e => e.roles && e.roles.some(p => p.productName.toLowerCase().includes(value.toLowerCase()) || e.roles.some(r => r.role.toLowerCase().includes(value.toLowerCase()))));
    },

    getUrlQueryParameter(location, param) {
        const params = new URLSearchParams(location.search);
        return params.get(param);
    },

    validateExternalForm(state) {
        let errorMessagesArr = [];

        if (!state.validationRules) {
            return null;
        }

        state.validationRules.map(fieldValidation => {

            if (typeof state.data[fieldValidation.inputId] === 'undefined') {
                helpers.addErrMessage(errorMessagesArr, `Data model does not match schema. ${fieldValidation.inputId} is missing`);
                return false;
            }

            let fieldValue = state.data[fieldValidation.inputId] || '';

            fieldValue = fieldValue && fieldValue.toString().trim();

            helpers.applyRequiredValidation(fieldValue, fieldValidation, errorMessagesArr);
            helpers.applyRegExValidation(fieldValue, fieldValidation, errorMessagesArr);

            return true;
        });

        if (state.data.selectedExternalPartner != null) {
            state.data.selectedExternalPartner.questions.map(question => {
                var answer = question.answers.find(answer => answer.id === question.selectedAnswer)

                if (answer === undefined) {
                    helpers.addErrMessage(errorMessagesArr, 'Answer to "' + question.text + '" is required')
                    return false;
                }

                if (answer.isCustomAnswer && (answer.customAnswerText === null || answer.customAnswerText === "")) {
                    helpers.addErrMessage(errorMessagesArr, 'Answer to "' + question.text + '" is blank')
                    return false;
                }
            })
        }

        if (!errorMessagesArr.length) {
            return null;
        }

        const errorMessage = errorMessagesArr.map(message => { return message }).join('\n')

        return errorMessage;
    },

    calculateByteToRightFormat(byte) {
        const mb = byte / 1024 / 1024;

        if (mb > 1024) {
            const gb = mb / 1024;
            return `${Math.round(gb)} GB`
        }

        return `${Math.round(mb)} MB`
    },

    formatComma(decimal) {
        return decimal.replace(",", ".")
    },

    isAoCustomer(customer) {
        return (customer.group >= 1800 && customer.group <= 1899)
    },

    scrollToBottom() {
        return window.scrollTo({
                top: document.body.scrollHeight,
                left: 0,
                behavior: "smooth",
            })
    }
}

export default helpers;