import { FormInput } from "../../../FormComponents/Input/Input";
import { Checkbox } from "../../../FormComponents/Checkbox";
import { VonAccessRights } from "../../../../Constants/VON/EmployeeForm/vonAccessRights";
import { VonEmployeeTypes } from "../../../../Constants/VON/EmployeeForm/vonEmployeeTypes";

export default function VonEditEmployeeDetailsForm({ editVonEmployeeForm, onChange }) {

    if (!editVonEmployeeForm) return null;
    const { data } = editVonEmployeeForm;

    const onCheckPrimaryContact = (value) => {

        if (value) {
            onChange('editVonEmployeeForm', VonAccessRights.Administrator, 'onlineRoleId');
            onChange('editVonEmployeeForm', VonEmployeeTypes.User.id, 'employeeTypeId');
            onChange('editVonEmployeeForm', data.employeeId, 'newMainContactId');
        } else {
            return onChange('editVonEmployeeForm', null, 'newMainContactId');
        }
    }

    const disabled = data.isPrimaryContact || data.employeeTypeId == VonEmployeeTypes.Conversion.id

    return <div>
        <fieldset className="d-grid col-lg-6 gap-16 mb-0">
            <legend>Användaruppgifter</legend>
            <FormInput label='Förnamn' disabled value={data.firstName} />
            <FormInput label='Efternamn' disabled value={data.lastName} />
            <FormInput label='E-postadress' disabled value={data.email} />
            <Checkbox label={"Markera användaren som Vismas huvudkontakt"} checked={data.isPrimaryContact || data.newMainContactId} disabled={disabled} onChange={(value) => onCheckPrimaryContact(value)} />
        </fieldset>
    </div>
}