import React from 'react'
import ConnectedCustomerDetails from './ConnectedCustomerDetails'
import { useTranslation } from 'react-i18next';

const SubCustomerDetails = ({subCustomers, title}) => {
    const { t } = useTranslation();

    if(!subCustomers || subCustomers.length < 1) 
        return null;

    return  <div className="customerConnections subCustomers">
                    <h4><span className="vismaicon vismaicon-sm vismaicon-users" /> {t(title + '.header')}<small>{t(title + '.subheader')}</small></h4>
                    { subCustomers.map((subCustomer, index) => {
                        return  <ConnectedCustomerDetails key={index} customer={subCustomer} />
                    })}
                </div>
}

export default SubCustomerDetails;