import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import LeftNavigationBarItem from '../LeftNavigationBarItem'
import routes from '../../Constants/routes'

class InyettLinks extends Component {

    getLinks() {
        const { customer: { customerNumber }, t } = this.props;

        return [
            {
                title: t('leftnavigation.inyettdetails'), id: "inyett", onClick: () => this.props.setHeader("inyett"), path: routes.inyettdetails(customerNumber)
            }
        ];
    }


    render() {

        return <>
                {this.getLinks().filter(link => !link.disabled).map((item, index) => {
                    return <LeftNavigationBarItem key={index} link={item} location={this.props.location} openHeader={this.props.openHeader} />
                })}
            </>
    }
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.selectedCustomer,
        openHeader: state.CommonReducer.openHeader
    }
}   

export default connect(mapStateToProps)(withTranslation()(InyettLinks));