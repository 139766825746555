import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

import VbProductDetails from './VbProductDetailsContainer'
import VonProductDetails from './VonProductDetailsContainer'
import BlikkProductDetails from './BlikkProductDetails'

import StaticTextInput from '../../Components/StaticTextInput'
import helper from '../../Utils/helpers'
import MessageAlertLevel from '../../Components/MessageAlertLevel'
import MessageInfoLevel from '../../Components/MessageInfoLevel'

import Modal from '../../Components/Modal'
import PriceExplanation from '../../Components/Order/PriceExplanation'

import orderActions from '../../Actions/orderActions'
import commonActions from '../../Actions/commonActions'
import quotationActions from '../../Actions/quotationActions'

import system from '../../Constants/system'

import AlertSmall from '../../Components/AlertSmall';
import TrialSearch from '../../Components/Order/TrialSearch';

import TextInput from '../../Components/TextInput';
import * as formValidationRules from '../../Constants/formValidationRules'

import moment from 'moment';

import * as calcTypes from '../../Constants/priceCalculationTypes'
import HorizontalText, { HorizontalRow } from "../../Components/HorizontalText";

class ProductDetailsContainer extends Component {

  componentDidMount() {
    this.initCustomerForm();
  }

  initCustomerForm() {
    const customerFields = {
      orgNr: '',
      emailAddress: ''
    }
    if (!this.props.quotationCustomerForm)
      this.props.commonActions.startFormEditing('quotationCustomerForm', customerFields, formValidationRules.quotationCustomer);
  }

  saveChanges = (quotationFromOrderContainer) => {
    if (this.props.priceCalcType === calcTypes.priceCalculationTypes.quotation) {
      const addOrderLineFunc = (orderId) => {
        this.props.genericActions.addOrderLine(orderId)
      }

      if (!this.props.orderId) {
        const quotationCustomerForm = this.props.quotationCustomerForm;
        this.props.genericActions.startOrder(-1, quotationCustomerForm, addOrderLineFunc);
      }
      else
        addOrderLineFunc(this.props.orderId)
    }
    else
      this.props.genericActions.addOrderLine(this.props.orderId, quotationFromOrderContainer)
  }

  cancelChanges = () => {
    //this.props.orderActions.hideProductDetails()
    this.props.genericActions.hideProductDetails()
  }

  onTrialSearch(query) {
    this.props.genericActions.trialSearch(query);
  }

  onTrialClick(tenantGuid) {
    const { customer, orderId, product } = this.props;

    this.props.genericActions.getBlikkProductDetails(product.productId, customer.customerNumber, orderId, tenantGuid);
  }

  renderCustomerFields() {
    const { t } = this.props;
    const quotationCustomerForm = this.props.quotationCustomerForm;

    var disabled = this.props.order && this.props.order.orderlines && this.props.order.orderlines.length > 0

    if (disabled)
      return null;

    return (
      <div>
        <TextInput label={t('productdetailscontainer.orgnumber')} value={quotationCustomerForm.data.orgNr} onChange={this.onFieldChange.bind(this, 'orgNr')} />
        <TextInput label={t('productdetailscontainer.emailaddress')} value={quotationCustomerForm.data.emailAddress} onChange={this.onFieldChange.bind(this, 'emailAddress')} />
      </div>
    )
  }

  onFieldChange(field, value) {
    const { commonActions } = this.props;

    commonActions.changeMultiFormField('quotationCustomerForm', field, value)
  }
  
  renderQuotationButton(product, editOrderline) {
    const { t } = this.props

    return <button type="button" className="btn btn-primary"
                   disabled={!product.orderingValidation.successful && !editOrderline}
                   onClick={() => this.saveChanges(true)}>{editOrderline ? t("productdetailscontainer.savechanges") : t("productdetailscontainer.addtoquotation")}
    </button>
  }

  renderButtons() {
    const { editOrderline, product, t } = this.props;

    if (this.props.priceCalcType === calcTypes.priceCalculationTypes.quotation) {
      return (
        this.renderQuotationButton(product, editOrderline)
      )
    }
    else {
      if (this.props.order.orderlines.length === 0) {
        return (
          this.firstButtonRender(editOrderline, product)
        )
      }
      else {
        return (<>
          {!this.props.order.quotationInProgress &&
            <>
              <button type="button" className="btn btn-primary" disabled={!product.orderingValidation.successful} onClick={() => this.saveChanges(false)}>{editOrderline ? t("productdetailscontainer.savechanges") : t("productdetailscontainer.addtoorder")}</button>
              {!product.orderingValidation.successful && <span style={{ paddingLeft: 10 }}><MessageAlertLevel message={product.orderingValidation.message} /></span>}
            </>
          }
          {this.props.order.quotationInProgress &&
            this.renderQuotationButton(product, editOrderline)
          }
        </>
        )
      }
    }
  }

  firstButtonRender(editOrderline, product) {
    const { t } = this.props
    return (
      <>
        <button type="button" className="btn btn-primary" disabled={!product.orderingValidation.successful} onClick={() => this.saveChanges(false)}>{editOrderline ? t("productdetailscontainer.savechanges") : t("productdetailscontainer.addtoorder")}</button>
        {!product.orderingValidation.successful && <span style={{ paddingLeft: 10 }}><MessageAlertLevel message={product.orderingValidation.message} /></span>}
        {this.renderQuotationButton(product, editOrderline)}
      </>
    )
  }

  hasUnpaidInvoices(product) {

    if (product.hasUnpaidInvoices && (!product.dueDate || (product.dueDate && !moment(product.dueDate).isAfter(moment().startOf('day'))))) {
      return true;
    }

    return false;
  }

  render() {
    const { product, sourceSystem, editOrderline, commonActions, orderId, customer, quotationCustomerForm, t } = this.props;
    if (!product) {
      return null;
    }

    if (editOrderline) {
      product.orderingValidation.successful = true;
    }

    const modalProps = {
      component: PriceExplanation,
      title: t('productdetailscontainer.priceexplanation'),
      data: product,
      readonly: true,
      onCancelClick: () => commonActions.showModal(false)
    }

    const showProductdetails = !(sourceSystem === system.Blikk && !product.blikkAccountFound || !product.orderingValidation.successful);

    return <>
        {sourceSystem === system.Blikk && (!product.blikkAccountFound || product.isTrial) && product.orderingValidation.successful && <>
          <AlertSmall alert={{ type: "info", title: t('productdetailscontainer.notfound'), message: t('productdetailscontainer.searchtrial') }} />
          <div className="panel panel-default">
            <div className="panel-heading">{t('productdetailscontainer.searchtrialheader')}</div>
            <div className="panel-body">
              <TrialSearch results={this.props.trialSearchResults} onSearch={this.onTrialSearch.bind(this)} onTrialClick={this.onTrialClick.bind(this)} />
            </div>
          </div>
        </>}

        {!product.orderingValidation.successful && <AlertSmall alert={{ type: "warning", title: product.orderingValidation.message }} />}
        {showProductdetails && <>
                {sourceSystem === system.Vb && <VbProductDetails genericActions={this.props.genericActions} product={product} customerNumber={customer ? customer.customerNumber : ""} orderId={orderId} />}
                {sourceSystem === system.Von && <VonProductDetails calcType={this.props.priceCalcType} genericActions={this.props.genericActions} product={product} customer={customer} customerNumber={customer ? customer.customerNumber : ""} orderId={orderId} />}

                {sourceSystem === system.Blikk && product.blikkAccountFound && <BlikkProductDetails genericActions={this.props.genericActions} priceCalcType={this.props.priceCalcType} product={product} customer={customer} orderId={orderId} />}

          <div className="row">
            <div className="col-6">
              <h3>{t('productdetailscontainer.totals')}</h3>
              <hr />
              <HorizontalRow>
                <HorizontalText columns={1} label={t('productdetailscontainer.initialprice')}
                                value={helper.currencyFormatter(product.totalInitialPrice)}/>
                <HorizontalText columns={1} label={t('productdetailscontainer.nextprice')}
                                value={helper.currencyFormatter(product.totalRecurringPrice)}/>
              </HorizontalRow>

              <br/>
              {this.props.priceCalcType === calcTypes.priceCalculationTypes.quotation && this.renderCustomerFields()}
              <div className="padding-top btn-toolbar">
                {!this.hasUnpaidInvoices(product) && this.renderButtons()}

                {editOrderline && <button type="button" className="btn btn-default"
                                          onClick={this.cancelChanges}>{t('productdetailscontainer.cancel')}</button>}

                {product.orderingValidation && product.orderingValidation && product.orderingValidation.successful && sourceSystem !== system.Blikk &&
                    <button type="button" className="btn btn-default" onClick={() => commonActions.showModal(true)}>
                      <span
                          className="vismaicon vismaicon-sm vismaicon-filled vismaicon-help"></span>{t('productdetailscontainer.explainprice')}
                    </button>}

                {product.orderingValidation && product.orderingValidation.successful && product.orderingValidation.message && 
                    <MessageAlertLevel message={product.orderingValidation.message} />}
                    
                {this.hasUnpaidInvoices(product) && <>
                  <div>{t('productdetailscontainer.unpaidinvoice')}</div>
                  <div>{t('productdetailscontainer.contactcustomeradmin')}</div>
                </>}
              </div>
            </div>
          </div>
          <Modal {...modalProps} />
       
          {sourceSystem === system.Blikk && product.isTrial && <MessageAlertLevel message={t('productdetailscontainer.trialwillbeactivated')} />}
        </>}
      </>
  }
}

function mapStateToProps(state) {
  return {
    product: state.OrderReducer.product && state.OrderReducer.product,
    sourceSystem: state.OrderReducer.sourceSystem && state.OrderReducer.sourceSystem,
    orderId: state.OrderReducer.orderId,
    order: state.OrderReducer.order,
    editOrderline: state.OrderReducer.editOrderline,
    customer: state.CustomerReducer.selectedCustomer,
    trialSearchResults: state.OrderReducer.trialSearchResults,
    quotationCustomerForm: state.CommonReducer.forms && state.CommonReducer.forms.quotationCustomerForm
  }
}

function mapDispatchToProps(dispatch) {
  return {
    orderActions: bindActionCreators(orderActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    quotationActions: bindActionCreators(quotationActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProductDetailsContainer)); 