import React, { Component, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import BokaMeraCalendar from '../../Components/Customer/BokaMeraCalendar'

import routes from '../../Constants/routes'

import customerActions from '../../Actions/customerActions'
import commonActions from '../../Actions/commonActions'
import Loader from '../../Components/Loader'
import BokaMeraBookModal from '../../Components/Customer/BokaMeraBookModal'
import { useNavigate } from 'react-router-dom';

function OnboardingContainer({ customerActions, bokaMeraServices, serviceId, t, weekNo, bokaMeraTimes, customer }) {
    const [showBokaMeraModal, setShowBokaMeraModal] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState('All');
    const [selectedValue, setSelectedValue] = useState('default');
    const navigate = useNavigate();

    useEffect(() => {
        customerActions.getBokaMeraServices();
        customerActions.getBokaMeraCustomFields();

        return () => {
            customerActions.clearBokaMeraTimes();
        };
    }, []);

    const onChange = (event) => {
        let serviceId = event.target.value;
        if (!isNaN(serviceId)) {
            setSelectedValue(event.target.value);
            customerActions.getBokaMeraTimes(serviceId, 0);
        } else {
            customerActions.clearBokaMeraTimes();
        }
    };

    const onSelectGroup = (event) => {
        customerActions.clearBokaMeraTimes();
        setSelectedValue('default');
        setSelectedGroup(event.target.value);
    };

    const showService = (group) => {
        if (
            selectedGroup === 'All' ||
            (group === null && selectedGroup === 'Other') ||
            (group === selectedGroup)
        ) {
            return true;
        }
    };

    const onWeekChange = (weekNo) => {
        customerActions.getBokaMeraTimes(serviceId, weekNo);
    }

    const onClick = (fromDateTime, toDateTime, availableResources) => {
        const currentService = bokaMeraServices.Results.filter(s => s.id == serviceId)[0];
        customerActions.setBokaMeraModalObject(fromDateTime, toDateTime, currentService, availableResources);
        setShowBokaMeraModal(true);
    };

    const onCloseClick = (weekNo) => {
        navigate(routes.onboarding);
        customerActions.getBokaMeraTimes(serviceId, weekNo);
    };

    const getOnboardingPrice = (service) => {
        if (!service.prices.length > 0) {
            return "";
        }

        return " - " + service.prices[0].priceText;
    };

    const services = bokaMeraServices;

    if (!services)
        return null;

    return <>
            <div>
                <a href="https://vismaspcs.se/utbildning" target="_blank" rel="noopener noreferrer">{t('onboardingcontainer.onboardinginformation')}</a>
            </div>
            <br />
            <div>
                <div>
                    <select name="" onChange={onSelectGroup.bind(this)} value={selectedGroup} className="form-control">
                        <option value={"All"}>{t('onboardingcontainer.allservices')}</option>
                        {services.groups.map((group) => <option key={group} value={group}>{group}</option>)}
                    </select>
                </div>
                <br />
                <div>
                    <select name="" onChange={onChange.bind(this)} value={selectedValue} className="form-control">
                        <option value="default">{t('onboardingcontainer.selectone')}</option>
                        {services.Results.filter(s => !s.onlyVisibleByAdmin && s.active && showService(s.group))
                            .map((service) => <option key={service.id} value={service.id}>{service.name + getOnboardingPrice(service)}</option>)}
                    </select>
                </div>
            </div>
            <Loader />
            <BokaMeraCalendar bokaMeraTimes={bokaMeraTimes}
                redirect={navigate}
                customerNumber={customer.customerNumber}
                weekNo={weekNo}
                onWeekChange={onWeekChange.bind(this)}
                onClick={onClick.bind(this)}
            />

            {showBokaMeraModal && <BokaMeraBookModal onClose={setShowBokaMeraModal.bind(this, !showBokaMeraModal)} onComplete={() => onCloseClick()} />}
        </>
}


function mapStateToProps(state) {
    return {
        bokaMeraServices: state.CustomerReducer.bokaMeraServices,
        bokaMeraTimes: state.CustomerReducer.bokaMeraTimes,
        serviceId: state.CustomerReducer.bokaMeraServiceId,
        weekNo: state.CustomerReducer.bokaMeraWeekNo,
        customer: state.CustomerReducer.selectedCustomer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OnboardingContainer));