import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next';

import customerActions from '../../Actions/customerActions'
import commonActions from '../../Actions/commonActions'

import InvoiceList from '../../Components/Customer/InvoiceList'
import EmailTemplate from '../../Components/EmailTemplate'
import Modal from '../../Components/Modal'

import withModalForm from '../../Components/HOC/withModalForm'
import SendInvoiceCreditModal from '../../Components/Customer/SendInvoiceCreditModal'
import Panel from '../../Components/Panel';

function InvoicesContainer({customerActions, commonActions, invoiceForm, invoices, customer, sendInvoiceCreditForm, reasons, payload, onCloseClick, isModalActive, onOpenModal})
{
    useEffect(() => {
        customerActions.getCustomerInvoices(customer.customerNumber);
    }, []);

    const onSendClick = () => {
        customerActions.sendInvoiceEmail(invoiceForm);
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing(invoiceForm.id);
        commonActions.showModal(false);
    }

    const onFieldChange = (formId, field, value) => {
        commonActions.changeMultiFormField(formId, field, value)
    }

    const onOpenFormClick = (solumInvoiceOCR, vbInvoiceOCR, invoiceNumber, id) => {
        customerActions.getInvoiceEmailTemplate(customer.customerNumber, solumInvoiceOCR, vbInvoiceOCR, invoiceNumber, id)
    }

    const renderNoData = () => {
        return (<p>{t('invoicescontainer.noentriesfound')}</p>);
    }

    const { t}  = useTranslation();

    const invoice = payload;

    if (!invoices)
        return null;

    const sendInvoiceModalProps = {
        component: EmailTemplate,
        title: t('invoicescontainer.sendinvoice'),
        mainButtonTitle: t('invoicescontainer.send'),
        data: invoiceForm && invoiceForm.data,
        bodyHeight: 200,
        onChange: (field, value) => commonActions.changeMultiFormField(invoiceForm.id, field, value),
        onSaveClick: onSendClick,
        onCancelClick: onCancelClick
    }

    const sendInvoiceCreditModalProps = {
        form: sendInvoiceCreditForm,
        invoice: invoice,
        onChange: onFieldChange,
        onClose: onCloseClick,
        onLoad: () => customerActions.showInvoiceCreditModal(customer, invoice),
        onSave: (form, body) => customerActions.sendInvoiceCredit(customer.customerNumber, form, body, () => onCloseClick())
    }

    return <Panel title={t('invoicescontainer.header')}>
        {invoices.length < 1 ? renderNoData() : <>
            <InvoiceList invoices={invoices} customerNumber={customer.customerNumber} onSendClick={onOpenFormClick} onCreditClick={onOpenModal} />
            <Modal {...sendInvoiceModalProps} />
            {isModalActive('sendInvoiceCredit') && <SendInvoiceCreditModal {...sendInvoiceCreditModalProps} />}
        </>}
    </Panel>;
}

function mapStateToProps(state) {
    return {
        invoices: state.CustomerReducer.invoices,
        showModal: state.CommonReducer.showModal,
        customer: state.CustomerReducer.selectedCustomer,
        invoiceForm: state.CommonReducer.forms && state.CommonReducer.forms.invoiceEmail,
        sendInvoiceCreditForm: state.CommonReducer.forms && state.CommonReducer.forms.sendInvoiceCreditForm,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default withModalForm(connect(mapStateToProps, mapDispatchToProps)(InvoicesContainer));