import { useEffect } from "react";
import { FormInput } from "../../../FormComponents/Input/Input";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import routes from "../../../../Constants/routes";
import * as formValidationRules from '../../../../Constants/formValidationRules'

function VonEmployeeCheck({ commonActions, customerActions, vonEmployeeCheckForm, customer }) {

    const navigate = useNavigate();

    useEffect(() => {
        commonActions.startFormEditing("vonEmployeeCheckForm", {}, formValidationRules.VonEmployeeCheckForm);

        return () => {
            commonActions.cancelFormEditing('vonEmployeeCheckForm');
        }
    }, []);

    const onChange = (value) => {
        commonActions.changeMultiFormField('vonEmployeeCheckForm', 'email', value);
    };

    const onSubmit = () => {
        customerActions.beginNewVonEmployeeCreation(vonEmployeeCheckForm);
    };

    if (!vonEmployeeCheckForm) return null;

    const { data } = vonEmployeeCheckForm;

    return <div>
        <div>
            <p>Ange e-postadressen på den användare som du vill skapa</p>
        </div>
        <fieldset className="d-grid col-lg-6 gap-24 mb-0">
            <fieldset className="d-grid col-lg-6 gap-16 mb-0">
                <FormInput label='E-postadress' onChange={(value) => onChange(value)} value={data.email} required />
            </fieldset>
            <div>
                <button className='btn btn-default' onClick={() => onSubmit()}>Fortsätt</button>
                <button className='btn btn-default' onClick={() => { navigate(routes.customerVonAccounts(customer.customerNumber)) }}>Avbryt</button>
            </div>
        </fieldset>
    </div>
}

const mapStateToProps = (state) => {
    return {
        customer: state.CustomerReducer.selectedCustomer,
        vonEmployeeCheckForm: state.CommonReducer.forms && state.CommonReducer.forms.vonEmployeeCheckForm
    };
};

export default connect(mapStateToProps)(VonEmployeeCheck);