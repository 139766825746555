import { settingsActionTypes as actionTypes } from '../ActionTypes/settingsActionTypes'
import { httpFetch } from '../Utils/httpUtils'

import commonActions from './commonActions'

import helpers from '../Utils/helpers'
import * as formValidationRules from '../Constants/formValidationRules'

const settingsActions = {

    getEloquaFields() {
      return(dispatch) => {
          return httpFetch(`/api/eloqua/GetEloquaFields`, dispatch)
              .then(json => {
                  dispatch({ type: actionTypes.GET_ELOQUA_FIELDS, data: json });
              })
      }
    },

    getEloquaDefinitions() {
      return(dispatch) => {
          return httpFetch(`/api/eloqua/GetEloquaDefinitions`, dispatch)
              .then(json => {
                  dispatch({ type: actionTypes.GET_ELOQUA_DEFINITIONS, data: json });
              })
      }
    },

    getEloquaSyncLogs(syncUri, callback) {
        return(dispatch) => {
            return httpFetch(`/api/eloqua/GetEloquaSyncLogs${syncUri}`, dispatch)
                .then(json => {
                    callback();
                    dispatch({ type: actionTypes.GET_ELOQUA_SYNC_LOGS, data: json });
                })
        }
    },

    updateEloquaSyncStatus(syncUri, callback) {

        return(dispatch) => {
            const httpRequest = {
                method: 'PUT',
            }

            return httpFetch(`/api/eloqua/UpdateEloquaSyncStatus${syncUri}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Status updated successfully'));
                    callback();
                })
        }
    },

    runEloquaJob() {
        const httpRequest = {
            method: 'PUT',
        }

        return dispatch => {
            return httpFetch('/api/eloqua/RunJob', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Jobbet har startats'));
                })

        }
    },

    saveFields(callback) {
        return(dispatch) => {
            const httpRequest = {
                method: 'PUT',
            }

            dispatch(commonActions.progressLoader(true));

            return httpFetch('/api/eloqua/SaveFields', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Fälten uppdateras från Eloqua nu'));
                    callback(json);
                })
        }
    },

    updateSyncStatusForAll() {
        return(dispatch) => {
            const httpRequest = {
                method: 'PUT',
            }

            dispatch(commonActions.progressLoader(true));

            return httpFetch('/api/eloqua/UpdateAllsyncs', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Syncs are now updated'));
                    getEloquaDefinitions();
                })
        }

    },

    createImportDefinition(callback) {
        return(dispatch) => {
            const httpRequest = {
                method: 'PUT',
            }

            dispatch(commonActions.progressLoader(true));

            return httpFetch('/api/eloqua/CreateImportDefinition', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Importdefinitionerna är nu uppdaterade'));
                    callback(json);
                })
        }
    },

    updateEloquaField(eloquaField, save = true) {
        return(dispatch) => {

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(eloquaField)
            }

            if(save)
                return httpFetch('/api/eloqua/UpdateEloquaField', dispatch, httpRequest)
                    .then(json => {
                        dispatch({ type: actionTypes.UPDATE_ELOQUA_FIELD, data: eloquaField });
                    })
            else {
                // will only update state without saving in backend
                dispatch({ type: actionTypes.UPDATE_ELOQUA_FIELD, data: eloquaField });
            }
        }
    },

    getGuidedSellingRules() {
        return (dispatch) => {
            return httpFetch(`/api/settings/getGuidedSellingRules`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.GET_GUIDED_SELLING_RULES, data: json });
                })
        }
    },

    getGuidedSellingRule(id) {
        return (dispatch) => {
            return httpFetch(`/api/settings/getGuidedSellingRule/${id}`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing("editGuidedRule", json, null));
                    dispatch({ type: actionTypes.GET_GUIDED_SELLING_RULE, data: json });
                })
        }
    },

    updateGuidedSellingRule(form, callback) {
        return dispatch => {

            const payload = Object.assign({}, form.data, { "availableSuggestions": null })

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(payload)
            }

            return httpFetch('/api/settings/updateGuidedSellingRule', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.cancelFormEditing("editGuidedRule"));
                    dispatch(commonActions.showSuccess('Guided Selling rule has been updated'));

                    callback();
                })
        }
    },

    getEmailTemplates() {
        return (dispatch) => {
            return httpFetch(`/api/settings/getEmailTemplates`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_EMAIL_TEMPLATES, data: json });
                })
        }
    },

    getEmailTemplate(id) {
        return (dispatch) => {
            return httpFetch(`/api/settings/getEmailTemplate/${id}`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing("editEmailTemplate", json, null));
                    dispatch({ type: actionTypes.STORE_EMAIL_TEMPLATE, data: json });
                    dispatch({ type: actionTypes.STORE_EMAIL_TEMPLATE_APPROVAL, data: json });
                })
        }
    },

    getServiceAddresses(){
        return (dispatch) => {
            return httpFetch(`/api/settings/getServiceAddresses/`, dispatch)
                .then(json => {
                    dispatch({type: actionTypes.STORE_SERVICE_ADDRESSES, data: json});
                })
        }
    },

    approveEmailTemplate(id) {
        return (dispatch) => {
            return httpFetch(`/api/settings/approveEmailTemplate/${id}`, dispatch)
            .then(json => {
                dispatch(settingsActions.getEmailTemplateApproval(id))
            })
        }
    },

    getEmailTemplateApproval(id){
        return (dispatch) => {
            return httpFetch(`/api/settings/getEmailTemplate/${id}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_EMAIL_TEMPLATE_APPROVAL, data: json });
                })
        }
    },

    getEditRoleModel(){
        return (dispatch) => {
            return httpFetch(`/api/settings/getEditRoleModel/`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_EDIT_ROLE_MODEL, data: json });
                })
        }
    },

    getAllEditRoleModel(){
        return (dispatch) => {
            return httpFetch(`/api/settings/getAllEditRoleModel/`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_EDIT_ROLE_MODEL, data: json });
                })
        }
    },

    getUsers(){
        return (dispatch) => {
            return httpFetch(`/api/user/getAllUsers/`, dispatch)
                .then(json => {
                    dispatch(settingsActions.getAllUserRoles())
                    dispatch({ type: actionTypes.STORE_USERS, data: json });
                })
        }
    },

    getUserForRoleEdit(id){
        return (dispatch) => {
            return httpFetch(`/api/settings/getUserForRoleEdit/${id}`, dispatch)
                .then(json => {
                    dispatch(settingsActions.getAllUserRoles())
                    dispatch(commonActions.startFormEditing("adminUserEdit", json))
                })
        }
    },

    getTeam(id){
        return (dispatch) => {
            return httpFetch(`/api/user/getTeamById/${id}`, dispatch)
                .then(json => {
                    dispatch(settingsActions.getAllUserRoles())
                    dispatch(commonActions.startFormEditing("adminUserEdit", json))
                })
        }
    },

    getUsersForBudget() {
        return (dispatch) => {
            return httpFetch(`/api/settings/getUsersForBudget/`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_BUDGET_USERS, data: json });
                })
        }
    },

    getTeamsForBudget() {
        return (dispatch) => {
            return httpFetch(`/api/settings/getTeamsForBudget/`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_BUDGET_TEAMS, data: json });
                })
        }
    },

    getBudgetUser(id){
        return (dispatch) => {
            return httpFetch(`/api/user/getUserById/${id}`, dispatch).then(json => {
                    dispatch({type: actionTypes.GET_BUDGET_USER, data: json});
                    dispatch(settingsActions.getAllUserRoles());
                })
        }
    },

    getAllUserRoles(){
        return dispatch => {
            return httpFetch(`/api/user/getAllUserRoles`, dispatch)
                .then(json => {
                    dispatch({type: actionTypes.STORE_USER_ROLES, data: json})
                })
        }
    },

    runUserSynchronization() {
        const httpRequest = {
            method: 'PUT',
        }

        return dispatch => {
            return httpFetch(`/api/user/UserSynchronization`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess(`Synkronisering påbörjad`));
                })
        }
    },

    updateEmailTemplate(form, callback) {
        return dispatch => {

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch('/api/settings/updateEmailTemplate', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.cancelFormEditing("editEmailTemplate"));
                    dispatch(commonActions.showSuccess('Email template has been updated'));

                    callback();
                })
        }
    },

    assignTemplateResponsible(templateId, userName, callback){
        
        const httpRequest = {
            method: 'POST'
        }

        return (dispatch) => {
            return httpFetch(`/api/settings/assignTemplateResponsible/${templateId}/${userName}`, dispatch, httpRequest)
            .then(json => {
                dispatch({type: actionTypes.ASSIGN_TEMPLATE_RESPONSIBLE, data: json})

                if(callback)
                    callback()
            })
        }
    },

    openOrderConfirmationPopup(data) {
        return dispatch => {
            dispatch(commonActions.startFormEditing('editOrderConfirmation', data, null))
            dispatch(commonActions.showModal(true))
        }
    },

    updateOrderConfirmation(form, callback) {
        return dispatch => {

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data.body)
            }

            return httpFetch(`/api/settings/updateOrderConfirmation/${form.data.id}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.cancelFormEditing("editOrderConfirmation"));
                    dispatch(commonActions.showSuccess('Order confirmation template has been updated'));

                    callback();
                })
        }
    },

    uploadLeadsList(file) {
        return dispatch => {

            dispatch(commonActions.progressLoader(true));

            const formData = new FormData();
            formData.append("file", file);

            const httpRequest = {
                method: 'POST',
                body: formData
            }

            return httpFetch(`/api/settings/uploadLeadsList`, dispatch, httpRequest, true)
                .then(json => {
                    dispatch(commonActions.showSuccess('Lead List has been succesfully imported!'));
                })
        }
    },

    startInternalCustomerCreateForm(data) {
        return (dispatch) => {
            dispatch(commonActions.startFormEditing("internalCustomerForm", data, formValidationRules.InternalCustomerCreateForm))
        }
    },

    createInternalCustomer(form, callback) {
        return dispatch => {

            dispatch(commonActions.progressLoader(true));

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                dispatch(commonActions.progressLoader(false));

                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch(`/api/customer/createInternal`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.progressLoader(false));
                    dispatch(commonActions.showSuccess('Internal customer has been succesfully created!'));
                    callback(json);
                })
        }
    },

    startWebinarCreateForm(data) {
        return (dispatch) => {
            dispatch(commonActions.startFormEditing("webinarForm", data, formValidationRules.WebinarCreateForm))
            dispatch(commonActions.loadDropdownList("WebinarProducts"));
        }
    },

    getWebinars() {
        return (dispatch) => {
            return httpFetch(`/api/settings/getWebinars`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.GET_WEBINARS, data: json });
                })
        }
    },

    getWebinar(id) {
        return (dispatch) => {
            return httpFetch(`/api/settings/getWebinar/${id}`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing("editWebinar", json, formValidationRules.editWebinar));
                    dispatch(commonActions.loadDropdownList("WebinarProducts"));
                    dispatch({ type: actionTypes.STORE_WEBINAR, data: json });
                })
        }
    },

    createWebinar(form, callback) {
        return dispatch => {
            dispatch(commonActions.progressLoader(true));

            const validationResult = helpers.validateForm(form);

            if(validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                dispatch(commonActions.progressLoader(false));

                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch('/api/settings/createWebinar', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.progressLoader(false));
                    dispatch(commonActions.showSuccess('Webinar has been successfully created!'));
                    callback(json);
                })
        }
    },

    updateWebinar(form, callback) {
        return dispatch => {

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch(`/api/settings/updateWebinar/`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.cancelFormEditing("editWebinar"));
                    dispatch(commonActions.showSuccess('Webinar has been updated'));

                    callback();
                })
        }
    },

    deleteWebinar(id, callback) {
        return (dispatch) => {

            const httpRequest = {
                method: 'POST',
            }

            return httpFetch(`/api/settings/deleteWebinar/${id}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.cancelFormEditing("editWebinar"));
                    dispatch(commonActions.showSuccess('Webinar has been deleted'));

                    callback();
                })
        }
    },

    getCustomerInfoTypesByCustomerNumber(customerNumber, callback){
        return (dispatch) => {
            return httpFetch(`/api/settings/getcustomerinfotypes/${customerNumber}`, dispatch)
            .then(json => {
                if(json.customerInfoFlags == null)
                {
                    callback();
                    dispatch(commonActions.showError("The selected customer is not an accounting office, or something went wrong on the server", json.name))
                }
                else
                {
                    dispatch({ type: actionTypes.STORE_CUSTOMER_AND_INFO_TYPES, data: json });
                    const formdata = {
                        customerNumber: customerNumber,
                        name: json.name,
                        customerInfoFlags: json.customerInfoFlags,
                        aoPartnerDateFrom: json.aoPartnerDates && json.aoPartnerDates.validFrom,
                        aoPartnerDateTo: json.aoPartnerDates && json.aoPartnerDates.validTo,
                        growthProgramDateFrom: json.growthProgramDates && json.growthProgramDates.validFrom,
                        growthProgramDateTo: json.growthProgramDates && json.growthProgramDates.validTo
                    }
                    dispatch(commonActions.startFormEditing("editCustomerInfoTypes", formdata));
                }
            })
        }
    },

    changeCustomerInfoArrayToggle(form, arr, index, value) {
        arr[index].hasFlag = value;

        return commonActions.changeMultiFormField(form, "hasFlag", arr);
    },

    changeCustomerInfoArrayValidTo(form, arr, index, value) {
        arr[index].validTo = value;

        return commonActions.changeMultiFormField(form, "validTo", arr);
    },

    updateCustomerInfoTypes(form, customerNumber, callback) {
        return dispatch => {

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify({customerInfoFlags: form.data.customerInfoFlags, aoPartnerDates: {validFrom: form.data.aoPartnerDateFrom, validTo: form.data.aoPartnerDateTo}, growthProgramDates: {validFrom: form.data.growthProgramDateFrom, validTo: form.data.growthProgramDateTo}})
            }

            return httpFetch(`/api/settings/updatecustomerinfotypes/${customerNumber}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.cancelFormEditing("editCustomerInfoTypes"));
                    dispatch(commonActions.showSuccess("Customer's info types has been updated"));

                    callback();
                })
                .catch(error => {
                    
                });
        }
    },

    getCompanyExpensePartners() {
        return (dispatch) => {
            return httpFetch(`/api/settings/GetCompanyExpensePartners`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.GET_COMPANY_EXPENSE_PARTNERS, data: json });
                })
        }
    },

    getCompanyExpensePartner(id) {
        return (dispatch) => {
            return httpFetch(`/api/settings/GetCompanyExpensePartner/${id}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_COMPANY_EXPENSE_PARTNER, data: json });
                    dispatch(commonActions.startFormEditing("editCompanyExpensePartnerForm", json, formValidationRules.CompanyExpensePartnerEditForm));
                })
        }
    },

    startCompanyExpensePartnerCreateForm(data) {
        return (dispatch) => {
            dispatch(commonActions.startFormEditing("createCompanyExpensePartnerForm", data, formValidationRules.CompanyExpensePartnerCreateForm))
        }
    },

    createCompanyExpensePartner(form, callback) {
        return dispatch => {
            dispatch(commonActions.progressLoader(true));

            const validationResult = helpers.validateForm(form);

            if(validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                dispatch(commonActions.progressLoader(false));

                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch('/api/settings/addCompanyExpensePartner', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.progressLoader(false));
                    dispatch(commonActions.showSuccess('Partner has been successfully created!'));
                    callback(json);
                    
                })
        }
    },

    updateCompanyExpensePartner(form, callback) {
        return dispatch => {

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch(`/api/settings/updateCompanyExpensePartner/`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.cancelFormEditing("editCompanyExpensePartnerForm"));
                    dispatch(commonActions.showSuccess('Partner has been updated'));

                    callback();
                })
        }
    },

    deleteCompanyExpensePartner(id, callback) {
        return (dispatch) => {

            const httpRequest = {
                method: 'POST',
            }

            return httpFetch(`/api/settings/deleteCompanyExpensePartner/${id}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.cancelFormEditing("editCompanyExpensePartnerForm"));
                    dispatch(commonActions.showSuccess('Partner has been deleted'));
                    callback();
                })
        }
    },

    createCompanyExpensePartnerAdmin(form, callback) {
        return dispatch => {
            dispatch(commonActions.progressLoader(true));

            const validationResult = helpers.validateForm(form);

            if(validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                dispatch(commonActions.progressLoader(false));

                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch('/api/settings/addCompanyExpensePartnerAdmin', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.progressLoader(false));
                    dispatch(commonActions.showSuccess('Admin user has been successfully created!'));
                    callback();                    
                })
        }
    },

    deleteCompanyExpensePartnerAdmin(partnerId, adminId, callback) {
        return dispatch => {
            dispatch(commonActions.progressLoader(true));            

            const httpRequest = {
                method: 'POST'
            }

            return httpFetch(`/api/settings/deleteCompanyExpensePartnerAdmin/${partnerId}/${adminId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.progressLoader(false));
                    dispatch(commonActions.showSuccess('Admin user has been successfully deleted!'));
                    callback();
                })
        }
    },

    startCompanyExpensePartnerAdminCreateForm(data) {
        return (dispatch) => {
            dispatch(commonActions.startFormEditing("companyExpensePartnerAdminCreateModal", data, formValidationRules.CompanyExpensePartnerAdminCreateForm))
        }
    },

    getCompensationTickets() {
        return dispatch => {
            return httpFetch(`/api/settings/getcompensationtickets/`, dispatch)
                .then(json => {
                    dispatch({type: actionTypes.GET_COMPENSATION_TICKETS, data: json})
                })
        }
    },

    getSelectedTicket(id) {
        return dispatch => {
            return httpFetch(`/api/settings/getcompensationticket/${id}`, dispatch)
                .then(json => {
                    dispatch({type: actionTypes.STORE_SELECTED_TICKET, data: json})
                    dispatch(commonActions.startFormEditing("settingsCompensation", json, formValidationRules.settingsCompensationTicket))
                })
        }
    },

    switchCompensationValidation(value, form) {
        return dispatch => {
            
            if (value === true)
                form.validationRules = formValidationRules.settingsCompensationTicketAllFields
            else
            form.validationRules = formValidationRules.settingsCompensationTicket

        }
    },

    saveTicket(form, callback, createSalesforceTicket) {
        return dispatch => {
            dispatch(commonActions.progressLoader(true));            

            const validationResult = helpers.validateForm(form);

            if(validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                dispatch(commonActions.progressLoader(false));

                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch(`/api/settings/updateticket/`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.progressLoader(false));
                    
                    if(createSalesforceTicket)
                    dispatch(settingsActions.sendSalesforceTicket(form))

                    dispatch(commonActions.showSuccess('Ticket has been successfully saved!'));
                    dispatch(commonActions.cancelFormEditing(form.id))
                    dispatch(settingsActions.clearSearch())
                    callback();
                })
        }
    },

    sendSalesforceTicket(form){
        
            return dispatch => {
                const httpRequest = {
                    method: 'POST',
                    body: JSON.stringify(form.data)
                }
    
                return httpFetch(`/api/customer/SendSalesforceTicketEmail`, dispatch, httpRequest)
                    .then(json => {
                        dispatch(commonActions.showSuccess('Salesforce ticket has been sent'));
                })
            }
    },

    searchClosedCompensations(input){
        input = input.split("/").length > 1 ? input.split("/")[0].trim() : input;
        return dispatch => {
            return httpFetch(`/api/settings/SearchClosedCompensationTickets/${input}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.SEARCH_CLOSED_COMPENSATION_TICKETS, data: json });
                })
        }
    },
    clearSearch() {
        return { type: actionTypes.SEARCH_CLOSED_COMPENSATION_TICKETS, data: [] };
    },

    getAoCustomers() {
        return dispatch => {
            return httpFetch(`/api/settings/getAOCustomers/`, dispatch)
                .then(json => {
                    dispatch({type: actionTypes.GET_AO_CUSTOMERS, data: json})
                })
        }
    },

    getAoCustomer(id) {
        return dispatch => {
            return httpFetch(`/api/settings/getAOCustomer/${id}`, dispatch)
                .then(json => {
                    dispatch({type: actionTypes.STORE_SELECTED_AO_CUSTOMER, data: json})
                    dispatch(commonActions.startFormEditing("aoCustomerEdit", json))
                })
        }
    },

    getDuplicateAoCustomer(id) {
        return dispatch => {
            return httpFetch(`/api/settings/getAOCustomer/${id}`, dispatch)
                .then(json => {
                    dispatch({type: actionTypes.STORE_DUPLICATE_AO_CUSTOMER, data: json})
                    dispatch(commonActions.startFormEditing("duplicateAoCustomerEdit", json))
                })
        }
    },

    updateAoCustomer(form, callback) {
        return dispatch => {

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch('/api/settings/updateAoCustomer', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.cancelFormEditing("aoCustomerEdit"));
                    dispatch(commonActions.showSuccess('AO customer has been updated!'));

                    callback();
                })
        }
    },

    createDuplicateAoCustomer(form, callback) {
        return dispatch => {

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch('/api/settings/createDuplicateAoCustomer', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.cancelFormEditing("duplicateAoCustomerEdit"));
                    dispatch(commonActions.showSuccess('AO customer has been duplicated!'));

                    callback();
                })
        }
    },

    deleteDuplicateAoCustomer(form, callback) {
        return dispatch => {

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch('/api/settings/deleteDuplicateAoCustomer', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.cancelFormEditing("aoCustomerEdit"));
                    dispatch(commonActions.showSuccess('AO customer has been updated!'));

                    callback();
                })
        }
    },


    searchAoCustomers(input){
        input = input.split("/").length > 1 ? input.split("/")[0].trim() : input;
        return dispatch => {
            return httpFetch(`/api/settings/SearchAOCustomers/${input}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.SEARCH_AO_CUSTOMERS, data: json });
                })
        }
    },

    clearAoCustomerSearch() {
        return { type: actionTypes.SEARCH_AO_CUSTOMERS, data: [] };
    },

    getEpiPartners() {
        return dispatch => {
            return httpFetch(`/api/settings/getEpiPartners/`, dispatch)
                .then(json => {
                    dispatch({type: actionTypes.GET_EPI_PARTNERS, data: json})
                })
        }
    },

    getEpiPartner(id) {
        return dispatch => {
           return httpFetch(`/api/settings/getEpiPartner/${id}`, dispatch)
                .then(json => {
                    dispatch(settingsActions.getAllPartnerCompetences())
                    dispatch(settingsActions.getAllPartnerBusinesses())
                    dispatch({type: actionTypes.STORE_SELECTED_EPI_PARTNER, data: json})
                    dispatch(commonActions.startFormEditing("epiPartnerEdit", json))
                })
        }
    },

    searchEpiPartners(input){
        input = input.split("/").length > 1 ? input.split("/")[0].trim() : input;
        return dispatch => {
            return httpFetch(`/api/settings/SearchEpiPartners/${input}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.SEARCH_EPI_PARTNERS, data: json });
                })
        }
    },  

    getAllPartnerCompetences(){
        return dispatch => {
            return httpFetch(`/api/settings/getAllPartnerCompetences`, dispatch)
                .then(json => {
                    dispatch({type: actionTypes.GET_ALL_PARTNER_COMPETENCES, data: json})
                })
        }
    },

    getAllPartnerBusinesses(){
        return dispatch => {
            return httpFetch(`/api/settings/getAllPartnerBusinesses`, dispatch)
                .then(json => {
                    dispatch({type: actionTypes.GET_ALL_PARTNER_BUSINESSES, data: json})
                })
        }
    },

    clearEpiPartnerSearch() {
        return { type: actionTypes.SEARCH_EPI_PARTNERS, data: [] };
    },

    updateEpiPartner(form, callback) {
        return dispatch => {

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch('/api/settings/updateEpiPartner', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.cancelFormEditing("epiPartnerEdit"));
                    dispatch(commonActions.showSuccess('EPI Partner has been updated!'));
                    callback();
                })
        }
    },

    updateRole(form, id, type, callback) {
        return dispatch => {

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data.userRoles)
            }

            return httpFetch(`/api/user/updateRoles/${id}/${type}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.cancelFormEditing("adminUserEdit"));
                    dispatch(commonActions.showSuccess('User roles have been updated!'));
                    callback();
                })
        }
    },

    getUserBudgetById(id){
        return dispatch => {
            return httpFetch(`/api/settings/getBudgetByUser/${id}`, dispatch)
                .then(json => {
                    
                    dispatch({type: actionTypes.GET_BUDGET_BY_USER, data: json});
                    
                })

        }
    },

    getTeamBudgetById(id){
        return dispatch => {
            return httpFetch(`/api/settings/getBudgetByTeam/${id}`, dispatch)
                .then(json => {
                    
                    dispatch({type: actionTypes.GET_BUDGET_BY_TEAM, data: json});
                    
                })

        }
    },

    getAllUserBudgetByMonthYear(month, year){
        return dispatch => {
            return httpFetch(`/api/settings/getAllBudgets/${month}/${year}`)
            
                .then(json => {
                    dispatch({type: actionTypes.GET_ALL_BUDGETS_BY_MONTH_YEAR, data: json})
                })
        }
    },

    updateUserBudget(form, callback){
        return dispatch => {
            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch('/api/settings/addBudget', dispatch, httpRequest)
                    .then(json => {
                        dispatch(settingsActions.getUsers());
                        dispatch(commonActions.cancelFormEditing(form.id, callback()));
                        dispatch(commonActions.showSuccess("User budget have been updated!"));
                    })
        }
    },

    getSettingsReleaseVersions() {
        return dispatch => {
            httpFetch(`/api/settings/getAllReleaseNews`, dispatch)
                .then(json => {
                    dispatch({type: actionTypes.GET_ALL_RELEASES, data: json})
                })
        }
    },

    getRelease(id) {
        return dispatch => {
            return httpFetch(`/api/releasenews/get/${id}`, dispatch)
                .then(json => {
                    dispatch({type: actionTypes.STORE_RELEASE_VERSION_FORM, data: json})
                    dispatch(commonActions.startFormEditing("releaseNewsForm", json));
            })
        }
    },

    updateReleaseNewsItem(form, callback) {
        return dispatch => {

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch('/api/settings/updateReleaseNews', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.cancelFormEditing("releaseNewsForm"));
                    dispatch(commonActions.showSuccess('Release news item has been updated!'));
                    callback();
                })
        }
    },

    startAddReleaseItemForm(data) {
        return (dispatch) => {
            dispatch(commonActions.startFormEditing("releaseNewsForm", data))
        }
    },

    addNewReleaseItemForm(form, callback) {
        return dispatch => {

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch('/api/settings/createReleaseNews', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.cancelFormEditing("releaseNewsForm"));
                    dispatch(commonActions.showSuccess('Release news item has been added!'));
                    callback();
                })
        }
    },

    deleteReleaseItem(id, callback) {
        return (dispatch) => {

            const httpRequest = {
                method: 'POST',
            }

            return httpFetch(`/api/settings/deleteReleaseNews/${id}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.cancelFormEditing("releaseNewsForm"));
                    dispatch(commonActions.showSuccess('Release news item has been deleted'));

                    callback();
                })
        }
    },

    getJobs() {
        return dispatch => {
            httpFetch(`/api/settings/workerjobs`, dispatch)
                .then(json => {
                    dispatch({type: actionTypes.GET_WORKER_JOBS, data: json})
                })
        }
    },

    addJobToQueue(jobArgs) {
        const httpRequest = {
            method: 'POST',
            body: JSON.stringify(jobArgs)
        }

        return dispatch => {
            httpFetch(`/api/settings/enqueuejob`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('I did my best'));
                })
        }
    },

    addFeatureToggle(name, usertype, userid) {
        return dispatch => {
            const httpRequest = {
                method: 'POST',
                body: JSON.stringify({
                    name: name,
                    userType: usertype,
                    userId: userid
                })
            }

            httpFetch('/api/featuretoggles', dispatch, httpRequest)
            .then(json => {
                dispatch(commonActions.showSuccess('Feature toggle added'))
                dispatch(settingsActions.getEnabledFeatureFlags())
            })
        }
    },

    deleteFeatureToggle(id) {
        return dispatch => {
            httpFetch(`/api/featuretoggles/delete/${id}`)
            .then(json => {
                dispatch(commonActions.showSuccess('Feature toggle removed'))
                dispatch(settingsActions.getEnabledFeatureFlags())
            })
        }
    },

    getEnabledFeatureFlags() {
        return dispatch =>
            httpFetch("/api/FeatureToggles/GetEnabledFlags", dispatch)
            .then(json => dispatch({type: actionTypes.STORE_ENABLED_FEATURE_FLAGS, data: json}))
    },

    getAllFeatureFlags() {
        return dispatch =>
            httpFetch("/api/FeatureToggles/GetAllFlags", dispatch)
            .then(json => dispatch({type: actionTypes.STORE_ALL_FEATURE_FLAGS, data: json}))
    }
}

export default settingsActions;