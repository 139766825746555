import HorizontalText, { HorizontalRow } from "../HorizontalText";
import React from "react";
import { useTranslation } from "react-i18next";
import routes from "../../Constants/routes";
import { UserRoles} from "../../Constants/userRoles";
import MainCustomerDetails from "./MainCustomerDetails";
import SubCustomersDetails from "./SubCustomersDetails";
import CheckAddressModal from "./CheckAddressModal";
import TransactionServiceButton from "./TransactionServiceButton";
import VismaFinanceProduct from "./VismaFinanceProduct";
import Panel, { NoInformation } from "../Panel";
import MessageAlertLevel from "../MessageAlertLevel";
import CustomerEditContainer from "../../Containers/Customer/CustomerEditContainer";
import Loader from "../Loader";
import CustomerInfoFlagsContainer from "../../Containers/Customer/CustomerInfoFlagsContainer";
import {hasAdminAccess} from "../../Utils/permissionUtils";

const getDropdownItemName = (list, value) => {
    if (!list) {
        return "Error";
    }
    
    list = list.filter(item => item.id === value);
    if (list.length === 1)
        return list[0].name;
    return "<Missing value>";
}

export function MainInfoContainer({customer, toggleModal }) {
    
        
    const { t } = useTranslation();

    const getCustomerIcon = (customer) => {
        if (customer.group >= 1800 && customer.group <= 1899) {
            return "iconAO"
        } else if ((customer.group >= 3000 && customer.group <= 3999) || customer.type === 2) {
            return "iconReseller"
        } else if (containsProduct(customer.products, "ADMIN.API.UTV")) {
            return "iconIntegrationPartner"
        }
    }

    const containsProduct = (productArray, productNumber) => {
        return productArray && productArray.some(product => product.productNumber === productNumber);
    }
    
    return <>
            <HorizontalRow>
                <HorizontalText label={t("maininfocontainer.customernumber")} value={customer.customerNumber}
                    icon={getCustomerIcon(customer)} />
            </HorizontalRow>
        
            <div className="infotypeContainer">
                {!!customer.customerInfoFlags.length && <h3>{t('customerinfoflags.header')}</h3>}
                <CustomerInfoFlagsContainer onClick={toggleModal}/>
            </div>
    </>
}

async function onSaveClick(props){
    const { commonActions, customerForm, isCreation, customerActions } = props;

    if (customerForm.data.mainCustomer == "" || (customerForm.data.subCustomers && customerForm.data.subCustomers.length > 0 && customerForm.data.subCustomers.every(i => i == ""))) {
        commonActions.showWarning("Main customer is either not selected, or every added sub customer is empty, select main customer or remove open sub customer fields. Please validate!");
        return;
    }
    else if (customerForm.data.group === 1001 && (customerForm.data.mainCustomer == null)) {
        commonActions.showWarning("Main customer is missing or isn't selected. Add main customer and try again. Please validate!");
        return;
    }

    await customerActions.saveCustomer(customerForm, isCreation)
    props.changeEditInternalInfo();
}

export function InternalInfo(props) {
    const { t } = useTranslation();
    const customer = props.customer
    const paymentTerms = [
        { id: 30, name: "30 dagar netto" },
        { id: 2, name: "2 postförskott" }]
    const customerGroups = props.dropdownlists && props.dropdownlists.CustomerGroup;
    const customerTypes = props.dropdownlists && props.dropdownlists.CustomerType;
    const customerStatuses = props.dropdownlists && props.dropdownlists.CustomerStatus;
    const customerGroups2 = props.dropdownlists && props.dropdownlists.CustomerGroup2;
    const spcsEdi = props.dropdownlists && props.dropdownlists.SpcsEdi;
    const vbAdmin = hasAdminAccess(props.user, UserRoles.InternalInfo)
    const editInfo = props.editInternalInfo;

    const getCustomerInvoiceFee = (customer) => {

        switch (customer.invoiceFee) {
            case 8192:
                return "Yes"
            case 8200:
                return "No"
            default:
                return customer.invoiceFee
        }
    }
    
    return <HorizontalRow>
        {!editInfo && <> <div className={`col-sm-12`}> <div className=''><i className="float-right vismaicon vismaicon-edit" onClick={props.changeEditInternalInfo}></i></div></div>
        <HorizontalText label={t('internalinfocontainer.customergroup')} value={customer.group !== 0 && `${getDropdownItemName(customerGroups, customer.group)} / ${customer.group}`} />
        <HorizontalText label={t('internalinfocontainer.voncustomergroup')} value={customer.vonGroup !== 0 && `${getDropdownItemName(customerGroups, customer.vonGroup)} / ${customer.vonGroup}` || "Empty"} />
        <HorizontalText label={t('internalinfocontainer.customergroup2')} value={customer.secondaryGroup !== 0 && `${getDropdownItemName(customerGroups2, customer.secondaryGroup)} / ${customer.secondaryGroup}` || "Empty"} />
        <HorizontalText label={t('internalinfocontainer.customertype')} value={getDropdownItemName(customerTypes, customer.type)} />
        <HorizontalText label={t('internalinfocontainer.customerstatus')} value={getDropdownItemName(customerStatuses, customer.status)} />
        <HorizontalText label={t('internalinfocontainer.creditlimit')} value={customer.creditLimit} />
        {vbAdmin && <>
            <HorizontalText label={t('internalinfocontainer.invoicefee')} value={getCustomerInvoiceFee(customer)} />
            <HorizontalText label="SPCS EDI" value={getDropdownItemName(spcsEdi, customer.spcsEdi) || "Empty"} />
        </>}
        <HorizontalText label={t('internalinfocontainer.responsibleperson')} value={customer.responsibleSalesPersonName || "Empty"} />
        <HorizontalText label={t('internalinfocontainer.paymentterms')} value={getDropdownItemName(paymentTerms, customer.paymentTerms)} /></>}
        {customer.invoiceCustomerNumber && customer.invoiceCustomerNumber != "0" &&  <HorizontalText type={"link"} newTab={true} to={routes.customerDetails(customer.invoiceCustomerNumber)}label={t('internalinfocontainer.invoicecustomernumber')} value={customer.invoiceCustomerNumber || "Empty"} />}
        {editInfo && <><CustomerEditContainer fromMainpage={true}/> 
            <div className="form-group padding-top no-left-margin btn-toolbar w-100">
                <div>
                    <button type="button" className="btn btn-primary" onClick={() => onSaveClick(props)}>{t('customerform.save')}</button>
                    <button type="button" className="btn btn-default" onClick={props.changeEditInternalInfo}>{t('customerform.cancel')}</button>
                </div>
                <div className="btn-group">
                    <Loader />
                </div>
            </div>
        </>}
    </HorizontalRow> 
}

export function InyettPanel(props) {
    const { t } = useTranslation();
    
    return <Panel title={t('inyettdetailscontainer.header')}>
        <InyettDetails {...props} />
    </Panel>
}

export function InyettDetails(props) {
    const { t } = useTranslation();
    const inyettData = props.customer.inyettData;
    
    if(!inyettData)
        return <NoInformation />
    
    return <HorizontalRow>
            <HorizontalText label={t('inyettdetailscontainer.employees')} value={inyettData.employeeAmount}/>
            <HorizontalText label={t('inyettdetailscontainer.revenue')} value={inyettData.netRevenue + " tKr"}/>
            <HorizontalText label={t('inyettdetailscontainer.type')} value={inyettData.type}/>
            <HorizontalText label={t('inyettdetailscontainer.branch')} value={inyettData.branch}/>
        </HorizontalRow>
}

export function ConnectedCustomers(props) {
    const customer = props.customer
    
    if(!props.customer.mainCustomer && (!customer.subCustomers || customer.subCustomers.length === 0))
        return <NoInformation />
    
    return <>
        <MainCustomerDetails mainCustomer={customer.mainCustomer} title={'maincustomercontainer'} />
        <SubCustomersDetails subCustomers={customer.subCustomers} title={'subcustomercontainer'} />
    </>
}

export function ContactInfo(props) {
    const { t } = useTranslation();
    
    const openCheckAddressModal = props => {
        props.customerActions.getCustomerInfoForUpdate(props.customer.organizationNumber)
    }
    
    const customer = props.customer
    
    return <>
        { !customer.isNonInteractive && <button type="button" className="btn btn-default" onClick={() => openCheckAddressModal(props)}>{t('contactinfocontainer.update')}</button> }

        <HorizontalRow>
            <HorizontalText columns={1} label={t('contactinfocontainer.contactperson')} value={customer.contactPerson} />
            <HorizontalText columns={1} label={t('contactinfocontainer.streetaddress')} value={customer.streetAddress} />
            <HorizontalText columns={1} label={t('contactinfocontainer.coaddress')} value={customer.coAddress} />
            <HorizontalText columns={1} label={t('contactinfocontainer.postalcode')} value={customer.postalCode} />
            <HorizontalText columns={1} label={t('contactinfocontainer.postalcity')} value={customer.postalCity} />
            <HorizontalText columns={1} label={t('contactinfocontainer.emailaddress')} value={customer.emailAddress} type='email' />
            <HorizontalText columns={1} label={t('contactinfocontainer.phonenumber')} value={customer.phoneNumber} />
            <HorizontalText columns={1} label={t('contactinfocontainer.invoiceemail')} value={customer.invoiceEmail} type='email' />
        </HorizontalRow>
        <CheckAddressModal customer={props.customer} updatedInfo={props.updatedInfo} />
    </>
}

export function LonSmart(props) {
    const { t } = useTranslation();
    const customer = props.customer

    const hasNoDataLonSmart = !customer.mainLonSmartCustomer && customer.subLonSmartCustomers.length === 0
    const hasNoDataSkattBokslut = !customer.mainSkattBokslutCustomer && customer.subSkattBokslutCustomers.length === 0
    
    return <>
        <h4>{t('connectedcustomervoncontainer.headerlonsmart')}</h4>
        {hasNoDataLonSmart && <NoInformation/> || <>
            <MainCustomerDetails mainCustomer={customer.mainLonSmartCustomer} title={'mainlonsmartcustomercontainer'} />
            <SubCustomersDetails subCustomers={customer.subLonSmartCustomers} title={'sublonsmartcustomercontainer'} />
        </>}
        <br/>
        
        <h4>{t('connectedcustomervoncontainer.headerskattbokslut')}</h4>
        {hasNoDataSkattBokslut && <NoInformation/> || <>
            <MainCustomerDetails mainCustomer={customer.mainSkattBokslutCustomer} title={'mainskattbokslutcustomercontainer'} />
            <SubCustomersDetails subCustomers={customer.subSkattBokslutCustomers} title={'subskattbokslutcustomercontainer'} />
        </>}
    </>
}

export function TransactionServices(props) {
    const { t } = useTranslation();

    const customer = props.customer
    return <>
        {customer.transactionServices.map(item =>
            <TransactionServiceButton key={item.name} transactionServiceData={item} name={item.name} customerNumber={customer.customerNumber}
                customerIsValid={customer.hasVonAccount} showError={() => props.commonActions.showWarning(t('transactionservicescontainer.novonaccount'))} toggleModal={props.toggleModal} />)
        }
    </>
}

export function VismaFinanceProductContainer(props) {

    const { vismaFinance, vismaFinanceProductLoading } = props;
    
    if(vismaFinanceProductLoading)
        return <div className='loader'>Loading...</div>

    if(!vismaFinance)
        return null;
    
    if(!vismaFinance.vismaFinanceResponse)
        return <MessageAlertLevel message="Can´t access Visma Finance. Information is limited." />
    
    if(!vismaFinance.vismaFinanceProducts.length)
        return <NoInformation />
    
    return <>
        {vismaFinance.vismaFinanceProducts && vismaFinance.vismaFinanceProducts.map((item, index) =>
            <VismaFinanceProduct key={index}
                product={item.product}
                status={item.status}
                color={item.color}
                isAllowedToBuyProduct={item.isAllowedToBuyProduct} ></VismaFinanceProduct>
        )}
    </>
}

export function VonAccounts(props) {

    const { t } = useTranslation();
    
    if (!props.vonAccounts) {
        return null;
    }

    const groupVonAccounts = (vonAccounts) => {
        var arr = [];
        vonAccounts.forEach(account => {
            arr.push(...account.roles);
        });

        return arr.reduce((result, role) => {
            const product = role.productName || 'n/a';
            const specifiedProductName = role.specifiedProductName || "";
            var roles = role.role || 'n/a';
            result[product] = result[product] || [];
            result[product].specifiedProductName = specifiedProductName;
            result[product].push(roles);

            return result;

        }, Object.create(null));
    }

    if (props.vonAccounts.length === 0) {
        return (<fieldset className="col-xs-12"><legend>{t('vonaccountscontainer.vonaccounts')}</legend><p>{ }</p></fieldset>);
    }

    const groupedVonAccounts = groupVonAccounts(props.vonAccounts);
    return (<fieldset className="col-xs-12">
        <legend>{t('vonaccountscontainer.vonaccounts')}</legend>
        <ul className="list-group">
            {Object.keys(groupedVonAccounts).map((key, index) => {
                return (
                    <li key={index} className="list-group-item">
                        <span className="badge">{groupedVonAccounts[key].length}</span>
                        {key}
                    </li>
                )
            })}
        </ul>
    </fieldset>
    )
}

export function CollaborationsList(props) {
    const { t } = useTranslation();

    if (!props.collaborations) {
        return null;
    }

    if (props.collaborations.length === 0) {
        return (<fieldset className="col-xs-12"> <legend>{t('vonproducts.collaboration')}</legend><p>{t('collaborationscontainer.noentries')}</p></fieldset>);
    }

    const groupCollaborations = (collaborations) => {
        return collaborations.reduce((result, collaboration) => {
            const product = collaboration.product || 'n/a'
            result[product] = result[product] || [];
            result[product].push(collaboration);

            return result;

        }, Object.create(null));
    }
    
    const groupedCollaborations = groupCollaborations(props.collaborations);
    return ( <fieldset className="col-xs-12">
            <legend>{t('vonproducts.collaboration')}</legend>
            <ul className="list-group">
                {Object.keys(groupedCollaborations).map((key, index) => {
                    return (
                        <li key={index} className="list-group-item">
                            <span className="badge">{groupedCollaborations[key].length}</span>
                            {key}
                        </li>
                    )
                })}
            </ul>
        </fieldset>
    )
}

export function CustomerActionHistory(props) {
    const { t } = useTranslation();
    if(!props.actionLogs) {
        return null;
    }

    if(props.actionLogs.length === 0) {
        return (<fieldset className="col-xs-12"><legend>{t('actionhistory.actionhistory')}</legend><p>{t('actionlogcontainer.noentriesfound')}</p></fieldset>);
    }
    
    return <fieldset className="col-xs-12">
        <legend>{t('actionhistory.actionhistory')}</legend>
        <ul className="list-group">
            {(props.actionLogs).slice(0,5).map((action, index) => {
                return (
                    <li key={index} className="list-group-item">
                        <span>{action.createDate.split(' ')[0]} </span>
                        <span>{action.description} </span>
                        <span>{action.salesPersonName}</span>
                    </li>
                )
            })}
        </ul>
    </fieldset>
}

export function RelatedCustomers(props) {
    const { t } = useTranslation();
    
    if(!props.relatedCustomers) {
        return null;
    }

    if(props.relatedCustomers.length === 0) {
        return <p>{t("relatedcustomerscontainer.error") }</p>
    }
    
    return <ul className="list-group">
            {(props.relatedCustomers).slice(0,10).map((relatedCustomer, index) => {
                return (
                    <li key={index} className="list-group-item">
                        <a href={routes.customerDetails(relatedCustomer.customerNumber)} target="_blank" rel="noopener noreferrer">{relatedCustomer.customerNumber}</a>
                        <span> {relatedCustomer.name} </span>
                    </li>
                )
            })}
        </ul>
}

