import React from 'react';
import HorizontalText from '../../HorizontalText';
import {useTranslation} from "react-i18next";
import {Access, UserRoles} from "../../../Constants/userRoles";
import {hasAccess} from "../../../Utils/permissionUtils";

export default function AoCustomerInformation(props){
    const { t } = useTranslation()
    const aoCustomerInfo = props.aoCustomerInfo;
    const user = props.user;

    return <>
    {hasAccess(user, UserRoles.AOSales, Access.ReadWrite) &&
        <button type="button" className="pull-right btn btn-default" onClick={() => props.toggleModal("editAOCustomerInfo", "updateAOCustomerInfoModal")}>{t('aocustomerinformation.edit')}</button>
    }
        <div onMouseEnter={() => { props.setHoverState("assignments") }} onMouseLeave={() => { props.setHoverState("assignments") }}>
            <HorizontalText label={t('aocustomerinformation.assignments')} value={aoCustomerInfo && aoCustomerInfo.accountingAssignments} />
            {props.hoverState.assignments && <div className='table-content-hover'>{t('aocustomerinformation.assignmentreadhover')}</div>}
        </div>
        <div onMouseEnter={() => { props.setHoverState("eekonomi") }} onMouseLeave={() => { props.setHoverState("eekonomi") }}>
            <HorizontalText label={t('aocustomerinformation.eekonomi')} value={aoCustomerInfo && aoCustomerInfo.eekonomi} />
            {props.hoverState.eekonomi && <div className='table-content-hover'>{t('aocustomerinformation.eekonomireadhover')}</div>}
        </div>
        <br/>
        <div onMouseEnter={() => { props.setHoverState("supplier") }} onMouseLeave={() => { props.setHoverState("supplier") }}>
            <legend>{t('aocustomerinformation.supplier')}</legend>
            {props.hoverState.supplier && <div className='table-content-hover'>{t('aocustomerinformation.supplierreadhover')}</div>}
        </div>
        <HorizontalText label={t('aocustomerinformation.accounting')} value={aoCustomerInfo && aoCustomerInfo.accounting} />
        <HorizontalText label={t('aocustomerinformation.payroll')} value={aoCustomerInfo && aoCustomerInfo.payroll} />
        <HorizontalText label={t('aocustomerinformation.clienthandler')} value={aoCustomerInfo && aoCustomerInfo.clientHandler} />
        <HorizontalText label={t('aocustomerinformation.financialstatements')} value={aoCustomerInfo && aoCustomerInfo.financialStatements} />
    </>
}