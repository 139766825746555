import React, { Component } from 'react'
import { connect } from 'react-redux';
import commonActions from '../../Actions/commonActions'
import customerActions from '../../Actions/customerActions'
import { bindActionCreators } from 'redux'
import LeftNavigationBarItem from '../LeftNavigationBarItem'
import CheckAddressModal from '../Customer/CheckAddressModal'
import { withTranslation } from 'react-i18next';

class CustomerLinks extends Component {

    constructor(props) {
        super(props);
    }

    onExternalButtonClick(url) {
        window.open(url);
    }

    getSalesforceUrl() {
        return `https://vismaspcsab.my.salesforce.com/`;
    }
    
    getFileBoxUrl() { 
        // Old solution
        const { customer, externalUrls: {fileBox} } = this.props;

        const params = new URLSearchParams();

        params.append("customernumber", customer.customerNumber);
        params.append("email", customer.emailAddress);
        params.append("phone", customer.phoneNumber);
        params.append("customername", customer.name);
        
        return `${fileBox}/case/open?${params.toString()}`
    }

    //Use this when we release SkyNet 
    getFileBoxUrlSkynet = () => {
        const params = new URLSearchParams();

        params.append("CustomerNumber", customer.customerNumber);
        params.append("CustomerName", customer.name);

        return routes.createFileBoxTicket + "?" + params.toString();
    }

    getInternalAdminUrl(){

        const {customer, isProduction} = this.props;

        let customerAdminUrl = `https://admin.stage.vismaonline.com/Internal/customerinformationtabstrip.aspx?customerno=${customer.customerNumber}`

        if (isProduction)
            customerAdminUrl = `https://admin.vismaonline.com/Internal/customerinformationtabstrip.aspx?customerno=${customer.customerNumber}`;

        return customerAdminUrl;
    }

    getLinks() {
        const { t } = this.props;
        
        const subItems = [
            {
                title: 'Salesforce', onClick: () => this.onExternalButtonClick(this.getSalesforceUrl()),
            },
            {
                title: t('leftnavigation.internaladministration'), onClick: () => this.onExternalButtonClick(this.getInternalAdminUrl())
            },
            {
                title: 'Visma Filebox', onClick: () => this.onExternalButtonClick(this.getFileBoxUrl())
            },
            {
                title: t('leftnavigation.integrationpartners'), onClick: () => this.onExternalButtonClick("https://integrationer.vismaspcs.se/")
            },
            {
                title: t('leftnavigation.retailer'), onClick: () => this.onExternalButtonClick("https://vismaspcs.se/partners-och-aterforsaljare/hitta-aterforsaljare")
            },
            {
                title: t('leftnavigation.terminationonprem'), onClick: () => this.onExternalButtonClick("https://docs.google.com/a/visma.com/forms/d/e/1FAIpQLSfeK8OS0NecKd-jROwcxYbS1rg3Hc4NnHz_v5kK1vPC3GaImQ/viewform")
            }
        ];

        return [
            {
                title: t('leftnavigation.externalLinks'), id: "externalLinks", onClick: () => setHeader("externalLinks"), subItems: subItems
            }
        ];
    }

    render() {
        return <>
                {this.getLinks().filter(link => !link.disabled).map((item, index) => {
                    return <LeftNavigationBarItem key={index} link={item} location={this.props.location} />
                })}
                <CheckAddressModal customer={this.props.customer} updatedInfo={this.props.updatedInfo} />
            </>
    }
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.selectedCustomer,
        externalUrls: state.CommonReducer.externalUrls,
        isProduction: state.CommonReducer.isProduction
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerLinks));