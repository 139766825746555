import { useTranslation } from "react-i18next";
import helpers from "../../../../Utils/helpers";
import CustomerSearch from "../../../Search/CustomerSearch";
import CompetitorDiscount from "../../../Order/CompetitorDiscount";
import Card from "../../../Card/Card";

export function VbDiscountOptionsCard({ ...props }) {

    const { t } = useTranslation();

    return <Card>
        <Card.Header >
            <Card.Header.Title>
                {t("vbproductoptions.title")}
            </Card.Header.Title>
        </Card.Header>
        <Card.Content>
            <DiscountOptions {...props} />
        </Card.Content>
    </Card>
}

function DiscountOptions({ product, customer, order, orderActions, commonActions }) {

    const { t } = useTranslation();

    const getProductDetails = () => {
        orderActions.getProductDetails(
            product.productId,
            customer.customerNumber,
            product.additionalUsers,
            product.api,
            product.competitorDiscount,
            order.orderId,
            product.loyaltyDiscountCustomer
        );
    };

    const onCompetitorDiscountChange = (isChecked) => {
        orderActions.changeCompetitorDiscount(isChecked ? 'yes' : 'no');
        getProductDetails();
    };

    const onCustomerSelect = (loyaltyDiscountCustomer) => {
        const { customerNumber } = customer;
        if (loyaltyDiscountCustomer === customerNumber) {
            commonActions.showWarning(t('vbproductdetailscontainer.loyaltydiscountwarning'));
            return;
        }

        orderActions.changeLoyaltyDiscountCustomer(loyaltyDiscountCustomer);
        getProductDetails();
    };

    return <div>
        <div>
            {customer && !helpers.isAoCustomer(customer) && (
                <div>
                    <h3 className="font-family-ubuntu-semibold mb-12">{t("vbdiscountoptions.loyaltydiscount")}</h3>
                    <CustomerSearch onChange={onCustomerSelect} customClass="search-group search-group-button px-0" placeholder={t("vbdiscountoptions.searchcustomer")} />
                </div>
            )}
            {(product.allowCompetitorDiscount && product.competitorDiscount) && (
                <div>
                    <h3 className="font-family-ubuntu-semibold mb-12">{t("vbdiscountoptions.discountoptions")}</h3>
                    <CompetitorDiscount product={product} onChange={onCompetitorDiscountChange} />
                </div>
            )}
        </div>
    </div>
};
