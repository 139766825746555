import React, { Component } from 'react'
import { connect } from 'react-redux';
import commonActions from '../../Actions/commonActions'
import customerActions from '../../Actions/customerActions'
import { bindActionCreators } from 'redux'
import LeftNavigationBarItem from '../LeftNavigationBarItem'
import routes from '../../Constants/routes'
import { withTranslation } from 'react-i18next';
import LeadSendEmailModal from '../Customer/LeadSendEmailModal'
class CustomerLinks extends Component {

    constructor(props) {
        super(props);
        this.initState = {
            showLeadSendEmailModal : false,
            }
    
            this.state = this.initState;
        }

    toggleModal = (modalName, emailTemplate) => {

        if (emailTemplate)
            this.props.customerActions.setInternalLeadsEmailTemplate(emailTemplate);
        this.setState({
            [modalName]: !this.state[modalName]
        });
    }

    getLinks() {
        const { customer: { customerNumber, organizationNumber }, openLeadsCount, openAOLeadsCount, quotationsAmount, openAppointmentsCount, t } = this.props;
        let items = [
            {
                title: t('leftnavigation.sales'), id: "sales", onClick: () => this.props.setHeader("sales"), badgeNumber: (quotationsAmount + openLeadsCount + openAppointmentsCount + openAOLeadsCount), subItems: [
                ]
            }
        ];

        items[0].subItems.push({ title: t('leftnavigation.placeorder'), path: routes.orderPlacement(customerNumber) })
        
        items[0].subItems.push(
                    { title: t('leftnavigation.leads'), path: routes.customerLeads(customerNumber), badgeNumber: (openLeadsCount + openAOLeadsCount) },
                    { title: t('leftnavigation.appointments'), path: routes.customerAppointments( customerNumber), badgeNumber: openAppointmentsCount  },
                    { title: t('leftnavigation.sendleads'), onClick: () => this.toggleModal("showLeadSendEmailModal", "InternalLead")  },
                    { title: t('leftnavigation.campaigns'), path: routes.customerCampaign(customerNumber) }
                    )

        return items;
    }

    render() {
        return <>
                {this.getLinks().filter(link => !link.disabled).map((item, index) => {
                    return <LeftNavigationBarItem key={index} link={item} location={this.props.location} openHeader={this.props.openHeader} />
                })}
                {this.state.showLeadSendEmailModal && <LeadSendEmailModal onClose={this.toggleModal.bind(this, "showLeadSendEmailModal")} />}
            </>
    }
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.selectedCustomer,
        openLeadsCount: state.CustomerReducer.openLeadsCount,
        openAOLeadsCount: state.CustomerReducer.openAOLeadsCount,
        quotationsAmount: state.QuotationReducer.quotationsAmount,
        openAppointmentsCount: state.CustomerReducer.openAppointmentsCount,
        openHeader: state.CommonReducer.openHeader
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerLinks));