import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import appointmentActions from "../Actions/appointmentActions";
import CustomerDirectoryAppointmentModal from "./CustomerDirectoryAppointmentModal";
import { useState } from "react";
import helpers from '../Utils/helpers';
import { Link } from "react-router-dom";
import routes from "../Constants/routes";

function CustomerDirectoryTable({
    t,
    aoCustomers,
    sortListByKey,
    appointmentForm,
    appointmentActions
}) {

    const [showAppointmentModal, setShowAppointmentModal] = useState(false);
    const [selectedAoCustomer, setSelectedAoCustomer] = useState();

    const handleClickClose = () => {
        setShowAppointmentModal(false);
    }

    const handleClickSave = () => {
        appointmentActions.saveAppointment(appointmentForm, true, selectedAoCustomer.customerNumber, () => handleClickClose(), true);
    }

    const openAppointment = (aoCustomer) => {
        setSelectedAoCustomer(aoCustomer)
        setShowAppointmentModal(true);
    }

    const sortList = () => {
        const jsonSort = localStorage.getItem("sortOrder_aoCustomers");
        return jsonSort ? JSON.parse(jsonSort) : [];
    }

    const modalProps = {
        title: t('customerdirectorytable.bookanappointment'),
        mainButtonTitle: "Save",
        onCancelClick: handleClickClose.bind(this),
        stateful: true,
        open: true,
        onSaveClick: handleClickSave.bind(this),
        showButtons: true,
    }

    const onMoveToAppointment = (e, appointmentId) => {

        if (e.target.className !== "preventRowClick" && e.target.tagName !== "INPUT" && e.target.tagName !== "SELECT" && e.target.tagName !== "BUTTON" && appointmentId != 0)
            window.open(routes.appointmentDetails(appointmentId), "_blank")
    }

    const renderCustomerLink = (customerNumber) => {
        const to = routes.customerDetails(customerNumber)
        return (
            <Link target="_blank" to={to}>{customerNumber}</Link>
        );
    }


    const sort = sortList();
    const sortKey = sort.key;
    const sortOrder = sort.sortOrder === "asc" ? "pull-right vismaicon vismaicon-sm vismaicon-arrow-up-circle" : "pull-right vismaicon vismaicon-sm vismaicon-arrow-down-circle";
    const sortCursor = { cursor: "pointer" };

    return (
        <>
            <table className="table table-stripe table-hover">
                <thead>
                    <tr>
                        <th onClick={() => sortListByKey('priority')} style={sortCursor}>{t('customerdirectorytable.priority')}{sortKey === "priority" && <i className={sortOrder}></i>}</th>
                        <th onClick={() => sortListByKey('customerNumber')} style={sortCursor}>{t('customerdirectorytable.customernumber')}{sortKey === "customerNumber" && <i className={sortOrder}></i>}</th>
                        <th onClick={() => sortListByKey('organizationNumber')} style={sortCursor}>{t('customerdirectorytable.organizationnumber')}{sortKey === "organizationNumber" && <i className={sortOrder} />}</th>
                        <th onClick={() => sortListByKey('customerName')} style={sortCursor}>{t('customerdirectorytable.customername')}{sortKey === "customerName" && <i className={sortOrder} />}</th>
                        <th onClick={() => sortListByKey('city')} style={sortCursor}>{t('customerdirectorytable.city')}{sortKey === "city" && <i className={sortOrder} />}</th>
                        <th onClick={() => sortListByKey('postalCode')} style={sortCursor}>{t('customerdirectorytable.postalcode')}{sortKey === "postalCode" && <i className={sortOrder} />}</th>
                        <th onClick={() => sortListByKey('responsibleUser.name')} style={sortCursor}>{t('customerdirectorytable.responsibleperson')}{sortKey === "responsibleUser.name" && <i className={sortOrder} />}</th>
                        <th onClick={() => sortListByKey('lastAppointmentActions.lastActionTime')} style={sortCursor}>{t('customerdirectorytable.lastAction')} {sortKey === "lastAppointmentActions.lastActionTime" && <i className={sortOrder} />}</th>
                        <th onClick={() => sortListByKey('lastAppointmentActions.lastAppointmentTime')} style={sortCursor}>{t('customerdirectorytable.lastAppointment')} {sortKey === "lastAppointmentActions.lastAppointmentTime" && <i className={sortOrder} />}</th>
                        <th>{t('customerdirectorytable.subject')}</th>
                        <th >{t('customerdirectorytable.bookanappointment')}</th>
                    </tr>
                </thead>
                <tbody>
                    {aoCustomers.map((aoCustomer, index) => {
                        let priority = '';

                        if (aoCustomer.priorityType === "high")
                            priority = "vismaicon vismaicon-priority-high";
                        else if (aoCustomer.priorityType === "medium")
                            priority = "vismaicon vismaicon-priority-medium";
                        else if (aoCustomer.priorityType === "low")
                            priority = "vismaicon vismaicon-priority-low";

                        return <tr key={index} onClick={(e) => onMoveToAppointment(e, aoCustomer.lastAppointmentActions.appointmentId)}>
                            <td><i className={priority}></i></td>
                            <td className="preventRowClick">{renderCustomerLink(aoCustomer.customerNumber)}</td>
                            <td>{aoCustomer.organizationNumber}</td>
                            <td>{aoCustomer.customerName}</td>
                            <td>{aoCustomer.city}</td>
                            <td>{aoCustomer.postalCode}</td>
                            <td>{aoCustomer.responsibleUser.name}</td>
                            <td>{aoCustomer.lastAppointmentActions && aoCustomer.lastAppointmentActions.lastAction && helpers.formatDateAndTime(aoCustomer.lastAppointmentActions.lastActionTime) + " " + aoCustomer.lastAppointmentActions.lastAction}</td>
                            <td>{aoCustomer.lastAppointmentActions && helpers.formatDateAndTime(aoCustomer.lastAppointmentActions.lastAppointmentTime)}</td>
                            <td>{aoCustomer.lastAppointmentActions && aoCustomer.lastAppointmentActions.subject}</td>
                            <td className="text-center"><button className="btn btn-primary" onClick={() => openAppointment(aoCustomer)}>{t("customerdirectorytable.bookanappointment")}</button></td>
                            <td className="preventRowClick"><Link className="preventRowClick" to={routes.customerDirectoryContainerEdit(aoCustomer.customerNumber)}>{t('guidedsellinglist.edit')}</Link></td>
                        </tr>
                    })}
                </tbody>
            </table>
            {showAppointmentModal && <CustomerDirectoryAppointmentModal modalProps={modalProps} aoCustomer={selectedAoCustomer} />}
        </>
    );
}

function mapStateToProps(state) {
    return {
        appointmentForm: state.CommonReducer.forms && state.CommonReducer.forms.appointmentForm
    }
}

function mapDispatchToProps(dispatch) {
    return {
        appointmentActions: bindActionCreators(appointmentActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerDirectoryTable));
