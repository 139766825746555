import React, { Component } from 'react';
import moment from 'moment'

import Loader from '../../Components/Loader'
import TextInput from '../../Components/TextInput'
import StaticTextInput from '../../Components/StaticTextInput'
import UserSearch from '../../Components/UserSearch'
import CustomerSearch from '../Search/CustomerSearch';
import DateTimeInput from '../../Components/DateTimeInput'
import AppointmentComments from './AppointmentComments'
import routes from '../../Constants/routes'
import helpers from '../../Utils/helpers'
import LeadActionHistoryComments from '../Leads/LeadsActionHistoryComments'
import { withTranslation } from 'react-i18next';
import ButtonDropdown from '../ButtonDropdown';
import CustomerSearchNew from '../Search/CustomerSearchNew';
import FormSearch from '../Search/FormSearch';

class AppointmentForm extends Component {


    render() {

        const { readonly,
            appointment,
            isCompleted,
            onFieldChange,
            onSaveClick,
            onSaveAndCreateClick,
            onCancelClick,
            onReopenClick,
            customerSearchOnChange,
            showReopenButton,
            assignee,
            isCreation,
            hideButtons,
            width, t
        } = this.props;

        const lead = appointment.lead;
        

        return <form className="skynet-form">
                <div className="appointmentCustomer">
                    <FormSearch label={t('sendmoviemodal.customer')} className={readonly ? 'disabled' : ''}>{(className) => <CustomerSearchNew onChange={customerSearchOnChange} className={className} />}</FormSearch>
                    {appointment.customerName && appointment.customerName !== 'N/A' && <StaticTextInput label={t("appointmentform.customername")} value={appointment.customerName} />}
                    {appointment.customerNumber && appointment.customerNumber !== 'N/A' && <StaticTextInput label={t("appointmentform.customernumber")} value={appointment.customerNumber} type='link' to={routes.customerDetails(appointment.customerNumber)} newTab={true} />}
                </div>
                <TextInput label={t("appointmentform.contactperson")} onChange={onFieldChange.bind(this, 'contactPerson')} value={appointment.contactPerson} readonly={readonly} />
                <TextInput label={t("appointmentform.phonenumber")} onChange={onFieldChange.bind(this, 'phoneNumber')} value={appointment.phoneNumber} readonly={readonly} />
                <TextInput label={t("appointmentform.emailaddress")} onChange={onFieldChange.bind(this, 'emailAddress')} value={appointment.emailAddress} type="email" readonly={readonly} />
                <DateTimeInput label={t("appointmentform.timeforappointment")} onChange={onFieldChange.bind(this, 'time')} value={appointment.time} readonly={readonly} closeOnSelect={false} />
                <UserSearch label={t("appointmentform.assignto")} onChange={onFieldChange.bind(this, 'assignee')} defaultSelected={assignee} readonly={readonly} />
                <TextInput label={t("appointmentform.subject")} onChange={onFieldChange.bind(this, 'subject')} value={appointment.subject} readonly={readonly} />
                {appointment.reason && <StaticTextInput label={t("appointmentform.reason")} value={appointment.reason} />}
                <AppointmentComments comments={appointment.allComments} />
                {!isCompleted && <TextInput label={t("appointmentform.newcomment")} maxAmountOfCharacters={4000} onChange={onFieldChange.bind(this, 'comments')} value={appointment.comments || ""} multiline={true} />}
                {!isCreation && appointment.refData && <StaticTextInput label={t("appointmentform.leadreference")} value={t("appointmentform.leaddetails")} type='link' to={appointment.refData} />}
                {!isCreation && lead && <StaticTextInput label={t("appointmentform.leadtype")} value={lead.type + ': ' + lead.productNumber} />}
                {!isCreation && lead && <StaticTextInput label={t("appointmentform.leadsubmitted")} value={helpers.formatDate(lead.dateSubmitted)} />}
                {!isCreation && lead && <LeadActionHistoryComments actionHistory={lead.actionHistory} />}
            <div className="clearfix"></div>
            {!hideButtons &&
                <div className="form-group padding-top no-left-margin btn-toolbar appointmentActions">
                    <div className="btn-group" role="group" aria-label="button group">
                        <button type="button" className="btn btn-default" onClick={onCancelClick}>{t("appointmentform.cancel")}</button>
                        {showReopenButton && <button type="button" className="btn" onClick={onReopenClick}>
                                <span className="vismaicon vismaicon-sm vismaicon-refresh"></span> {t("appointmentform.reopen")}
                            </button>
                        }
                        {!isCompleted &&
                        <>
                            <ButtonDropdown title={t("appointmentform.save")} options={[{ title: 'appointmentform.save', identifier: "save", buttonClick: onSaveClick }, { title: 'appointmentform.saveandcreate', identifier: "saveandcreate", buttonClick: onSaveAndCreateClick }]} onClickAction={this.onClick}>{"appointmentform.save"}</ButtonDropdown>
                        </>
                    }
                    </div>
                    <Loader />
                </div>
            }
        </form>
    }
}

export default withTranslation()(AppointmentForm);