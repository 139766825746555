import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import customerActions from '../../Actions/customerActions'

class TransactionServiceButton extends Component {

    onButtonClick(disabled, vonServiceId) {
        if (!this.props.customerIsValid) {
            this.props.showError();
        }
        else if (!disabled) {
            if(vonServiceId === "d64cd704-57a9-449b-a762-cf96d34b95a1"){
                this.props.toggleModal("showVismaVceModal", "Vce")
            }
            else
            {
            this.props.customerActions.activateTransactionService(vonServiceId, this.props.customerNumber);
            }
        }
    }

    render() {
        const data = this.props.transactionServiceData;
        let isButtonDisabled = data.status !== "Open";
        let message = isButtonDisabled ? data.status : null;

        if(!isButtonDisabled && !this.props.isProduction && data.isProdOnly) {
            isButtonDisabled = true;
            message = 'This service is active only in production env.'
        }

        return (
            <div>
                <button onClick={this.onButtonClick.bind(this, isButtonDisabled, data.vonServiceId)} className="btn btn-primary" disabled={isButtonDisabled}>{data.name}</button>
                {isButtonDisabled ?
                    <strong>
                        <span className={data.status === "Pending"
                            ? "vismaicon vismaicon-filled vismaicon-help vismaicon-alignCenter"
                            : "vismaicon vismaicon-filled vismaicon-success vismaicon-alignCenter"} aria-hidden="true"></span> {message}
                    </strong>
                    : null}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isProduction: state.CommonReducer.isProduction
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionServiceButton);