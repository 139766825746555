import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation, withTranslation} from 'react-i18next';
import Modal from "../Modal";
import {Dropdown} from "../FormComponents/Dropdown";
import {RadioButtons} from "../FormComponents/RadioButtons";
import QuantitySelector from "../FormComponents/QuantitySelector";
import {FormInput} from "../FormComponents/Input/Input";
import Checkbox from "../Checkbox";
import EmailTemplate from "../EmailTemplate";

const SubscriptionTerminationForm = ({ form, reasons, subscription, onChange, content }) => {
    const [currentRadio, setCurrentRadio] = useState();
    const [currentReason, setCurrentReason] = useState("");
    const { t } = useTranslation();

    if (!form || !form.data)
        return;

    const onDropdownChange = (value) => {
        setCurrentReason(value)
        onChange(form.id, 'subReason', "")
        onChange(form.id, "reason", value)
    }

    const onRadioChange = (value) => {
        setCurrentRadio(value)
        onChange(form.id, "terminationType", value)
    }

    const isDisabled = (type) => {
        if (!currentRadio)
            return true;

        if (type === 'credit') {
            return currentRadio === "0" || currentRadio === "1";
        } else if (type === 'quantity') {
            return currentRadio === "2";
        }
    }

    let radioButtons = [
        { id: 'termination-type-2', label: t("subscriptiontermination.immediatetermination"), checked: false, value: "2"},
        { id: 'termination-type-3', label: t("subscriptiontermination.invoicetermination"), checked: false, value: "0" },
        { id: 'termination-type-1', label: t("subscriptiontermination.bindingtermination"), checked: false, value: "1"}
    ]

    let quantityDisabled = isDisabled("quantity");
    let creditDisabled = isDisabled("credit");

    return <>{content && <form className="skynet-form">
        <fieldset className="col-sm-12 mb-0 pl-0">
            <div className="form-group required d-flex flex-column align-items-start">
                <RadioButtons label={t("subscriptiontermination.timeoftermination")} items={radioButtons} value={form?.data?.terminationType} onChange={onRadioChange}/>
            </div>
        </fieldset>
        <fieldset className={`col-sm-12 mb-0 pl-0 ${quantityDisabled ? "disabled" : ""}`}>
            <h4 className={`modal-title ${quantityDisabled ? "text-disabled" : ""}`}>{t("subscriptiontermination.postponetermination")}</h4>
            <div className={`form-group`}>
                <QuantitySelector value={form?.data?.periodIterationCount ? form?.data?.periodIterationCount : 0} onChange={(e) => onChange(form.id, "periodIterationCount", e)}/>
                <p className="font-13 text-disabled skynet-font-italic mb-0 mt-2">{t("subscriptiontermination.0")}</p>
                <p className="font-13 text-disabled skynet-font-italic">{t("subscriptiontermination.3")}</p>
            </div>
        </fieldset>
        <fieldset className={`col-sm-12 mb-0 pl-0 ${creditDisabled ? "disabled" : ""}`}>
            <h4 className={`modal-title ${creditDisabled ? "text-disabled" : ""}`}>{t("subscriptiontermination.credit")}</h4>
                <div className={`form-group mb-8`}>
                    <Checkbox
                        label={t("subscriptiontermination.normalcredit")}
                        value={form?.data?.behaviour}
                        checked={form?.data?.behaviour}
                        onChange={(e) => onChange(form.id, "behaviour", e.checked)}
                        disabled={creditDisabled}/>
                </div>
                <div className={`form-group mb-4`}>
                    <Checkbox
                        label={t("subscriptiontermination.internalcredit")}
                        value={form?.data?.creditKind}
                        checked={form?.data?.creditKind}
                        onChange={(e) => onChange(form.id, "creditKind", e.checked)}
                        disabled={creditDisabled}/>
                </div>
                <div className={`form-group`}>
                    <FormInput
                        label={t("subscriptiontermination.invoicetext")}
                        placeholder={t("subscriptiontermination.textshownoninvoice")}
                        value={form?.data?.invoiceLineText}
                        onChange={(input) => onChange(form.id, "invoiceLineText", input)}/>
                </div>
            </fieldset>
            <fieldset className="col-sm-12 mb-0 pl-0">
                <h4 className="modal-title">{t("subscriptiontermination.reason")}</h4>
                <div className="form-group required">
                    <Dropdown options={reasons} placeholder={t("subscriptiontermination.reasondropdown")} initialValue={form?.data?.reason} onChange={onDropdownChange} label={t("subscriptiontermination.terminationreason")}/>
                </div>
                <div className="form-group">
                    <FormInput
                        label={t("common.comment")}
                        placeholder={t("common.commentplaceholder")}
                        value={form?.data?.comment}
                        readOnly={false}
                        onChange={(input) => onChange(form.id, "comment", input)}
                    />
                </div>
                {currentReason && reasons.find(r => r.id === currentReason)?.subReasons?.length > 0 &&
                    <div className="form-group">
                        <Dropdown
                            options={reasons.find(r => r.id === currentReason)?.subReasons}
                            placeholder={t("subscriptiontermination.reasondropdown")}
                            onChange={(value) => onChange(form.id, 'subReason', value)}
                            allowClear={true}
                            initialValue={form?.data?.subReason}
                            label={t("subscriptiontermination.subterminationreason")}/>
                    </div>
                }
            </fieldset>
    </form>}
    {!content && <EmailTemplate data={form.data} onChange={(field, value) => onChange(form.id, field, value)}/>}</>
}
export const SubscriptionTerminationModal = ({form, onLoad, onClose, onSave, onChange, reasons, onNextClick, subscription }) => {
    const {t} = useTranslation();
    const [toggleContent, setToggleContent] = useState(true);

    useEffect(() => {
        onLoad();
    }, []);

    const additionalButtonClick = () => {
        if (toggleContent) {
            onNextClick(() => setToggleContent(!toggleContent));
        } else {
            setToggleContent(!toggleContent);
        }
    }

    const modalProps = {
        title: subscription?.package?.name ? `${t("subscriptiontermination.terminationof")} ${subscription?.package?.name}` : t("subscriptiontermination.termination"),
        stateful: true,
        open: true,
        bodyHeight: 200,
        onCancelClick: () => onClose(form.id),
        additionalButton: true,
        onAdditionalClick: additionalButtonClick,
        additionalButtonTitle: toggleContent ? t("common.next") : t("common.return"),
        onSaveClick: onSave,
        mainButtonTitle: t("common.save"),
        disableMainButton: toggleContent
    }

    return form && form.data && <Modal {...modalProps}><SubscriptionTerminationForm form={form} reasons={reasons} subscription={subscription} onChange={onChange} content={toggleContent}/></Modal>
}