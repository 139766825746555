import React from 'react';
import PastActionInfo from '../PastActionInfo'
import { useTranslation } from 'react-i18next';
import { NoInformation } from '../Panel';

const InactiveProducts = ({ products, getPurchaseDate, getEndDate, getTermination, getProductName }) => {
    const { t } = useTranslation();
    let key = 1;

    let showModuleColumn = products.some(item => item.module);

    return (products && products.length > 0 ?
        <div className="table-responsive">
            <table className="table table-stripe table-condensed">
                <thead>
                    <tr>
                        <th className="text-left">{t('vonproducts.program')}</th>
                        {showModuleColumn && <th className="text-left">{t('vonproducts.module')}</th>}
                        <th className="text-left">{t('vonproducts.purchasedate')}</th>
                        <th className="text-left">{t('vonproducts.enddate')}</th>
                        <th className="text-left">{t('vonproducts.termination')}</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map(item =>
                        <tr key={key++}>
                            <td className="text-left text-nowrap">{getProductName ? getProductName(item) : item.product}</td>
                            {showModuleColumn && <td className="text-left text-nowrap">{item.module}</td>}
                            <td className="text-left text-nowrap">{getPurchaseDate ? getPurchaseDate(item) : item.purchaseDate}</td>
                            <td className="text-left text-nowrap">{getEndDate ? getEndDate(item) : item.endDate}</td>
                            <td>{getTermination ? getTermination(item) : item.terminationReason}</td>
                        </tr>)
                    }
                </tbody>
            </table>
        </div> : <NoInformation />
    )
}

const renderLastActionInfo = (product) => {
    return product.endDate
}

export default InactiveProducts;