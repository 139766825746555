import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class CustomerProducts extends Component {

    renderOnPremProducts(onPremProducts) {
        let key = 1;
        const { t } = this.props;
        return onPremProducts && onPremProducts.length > 0 && <>
        <h3>{t('customerproducts.products')}</h3>
        {onPremProducts.map(item =>
            <div key={key++} className="pb-10">
                <div><strong>{item.product}</strong> ({item.purchaseDate} - {!item.isProductValid ? <span style={{ color: "red" }}>{item.endDate}</span> : item.endDate})</div>
                <div className="text-left">{t('customerproducts.users')}{item.users}</div>
                {item.api && <div className="text-left">{t('customerproducts.api')}{item.api}</div>}
            </div>)}
        </>
    }

    renderSaasProducts(vonProducts) {
        let key = 1;
        const { t } = this.props;

        return vonProducts && vonProducts.length > 0 && <>
            <h3>{t('vonproducts.header')}</h3>
                {vonProducts.map(item =>
                    <div key={key++} className="pb-10">
                        <div><strong>{item.product}</strong> ({item.purchaseDate} - {!item.isProductValid ? <span style={{ color: "red" }}>{item.endDate}</span> : item.endDate})</div>
                        <div className="text-left">{t('vonproducts.module') + ': '} {item.module}</div>
                    </div>)}
        </>
    }

    renderCompanyExpenseProducts(companyExpenseProducts) {
        let key = 1;
        const { t } = this.props;
        return companyExpenseProducts && companyExpenseProducts.length > 0 && <>
            <h3>{t('customerproducts.thirdpartyproducts')}</h3>
                {companyExpenseProducts.map(item =>
                    <div key={key++} className="pb-10">
                        <div><strong>{item.product}</strong> ({item.purchaseDate}- )</div>
                    </div>)}
        </>
    }

    render() {
        const customer = this.props.customer;

        return (
            <div>
                {this.renderOnPremProducts(customer.products)}
                {this.renderSaasProducts(customer.vonProducts)}
                {this.renderCompanyExpenseProducts(customer.companyExpenseProducts)}
            </div>
        );
    }
}

export default withTranslation()(CustomerProducts);