import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import routes from '../../Constants/routes'

import customerActions from '../../Actions/customerActions'

import helpers from '../../Utils/helpers'
import Card from '../../Components/Card/Card';

function VonAccounts({ customer, customerActions, searchVonAccountInput, vonAccounts, t }) {

    const [accountLines, setAccountLines] = useState([]);
    const [VismaOnlineAccountLines, setVismaOnlineAccountLines] = useState([]);
    const [showAdmins, setShowAdmins] = useState(false);
    const [groupedVonAccounts, setGroupedVonAccounts] = useState([]);

    const navigate = useNavigate()
    const { customerNumber } = useParams();

    useEffect(() => {
        if (!customer.hasVonAccount) {
            navigate(routes.customerNewVonAccount(customer.customerNumber));
        } else {
            customerActions.getVonAccounts(customerNumber);
        }

        return () => {
            customerActions.setSearchVonAccountInput(null);
        }
    }, [])

    useEffect(() => {

        if (vonAccounts) {
            setAccountLineValues();
        }

    }, [vonAccounts])

    const renderProductLines = (product, cssClass, key) => {
        return (
            <tr key={key} className={cssClass}>
                <td className="text-left borderLeft">{product.productName}</td>
                <td className="text-left text-capitalize">{product.role}</td>
            </tr>
        );
    }

    const renderAccountHeader = (item, cssClass, key) => {
        const firstProduct = item.roles && item.roles[0];
        const rowSpanVal = item.roles.length === 0 ? 1 : item.roles.length;
        return (
            <tr key={key} className={cssClass} onClick={() => navigate(routes.customerEditVonEmployee(customerNumber, item.id))}>
                <td className="text-left valignTop" rowSpan={rowSpanVal}
                    style={item.isPrimaryContact ? { fontWeight: 'bold' } : { fontWeight: 'normal' }}>{item.name}</td>
                <td className="text-left valignTop" rowSpan={rowSpanVal}><a
                    href={"mailto:" + item.email}>{item.email}</a></td>
                <td className="text-left">{firstProduct && firstProduct.productName}</td>
                <td className="text-left text-capitalize">{firstProduct && firstProduct.role}</td>
            </tr>
        )
    }

    const onChange = (event) => {
        customerActions.setSearchVonAccountInput(event.target.value);
    }

    const filterVonAccounts = () => {
        const searchInput = searchVonAccountInput;

        if (!/\S/.test(searchInput) || typeof searchInput == "undefined" || searchInput === null) {
            return vonAccounts;
        }
        return helpers.filterVonAccountArrayByValue(vonAccounts, searchInput)
    }

    const groupVonAccounts = (vonAccounts) => {
        var arr = [];
        vonAccounts.forEach(account => {
            arr.push(...account.roles);
        });

        return arr.reduce((result, role) => {
            const product = role.productName || 'n/a';
            const specifiedProductName = role.specifiedProductName || "";
            var roles = role.role || 'n/a';
            result[product] = result[product] || [];
            result[product].specifiedProductName = specifiedProductName;
            result[product].push(roles);

            return result;

        }, Object.create(null));
    }

    const renderRoles = (accountList, product, role) => {
        return (
            <li className="list-group-item p-2" style={{ listStyle: "none" }}>
                <span className="badge">{accountList[product].length}</span>
                <span> </span>
                {!!role.specifiedProductName ? `${product} - ${role.specifiedProductName}` : product}
            </li>
        );
    }

    const pushToArray = (array) => {
        let x = 1;
        let tmp = [];

        for (var item of array) {
            tmp.push(renderAccountHeader(item, 'hl', x++))

            for (var i = 1; i < item.roles.length; i++) {
                tmp.push(renderProductLines(item.roles[i], 'blank', x++))
            }
        }
        return tmp
    }

    const setAccountLineValues = () => {
        const vonAccounts = filterVonAccounts().sort((a, b) => b.isPrimaryContact - a.isPrimaryContact);
        const customerAdminsForVismaOnline = vonAccounts.filter(a => a.roles.find(r => r.productName === 'Visma Online' && r.role === 'customeradmin'))

        const vonAdminAccountLines = pushToArray(customerAdminsForVismaOnline);
        const vonAccountLines = pushToArray(vonAccounts);

        setGroupedVonAccounts(groupVonAccounts(vonAccounts));
        setVismaOnlineAccountLines(vonAdminAccountLines);
        setAccountLines(vonAccountLines);
    }

    if (!vonAccounts) {
        return null;
    }

    return <Card>
        <Card.Header>
            <Card.Header.Title>VON Accounts</Card.Header.Title>
        </Card.Header>
        <Card.Content>
            <div className='mb-10'>
                <button className='btn btn-default' onClick={() => navigate(routes.customerNewVonEmployee(customer.customerNumber))}>Skapa ny användare</button>
            </div>
            <div className="mb-10">
                <input placeholder={t("vonaccountscontainer.searchfield")} className="form-control" type="text"
                    autoFocus value={searchVonAccountInput} onChange={onChange.bind(this)} />
            </div>

            <div className="mb-10">
                {Object.keys(groupedVonAccounts).map((product, index) => {
                    return (
                        <div className="col-md-6" key={index}>
                            {renderRoles(groupedVonAccounts, product, groupedVonAccounts[product])}
                        </div>
                    )
                })}
            </div>

            <br />
            <hr />
            <button className='btn btn-default'
                onClick={() => setShowAdmins(!showAdmins)}>{showAdmins ? t("vonaccountscontainer.showall") : t("vonaccountscontainer.showadmins")}</button>

            <table className="table table-stripe table-vonAccount table-active">
                <thead>
                    <tr>
                        <th className="text-left">{t("vonaccountscontainer.name")}</th>
                        <th className="text-left">{t("vonaccountscontainer.email")}</th>
                        <th className="text-left">{t("vonaccountscontainer.product")}</th>
                        <th className="text-left">{t("vonaccountscontainer.role")}</th>
                    </tr>
                </thead>
                <tbody>
                    {showAdmins ? VismaOnlineAccountLines : accountLines}
                </tbody>
            </table>
        </Card.Content>
    </Card>
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.selectedCustomer,
        vonAccounts: state.CustomerReducer.vonAccounts,
        searchVonAccountInput: state.CustomerReducer.searchVonAccountInput
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VonAccounts));