import React, { useState } from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import helpers from '../../../Utils/helpers'
import {hasAdminAccess} from "../../../Utils/permissionUtils";
import {UserRoles} from "../../../Constants/userRoles";

export function InternalInfo({ customer, isEditing, dropdownlists, user }) {
    const paymentTerms = [
        { id: 30, name: "30 dagar netto" },
        { id: 2, name: "2 postförskott" }]
    const customerGroups = dropdownlists && dropdownlists.CustomerGroup;
    const customerTypes = dropdownlists && dropdownlists.CustomerType;
    const customerStatuses = dropdownlists && dropdownlists.CustomerStatus;
    const customerGroups2 = dropdownlists && dropdownlists.CustomerGroup2;
    const spcsEdi = dropdownlists && dropdownlists.SpcsEdi;
    const vbAdmin = hasAdminAccess(user, UserRoles.InternalInfo)

    const { t } = useTranslation();

    const getCustomerInvoiceFee = () => {

        switch (customer.invoiceFee) {
            case 8192:
                return "Yes"
            case 8200:
                return "No"
            default:
                return customer.invoiceFee
        }
    }

    function getCustomerInvoiceFees(customer) {
        var invoiceFees = [
            { id: 8192, name: "Yes" },
            { id: 8200, name: "No" }
        ];

        if (invoiceFees.filter(item => item.id == customer.invoiceFee).length == 0) {
            invoiceFees.push({ id: customer.invoiceFee, name: customer.invoiceFee });
        }

        return invoiceFees;
    }

    const formatGroupName = (group) => {
        if (group.id > 0) {
            return `${group.name} / ${group.id}`;
        }
    }

    function renderField(label, value) {
        return (<>
                <th className="bold">{label}</th>
                <td>{value}</td>
            </>
        )
    }


    // VOSS: Company created,Customer is using 2FA
    // CustomerType -> Target group
    return (<div className="table-responsive">
            <table className={`table ${isEditing ? '' : 'table-stripe'}`}>
                <tbody>
                <tr>
                    {renderField(t('internalinfocontainer.customergroup'),customer.group !== 0 && `${helpers.getDropdownItemName(customerGroups, customer.group)} / ${customer.group}`, customerGroups, formatGroupName(customer.group))}
                    {renderField(t('internalinfocontainer.voncustomergroup'), customer.vonGroup !== 0 && `${helpers.getDropdownItemName(customerGroups, customer.vonGroup)} / ${customer.vonGroup}` || "-")}
                </tr>
                <tr>
                    {renderField(t('internalinfocontainer.customergroup2'), customer.secondaryGroup !== 0 && `${helpers.getDropdownItemName(customerGroups2, customer.secondaryGroup)} / ${customer.secondaryGroup}` || "Empty", customerGroups2)}
                    {renderField(t('internalinfocontainer.customertype'), helpers.getDropdownItemName(customerTypes, customer.type))}
                </tr>
                <tr>
                    {renderField(t('internalinfocontainer.customerstatus'), helpers.getDropdownItemName(customerStatuses, customer.status), customerStatuses)}
                    {renderField(t('internalinfocontainer.creditlimit'), customer.creditLimit)}
                </tr>
                <tr>
                    {renderField(t('internalinfocontainer.invoicefee'), getCustomerInvoiceFee(customer), getCustomerInvoiceFees(customer))}
                    {renderField(t('internalinfocontainer.spcsedi'), helpers.getDropdownItemName(spcsEdi, customer.spcsEdi) || "-")}
                </tr>
                <tr>
                    {renderField(t('internalinfocontainer.responsibleperson'), customer.responsibleSalesPersonName || "-",)}
                    {renderField(t('internalinfocontainer.paymentterms'), helpers.getDropdownItemName(paymentTerms, customer.paymentTerms))}
                </tr>
                </tbody>
            </table>
        </div>
    )
}
