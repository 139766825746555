import React, { useRef } from 'react'
import { connect } from 'react-redux';
import commonActions from '../../Actions/commonActions';
import routes from '../../Constants/routes'
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import Shortcuts from '../Layout/Shortcuts';
import { useNavigate } from 'react-router-dom';
import aocustomerLogo from "../../Images/aocustomer.svg"
import aocustomerLogoDark from "../../Images/aocustomerDark.svg"
import customerLogo from "../../Images/customer.svg"
import customerLogoDark from "../../Images/customerDark.svg"
import integrationPartnerLogo from "../../Images/integrationPartner.svg"
import integrationPartnerLogoDark from "../../Images/integrationPartnerDark.svg"
import resellerLogo from "../../Images/reseller.svg"
import resellerLogoDark from "../../Images/resellerDark.svg"
import SkynetCopy from "../Fundamentals/SkynetCopy";

function CustomerTopMenu({ t, customer, commonActions }) {

    const imgRef = useRef(null);
    const navigate = useNavigate();

    const navigateHome = () => {
        navigate(routes.customerMain(customer.customerNumber))
    }

    const containsProduct = (productArray, productNumber) => {
        return productArray && productArray.some(product => product.productNumber === productNumber);
    }
    const getCustomerImg = () => {
        let logo = customerLogo;
        let darkmodeLogo = customerLogoDark;
        let title = "Customer";

        if (customer.group >= 1800 && customer.group <= 1899) {
            logo = aocustomerLogo
            darkmodeLogo = aocustomerLogoDark
            title = "Accounting Office"
        } else if ((customer.group >= 3000 && customer.group <= 3999) || customer.type === 2) {
            logo = resellerLogo
            darkmodeLogo = resellerLogoDark
            title = "Reseller - Contact PartnerSales"
        } else if (containsProduct(customer.products, "ADMIN.API.UTV")) {
            logo = integrationPartnerLogo
            darkmodeLogo = integrationPartnerLogoDark
            title = "Integration Partner - Contact TechSales"
        }

        return (<>
            <img className='skynet-customer-logo pt-2 cursorpointer show-on-light' alt={"Customer logo"} title={title}
                 ref={imgRef} src={logo} onClick={() => navigateHome('default')}/>
            <img className='skynet-customer-logo pt-2 cursorpointer show-on-dark' alt={"Customer logo"} title={title}
                 ref={imgRef} src={darkmodeLogo} onClick={() => navigateHome('default')}/>
        </>)
    }

    return <>
        <div className="d-flex pb-8">
            {getCustomerImg()}
            <div className='flex-colum flex-auto pl-8'>
                <h1 className="m-0 bold">{(customer.isNonInteractive ? "Non-interactive: " : "") + customer.name}</h1>
                <div>
                    <span className="pr-8">Kundnr:</span>
                    <SkynetCopy label={t("customerinfo.customernumber")} value={customer.customerNumber}>{customer.customerNumber}</SkynetCopy>
                </div>
                <div>
                    <span className="pr-8">Orgnr:</span>
                    <SkynetCopy label={t("customerinfo.organizationnumber")} value={customer.organizationNumber}>{customer.organizationNumber}</SkynetCopy>
                </div>
            </div>
        </div>
        <div className="mb-8 skynet-customer-shortcuts-container">
            <Shortcuts/>
        </div>
    </>
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.selectedCustomer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
    }
}


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CustomerTopMenu));