import Card from "./Card";
import React, { useState } from "react";
import './Card.css'
import {useTranslation} from "react-i18next";

export default function SubscriptionCard({ title, children, headerContent, activeContent, inactiveContent }) {
    const [isActive, setIsActive] = useState(true);
    const className = "btn btn-sm btn-default"
    const { t } = useTranslation();
    function editAction(event, active) {
        event.stopPropagation();
        setIsActive(active);
    }

    return (
        <Card>
            <Card.CHeader>
                <Card.Header.Title>{title}</Card.Header.Title>
                {headerContent}
                <Card.Header.Buttons>
                    <button type="button" className={`${className} skynet-sub-border ${isActive ? " active" : ""}`} onClick={(event) => editAction(event, true)}>{t("subscriptioncard.activesubscriptions")}</button>
                    <button type="button" className={`${className} skynet-sub-border ${isActive ? "" : " active"}`} onClick={(event) => editAction(event, false)}>{t("subscriptioncard.inactivesubscriptions")}</button>
                </Card.Header.Buttons>
            </Card.CHeader>
            <Card.Content>
                {isActive ? activeContent : inactiveContent}
                {children}
            </Card.Content>
        </Card>
    );
}
