import { useEffect } from "react";
import { Dropdown } from "../../../FormComponents/Dropdown";
import { FormInput } from "../../../FormComponents/Input/Input";
import routes from "../../../../Constants/routes";
import { useNavigate } from "react-router-dom";
import DateTimeInput from "../../../FormComponents/Input/DateTimeInput";
import { newEmployee, VonEmployeeTypes } from "../../../../Constants/VON/EmployeeForm/vonEmployeeTypes";

export default function VonNewEmployeeForm({ newVonEmployeeForm, commonActions, customerActions, customer }) {

    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            commonActions.cancelFormEditing('newVonEmployeeForm');
        }
    }, []);

    const onSubmit = () => {
        customerActions.addVonEmployee(newVonEmployeeForm, customer.customerId, () => navigate(routes.customerVonAccounts(customer.customerNumber)));
    };

    const onChange = (value, field) => {
        commonActions.changeMultiFormField('newVonEmployeeForm', field, value);
    };

    if (!newVonEmployeeForm) return null;
    const { data } = newVonEmployeeForm;

    return <div>
        {data.userAlreadyExists && <div>
            <p>The user {data.email} is already in use in another company. The first and last name can therefore not be edited.</p>
        </div>
        }
        <fieldset className="d-grid col-lg-6 gap-24 mb-0">
            <fieldset className="d-grid col-lg-6 gap-16 mb-0">
                <FormInput label='Email address' value={data.email} disabled />
                <FormInput label='First name' value={data.firstName} disabled={data.userAlreadyExists} required onChange={(value) => onChange(value, 'firstName')} />
                <FormInput label='Last name' value={data.lastName} disabled={data.userAlreadyExists} required onChange={(value) => onChange(value, 'lastName')} />
                <Dropdown label='Type of user' options={newEmployee} initialValue={data.type} value={data.type} required onChange={(value) => onChange(value, 'type')} />
                {data.type === VonEmployeeTypes.Conversion.id && <DateTimeInput label={"Valid until"} dateFormat={"YYYY-MM"} showTime={false} onChange={(value) => onChange(value, 'validUntil')} />}
            </fieldset>
            <div>
                <button className='btn btn-default' onClick={() => onSubmit()}>Fortsätt</button>
                <button className='btn btn-default' onClick={() => navigate(routes.customerVonAccounts(customer.customerNumber))} >Avbryt</button>
            </div>
        </fieldset>
    </div>
}