import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import LeftNavigationBarItem from '../LeftNavigationBarItem'
import routes from '../../Constants/routes'

class EmailLinks extends Component {

    constructor(props) {
        super(props);
        this.initState = {
        }

        this.state = this.initState;
    }

    getLinks() {
        const { customer: { customerNumber }, t } = this.props;

        return [
            {
                title: t('leftnavigation.emailforms'), id: "email", onClick: () => this.props.setHeader("email"), path: routes.customerAdminForms(customerNumber)
            }
        ];
    }


    render() {

        return <>
                {this.getLinks().filter(link => !link.disabled).map((item, index) => {
                    return <LeftNavigationBarItem key={index} link={item} location={this.props.location} openHeader={this.props.openHeader} />
                })}
            </>
    }
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.selectedCustomer,
        openHeader: state.CommonReducer.openHeader
    }
}

export default connect(mapStateToProps)(withTranslation()(EmailLinks));