import React from 'react';
import { useTranslation } from 'react-i18next';

import './SearchResults.css'

const SearchHistory = ({clickEvent}) => {
  const {t} = useTranslation();

    const clearHistory = () => {

        localStorage.removeItem("CustomerSearchHistory");
    }
    
    const customerSearchHistory = localStorage.getItem("CustomerSearchHistory");

    const customers = customerSearchHistory ? JSON.parse(customerSearchHistory) : [];

    
    if(!customers)
        return null;

    if(customers.length === 0)
        return null;    

    return (
        <div className="searchResults" style={{zIndex:"1"}}>
          <h3>{t('searchhistory.lastvisitedcustomer')} <a href="#" onClick={() => clearHistory()} >{t('searchhistory.clearhistory')} </a></h3>
          <table className="table table-stripe table-hover">
            <thead>
                <tr>
                  <th width="200" className="text-right">{t('searchhistory.organizationnumber')} </th>
                  <th width="200" className="text-right">{t('searchhistory.customernumber')} </th>                  
                  <th>{t('searchhistory.name')} </th>
                </tr>
            </thead>
            <tbody>
            {
              customers.reverse().map(customer =>
                <tr key={customer.customerNumber} onClick={() => clickEvent(customer.customerNumber) } className="cursorpointer">
                  <td className="text-right">{customer.organizationNumber}</td>
                  <td className="text-right">{customer.customerNumber}</td>
                  <td className="text-left">{customer.name}</td>
                </tr>
              )
            }
            </tbody>
          </table> 
        </div>
      );
  }

  export default SearchHistory;