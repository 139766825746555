import { httpFetch } from '../../Utils/httpUtils'
import { internalCustomerAdminActionTypes as actionTypes } from '../../ActionTypes/InternalCustomerAdmin/internalCustomerAdminActionTypes'
import commonActions from '../commonActions';

const activityLogActions = {

    searchLogs(data) {
        return (dispatch) => {

            dispatch(commonActions.progressLoader(true));

            return httpFetch(`/api/ActivityLog/GetActivityLogs?searchBy=${data.searchBy}&value=${data.value}&fromDate=${data.fromDate}&toDate=${data.toDate}&applicationId=${data.applicationId}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_ACTIVITY_LOGS, data: json });
                })
                .catch(error => {
                    console.error(error);
                    dispatch({ type: actionTypes.STORE_ACTIVITY_LOGS, data: [] });
                });
        }
    },

    clearLogs() {
        return (dispatch) => {
            dispatch({ type: actionTypes.STORE_ACTIVITY_LOGS, data: null });
        }
    },

    searchFailedMails(data) {
        return (dispatch) => {
            return httpFetch(`/api/ActivityLog/GetFailedMails?email=${data.value}&fromDate=${data.fromDate}&toDate=${data.toDate}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_ACTIVITY_LOGS, data: json });
                })
                .catch(error => {
                    console.error(error);
                    dispatch({ type: actionTypes.STORE_ACTIVITY_LOGS, data: [] });
                });
        }
    }
}
export default activityLogActions;