import React from 'react'
import ConnectedCustomerDetails from './ConnectedCustomerDetails'
import { useTranslation } from 'react-i18next';

const MainCustomerDetails = ({mainCustomer, title}) => {
    const { t } = useTranslation();
    
    if(!mainCustomer) 
        return null;

    return  <div className="customerConnections">
                <h4><span className="vismaicon vismaicon-sm vismaicon-user" />{t(title + '.header')}<small>{t(title + '.subheader')}</small></h4>
                <ConnectedCustomerDetails customer={mainCustomer} />
            </div>
}

export default MainCustomerDetails;