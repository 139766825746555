import { useTranslation } from "react-i18next";
import Card from "../../../Card/Card";
import TrialSearch from "../../../Order/TrialSearch";
import AlertSmall from "../../../AlertSmall";
export function BlikkTrialSearch ({ product, order, orderActions, trialSearchResults}){

    const { t } = useTranslation();

    const onTrialSearch = (query) => {
        orderActions.trialSearch(query);
    }

    const onTrialClick = (tenantGuid) => {
        orderActions.getBlikkProductDetails(product.productId, order.customerNumber, order.orderId, tenantGuid);
    }

    return (
        <Card>
            <Card.Header>
                <Card.Header.Title>{t("blikkproductinfo.title")}</Card.Header.Title>
            </Card.Header>
            <Card.Content>
                <AlertSmall alert={{ type: "info", title: t('productdetailscontainer.notfound'), message: t('productdetailscontainer.searchtrial') }} />
                <TrialSearch results={trialSearchResults} onSearch={onTrialSearch} onTrialClick={onTrialClick} />
            </Card.Content>
        </Card>
    );
};
