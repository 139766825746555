import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom'
import CoursesContainer from './../Customer/CoursesContainer'
import WebinarsContainer from './../Customer/WebinarsContainer'
import OnboardingContainer from './../Customer/OnboardingContainer'
import Panel from "../../Components/Panel";
import routes from "../../Constants/routes";
import PillNavigation from "../../Components/Navigation/PillNavigation";

function EducationsContainer ({customer, t}){
    const links =   [
        { to: routes.courses(customer.customerNumber), text: t('educationsnavigation.courses') },
        { to: routes.webinars(customer.customerNumber), text: t('educationsnavigation.freewebinars') },
        { to: routes.onboarding(customer.customerNumber), text: t('educationsnavigation.onboarding') }
    ];

        return(
            <Panel title={t('educationscontainer.educations')}>
                <PillNavigation path={window.location.pathname} links={links} />
                <Routes>
                    <Route path={'/courses/'} element={<CoursesContainer/>} />
                    <Route path={'/webinars/'} element={<WebinarsContainer/>} />
                    <Route path={'/onboarding/'} element={<OnboardingContainer/>} />
                </Routes>
            </Panel>
        )
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.selectedCustomer
    }
}

export default connect(mapStateToProps, null)(withTranslation()(EducationsContainer));