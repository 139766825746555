
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import React from "react";
import EditCard from "../../Components/Card/EditCard";
import {MainInfo} from "../../Components/Customer/CustomerComponents/MainInfo";
import commonActions from "../../Actions/commonActions";
import customerActions from "../../Actions/customerActions";
import CustomerInfoFlagsContainer from "./CustomerInfoFlagsContainer";


export function MainInfoContainer({ customer, toggleModal, customerForm, commonActions }) {
    const { t } = useTranslation();

    async function onSaveClick(){
        /*
        if (customerForm.data.mainCustomer == "" || (customerForm.data.subCustomers && customerForm.data.subCustomers.length > 0 && customerForm.data.subCustomers.every(i => i == ""))) {
            commonActions.showWarning("Main customer is either not selected, or every added sub customer is empty, select main customer or remove open sub customer fields. Please validate!");
            return;
        }
        else if (customerForm.data.group === 1001 && (customerForm.data.mainCustomer == null)) {
            commonActions.showWarning("Main customer is missing or isn't selected. Add main customer and try again. Please validate!");
            return;
        }
    
        await customerActions.saveCustomer(customerForm, isCreation)
        props.changeEditInternalInfo();*/
    }



    function getActions() {
        const actions = {
            editAction: () => console.log("not implemented"), //customerActions.editMainInfo(customer),
            cancelAction: () => commonActions.cancelFormEditing("mainInfoFormData"),
            saveAction: () => onSaveClick()
        }
        return actions;
    }

    function getHeader() {
        return (<>{/* TODO: Add in Skynet when verified customer is implemented
            <Tooltip>
                <TooltipTrigger>
                    {<img className='pl-8 pr-16' alt={"Verified Customer"} src={verifiedcustomer} />}
                </TooltipTrigger>
                <TooltipContent>
                    <div>{t('maininfocontainer.verifiedcustomer')}</div>
                </TooltipContent>
            </Tooltip>*/}
                <div className="ml-8">
                    <CustomerInfoFlagsContainer/>
                </div>
            </>
        )
    }

    return <>
        <EditCard
            title={t("maininfocontainer.header")}
            actions={getActions()}
            formContent={<MainInfo customer={customer}/>}
            headerContent={getHeader()}
        />
    </>
}

function mapStateToProps(state) {
    return {
        customerForm: state.CommonReducer.forms && state.CommonReducer.forms.mainInfoFormData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MainInfoContainer));