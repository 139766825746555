import { useTranslation } from 'react-i18next';
import nonactive from '../../../Images/nonactive.svg'
import SwitchToggle from '../../SwitchToggle';
import Tooltip, {TooltipContent, TooltipTrigger} from "../../Overlays/Tooltip";
import AlertSmall from "../../AlertSmall";
import React from "react";
import Card from "../../Card/Card";
function TransactionServiceRow({ service, onClick, disabled }) {
    let pending = service.status === "Pending";
    let activated = service.status === "Activated";

    function getIcon() {
        switch(service.status) {
            case "Pending":
                return <span className="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-changelog icon-warning"></span>
            case "Activated":
                return <span className="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-filled vismaicon-success"></span>
            default:
                return <img className='pl-0' alt={"Non active"} src={nonactive} />
        }
    }

    return (
        <tr key={service.name}>
            <td className="pr-0 pt-12 d-flex position-static">
                <SwitchToggle onChange={() => onClick(service)} checked={activated || pending} disabled={disabled || activated || pending} size="sm" />
            </td>
            <td>{service.name}</td>
            <td ><div className="d-flex justify-content-center">{getIcon()}</div></td>
        </tr>
    )

}

export function TransactionServices({ customer, customerActions, commonActions, toggleModal, isProduction}) {
    const { t} = useTranslation();

    function onClick(service) {
        if (isProduction || !service.isProdOnly) {
            if (service.vonServiceId === "d64cd704-57a9-449b-a762-cf96d34b95a1") {
                toggleModal("showVismaVceModal", "Vce")
            }
            else {
                customerActions.activateTransactionService(service.vonServiceId, customer.customerNumber);
            }
        } else {
            commonActions.showWarning("Only works in production environment")
        }
    }

    let transactionServices = customer.transactionServices;
    let disabled = !customer.hasVonAccount;

    return <>
        {disabled ? <AlertSmall alert={{type: "warning", title: t('transactionservicescontainer.novonaccount')}}/> :
        <table className="table table-stripe table-hover">
            <thead>
            <tr className="bold text-left has-error">
                <th scope="col" width="50" className="pr-0">{t('actionhistory.action')}</th>
                <th scope="col" className="text-left">{t('common.service')}</th>
                <th scope="col" width="50" className="text-left">{t('common.status')}</th>
            </tr>
            </thead>
            <tbody>
                {transactionServices.map(item => <TransactionServiceRow key={item.name} service={item} onClick={onClick} disabled={disabled} />)}
            </tbody>
        </table>}
    </>
}