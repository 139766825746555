import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import terminationReasonsActions from "../../../../Actions/InternalCustomerAdmin/terminationReasonsActions";
import Card from "../../../../Components/Card/Card";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import commonActions from "../../../../Actions/commonActions";
import { TerminationReasonForm } from "../../../../Components/InternalCustomerAdmin/TerminationReasons/TerminationReasonsForm";

function TerminationReasonFormContainer({ ...props }) {
    const { terminationReasonsActions, commonActions, terminationReasonForm, isEdit } = props;
    const [searchParams] = useSearchParams();

    const reasonId = searchParams.get("ReasonId")
    const parentId = searchParams.get("ParentId")

    useEffect(() => {
        terminationReasonsActions.getTerminationReasons();
        terminationReasonsActions.getTerminationReasonForEdit(reasonId, parentId);

        return () => {
            commonActions.cancelFormEditing("terminationReasonForm");
        }
    }, []);

    if (!terminationReasonForm)
        return null;

    return <Card>
        <Card.Header>
            <Card.Header.Title>
                {isEdit ? 'Redigera orsak' : terminationReasonForm.data.parentId ? 'Skapa en ny underorsak' : 'Skapa en ny orsak'}
            </Card.Header.Title>
        </Card.Header>
        <Card.Content>
            <TerminationReasonForm {...props} />
        </Card.Content>
    </Card>
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        terminationReasonsActions: bindActionCreators(terminationReasonsActions, dispatch)
    };
}

function mapStateToProps(state) {
    return {
        terminationReasonForm: state.CommonReducer.forms && state.CommonReducer.forms.terminationReasonForm,
        terminationReasons: state.InternalCustomerAdminReducer.terminationReasons,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TerminationReasonFormContainer);