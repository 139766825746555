import React, {useEffect} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Card from "../../Components/Card/Card";
import Loader from "../../Components/Loader";
import {bindActionCreators} from "redux";
import customerActions from "../../Actions/customerActions";
import commonActions from "../../Actions/commonActions";
import helpers from "../../Utils/helpers";
import {MainInfoEditContent} from "../../Components/Customer/CustomerComponents/MainInfoEditContent";
import {ActionType} from "../../Constants/actionType";
import {InternalInfoEditContent} from "../../Components/Customer/CustomerComponents/InternalInfoEditContent";
import {useNavigate} from "react-router-dom";
import routes from "../../Constants/routes";

function EditCustomerContainer({ dropdownlists, customerForm, customerActions, commonActions, actionType, t }) {
    const navigate = useNavigate();

    useEffect(() => {
        const leadId = helpers.getUrlQueryParameter(window.location, 'leadId');
        const input = helpers.getUrlQueryParameter(window.location, 'input');

        customerActions.beginCustomerCreation(input, leadId);
    }, )

    const navigateAfterAction = () => {
        if (customerForm.data.leadId) {
            return () => navigate(routes.leadEdit(customerForm.data.leadId));
        }
        return () => navigate(routes.search(''));
    }
    const saveAction = () => {
        customerActions.createCustomer(customerForm);
    }
    const onCancelClick = () => {
        commonActions.cancelFormEditing(customerForm.id, navigateAfterAction());
    }

    return (<>
            <Card>
        <Card.Header>
            <Card.Header.Title>{`Create new customer: ${customerForm.data.name} (${customerForm.data.organizationNumber})`}</Card.Header.Title>
        </Card.Header>
        <Card.Content>
            <form className="container">
                <div className="row">
                    <div className="col">
                        <MainInfoEditContent data={customerForm.data} commonActions={commonActions} actionType={actionType} form={customerForm}/>
                    </div>
                    <div className="col">
                        <InternalInfoEditContent data={customerForm.data} dropdownlists={dropdownlists} commonActions={commonActions} actionType={actionType} form={customerForm}/>
                    </div>
                </div>
            </form>
        </Card.Content>
                {<Card.Footer>
                    <Card.Footer.Buttons>
                        {<>
                            <Loader/>
                            <button type="button" className="btn btn-primary"
                                    onClick={saveAction}>{t("common.save")}</button>
                            <button type="button" className="btn btn-default"
                                    onClick={onCancelClick}>{t("common.cancel")}</button>
                        </>}
                    </Card.Footer.Buttons>
                </Card.Footer>}
            </Card></>)
}
function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.selectedCustomer,
        customerForm: state.CommonReducer.forms && state.CommonReducer.forms.vossCustomerFormData,
        dropdownlists: state.CommonReducer.dropdownlists
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditCustomerContainer));