import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'

import AppointmentForm from '../../Components/Appointment/AppointmentForm'
import commonActions from '../../Actions/commonActions'
import appointmentActions from '../../Actions/appointmentActions'


class AppointmentFormContainer extends Component {

    componentWillUnmount() {
        const { commonActions, appointmentForm } = this.props;
        
        commonActions.clearStoreSelected();
        
        appointmentForm && commonActions.cancelFormEditing(appointmentForm.id);
    }

    onSaveClick() {
        const { appointmentActions, appointmentForm, isCreation, callback } = this.props;

        appointmentActions.saveAppointment(appointmentForm, isCreation, this.getCustomerNumberForCallback(), callback)
    }

    onSaveAndCreateClick() {
        const { appointmentActions, appointmentForm, isCreation, callback } = this.props;

        appointmentActions.saveAppointment(appointmentForm, isCreation, this.getCustomerNumberForCallback(), callback, true)
    }

    onCancelClick() {
        const { commonActions, appointmentForm, callback } = this.props;

        commonActions.cancelFormEditing(appointmentForm.id, callback);
    }

    onReopenClick() {
        const { appointmentActions, appointmentForm, callback } = this.props;

        appointmentActions.reopenAppointment(appointmentForm.data.id, this.getCustomerNumberForCallback(), callback);
    }

    onFieldChange(field, value) {
        const { commonActions, appointmentForm } = this.props;

        commonActions.changeMultiFormField(appointmentForm.id, field, value);
    }

    customerSearchOnChange(customerNumber, customerName) {
        this.onFieldChange('customerNumber', customerNumber);
        this.onFieldChange('customerName', customerName);
    }

    getCustomerNumberForCallback() {
        return this.props.isMyAppointment ? null : this.props.appointmentForm.data.customerNumber;
    }

    isReadonly(appointment, isCreation) {
        if (isCreation) {
            return false;
        }

        if (appointment.assignee === this.props.user.email && appointment.status === 0) {
            return false;
        }

        return true;
    }


    render() {

        const { isCreation, appointmentForm, selectedAppointment, user } = this.props;

        if (!appointmentForm || (!isCreation && !selectedAppointment)) {
            return null;
        }

        const appointment = appointmentForm.data || {};

        const isCompleted = (selectedAppointment && selectedAppointment.isCompleted) || false;
        const readonly = selectedAppointment && this.isReadonly(selectedAppointment, isCreation);
        const assignee = isCreation
            ? { name: user.name || '', email: user.email }
            : { name: appointment.assigneeName || appointment.assignee, email: appointment.assignee }

        const attributes = {
            readonly: readonly,
            appointment: appointment,
            isCompleted: isCompleted,
            onFieldChange: (field, value) => this.onFieldChange(field, value),
            onSaveClick: () => this.onSaveClick(),
            onCancelClick: () => this.onCancelClick(),
            onReopenClick: () => this.onReopenClick(),
            customerSearchOnChange: (customerNumber, customerName) => this.customerSearchOnChange(customerNumber, customerName),
            showReopenButton: readonly && isCompleted,
            assignee: assignee,
            isCreation: isCreation,
            onSaveAndCreateClick: () => this.onSaveAndCreateClick(),
            width: this.props.appointmentFormWidth,
            hideButtons: this.props.hideButtons
        }

        // const modalAttributes = {
        //     appointmentId: appointmentForm.data.id,
        //     customerNumberForCallback: this.getCustomerNumberForCallback(),
        //     callback: callback
        // }

        return (
            <div>
                <AppointmentForm {...attributes} />
                {/* {showCloseModal && <CloseModal {...modalAttributes} />} */}
                {/*<div className="col-xs-4">
                    {!isMyAppointment && <CustomerInfo customer={customer} />}
                    </div>    */}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.selectedCustomer,
        user: state.CommonReducer.user,
        appointmentForm: state.CommonReducer.forms && state.CommonReducer.forms.appointmentForm,
        selectedAppointment: state.AppointmentReducer.appointment
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        appointmentActions: bindActionCreators(appointmentActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentFormContainer);

