import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import settingsActions from '../../Actions/settingsActions'
import commonActions from '../../Actions/commonActions'
import routes from '../../Constants/routes'
import StaticTextInput from '../../Components/StaticTextInput';
import Loader from '../../Components/Loader'
import { withTranslation } from 'react-i18next';
import orderActions from '../../Actions/orderActions';
import OrderGrid from '../../Components/Order/OrderGrid';
import TextInput from '../../Components/TextInput';
import { useParams, useNavigate } from 'react-router-dom';

function OrderApprovalRequestsEditContainer({ orderActions, order, form, commonActions, t }) {

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        orderActions.getOrderById(id);
    }, [])

    const onApproveClick = () => {
        orderActions.approveOrderRequest(order.orderId, order.customerNumber, form, () => navigate(routes.orderApprovalRequests));
    }

    const onDeleteClick = () => {
        orderActions.cancelOrderRequest(order.orderId, () => navigate(routes.orderApprovalRequests));
    }


    const onDenyClick = () => {
        orderActions.denyOrderRequest(order.orderId, order.customerNumber, form, () => navigate(routes.orderApprovalRequests));
    }

    const onCancelClick = () => {
        commonActions.cancelFormEditing(form.id, () => navigate(routes.orderApprovalRequests))
    }

    const onFieldChange = (field, value) => {
        commonActions.changeMultiFormField(form.id, field, value)
    }
    
    if (!order || !form) {
        return null;
    }

    return (
        <div>
            <div className="orderapproval-text-container">
                <form className="form-horizontal col-xs-12 orderapproval-form">
                    <fieldset className="col-xs-6">
                        <StaticTextInput label={t('orderapprovalrequestseditcontainer.customernumber')} type={"link"} newTab={true} to={routes.customerDetails(form.data.customerNumber)} value={form.data.customerNumber} />
                        <StaticTextInput label={t('orderapprovalrequestseditcontainer.salesperson')} value={form.data.salesPersonEmail} />
                    </fieldset>
                </form>
            </div>
            <div className="orderDetails">
                <div className="panel panel-default">
                    <div className="panel-body ">
                        <div className="form-horizontal order-body">
                            <OrderGrid order={order} isApprovalRequest={true} />
                        </div>
                    </div>
                </div>
            </div>
            <TextInput label={"noLabel"} placeholder={"Your comment"} onChange={onFieldChange.bind(this, 'comment')} value={form.data.comment || ""} multiline={true} />
            <div className="form-group padding-top no-left-margin btn-toolbar">
                <button type="button" className="btn btn-primary" onClick={onApproveClick}>{t("orderapprovalrequestseditcontainer.approve")}</button>
                <button type="button" className="btn btn-danger" onClick={onDenyClick}>{t("orderapprovalrequestseditcontainer.deny")}</button>
                <button type="button" className="btn btn-danger" onClick={onDeleteClick}>{t("orderapprovalrequestseditcontainer.delete")}</button>
                <button type="button" className="btn btn-default" onClick={onCancelClick}>{t("orderapprovalrequestseditcontainer.cancel")}</button>
                <Loader />
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        order: state.OrderReducer.order,
        form: state.CommonReducer.forms && state.CommonReducer.forms.orderForm,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        settingsActions: bindActionCreators(settingsActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        orderActions: bindActionCreators(orderActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderApprovalRequestsEditContainer));