import { bindActionCreators } from "redux";
import { Dropdown } from "../../FormComponents/Dropdown";
import DateTimeInput from "../../FormComponents/Input/DateTimeInput";
import { FormInput } from "../../FormComponents/Input/Input";
import { connect } from "react-redux";
import { useEffect } from "react";
import commonActions from "../../../Actions/commonActions";
import activitylogActions from "../../../Actions/InternalCustomerAdmin/activityLogActions";
import helpers from "../../../Utils/helpers";
import { useTranslation } from "react-i18next";

function ActivityLogForm({ services, activityLogSearchForm, commonActions, activitylogActions, customer }) {
    useEffect(() => {
        commonActions.startFormEditing('activityLogSearchForm', { searchBy: "Customer", value: customer ? customer.customerNumber : "", fromDate: helpers.getCurrentDate(), toDate: helpers.getCurrentDate(), applicationId: "" });

        return () => {
            commonActions.cancelFormEditing('activityLogSearchForm');
        }
    }, []);

    const data = activityLogSearchForm?.data;
    const { t } = useTranslation();

    const logType = [
        { id: "Customer", name: "Ändringar utförda på kund" },
        { id: "UserPerformer", name: "Ändringar utförda av användare" },
        { id: "UserAffected", name: "Ändringar utförda på användare" },
        { id: "FailedMails", name: "Ej levererade mejl" },
    ];

    const onFieldChange = (value, field) => {
        commonActions.changeMultiFormField(activityLogSearchForm.id, field, value);
    }

    const onFormSubmit = () => {
        if (data.searchBy === "FailedMails") {
            return activitylogActions.searchFailedMails(data);
        }
        activitylogActions.searchLogs(data);
    }

    if (!data) return

    return <div className="col-3">
        <fieldset className="d-grid gap-16">
            <Dropdown label={t("activitylogform.searchbasedon")} options={logType} initialValue={data.searchBy} value={data.searchBy} onChange={(value) => onFieldChange(value, "searchBy")} />
            <FormInput label={data.searchBy == "Customer" ? t("activitylogform.customernumber") : t("activitylogform.email")} value={data.value} onChange={(value) => onFieldChange(value, "value")} />
            <Dropdown label={t("activitylogform.fromservice")} options={services} initialValue={data.applicationId} value={data.applicationId} valueProperty={'guid'} onChange={(value) => onFieldChange(value, 'applicationId')} />
            <div>
                <label>{t("activitylogform.dateinterval")}</label>
                <div className="d-flex">
                    <div className="flex-grow-1">
                        <DateTimeInput dateFormat={"YYYY-MM"} showTime={false} onChange={(value) => onFieldChange(value, 'fromDate')} initialValue={data.fromDate} />
                    </div>
                    <div className="mx-12">
                        <p>_</p>
                    </div>
                    <div className="flex-grow-1">
                        <DateTimeInput dateFormat={"YYYY-MM"} showTime={false} onChange={(value) => onFieldChange(value, 'toDate')} initialValue={data.toDate} />
                    </div>
                </div>
            </div>
            <div>
                <button className="btn btn-primary" onClick={() => onFormSubmit(data.searchBy)}>{t("common.search")}</button>
            </div>
        </fieldset>
    </div>
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        activitylogActions: bindActionCreators(activitylogActions, dispatch),
    };
}

function mapStateToProps(state) {
    return {
        activityLogSearchForm: state.CommonReducer.forms && state.CommonReducer.forms.activityLogSearchForm,
        services: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists.VonServices,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLogForm);