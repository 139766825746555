import { useTranslation } from "react-i18next";
import { FormInput} from '../../FormComponents/Input/Input';
export function MainInfoEditContent({ data, commonActions, form, actionType }) {
    const {t} = useTranslation();

    const onChange = (value, field) => {
        commonActions.changeMultiFormField(form.id, value, field)
    }

    return <>
        <fieldset className="d-grid col-lg-8 gap-16">
            <legend>{t("maininfocontainer.maininfocontactperson")}</legend>
            <FormInput label={t("maininfocontainer.firstname")} value={data.contactPersonFirstName}
                       onChange={(input) => onChange('contactPersonFirstName', input)}/>
            <FormInput label={t("maininfocontainer.lastname")} value={data.contactPersonLastName}
                       onChange={(input) => onChange('contactPersonLastName', input)}/>
            <FormInput label={t("maininfocontainer.emailaddress")} value={data.contactPersonEmail} type='email'
                       onChange={(input) => onChange('contactPersonEmail', input)}/>
            <FormInput label={t("maininfocontainer.phonenumber")} value={data.contactPersonPhoneNumber} type='tel'
                       onChange={(input) => onChange('contactPersonPhoneNumber', input)}/>
            </fieldset>
            <fieldset className="d-grid col-lg-8 gap-16">
                <legend>{t("maininfocontainer.maininfocustomer")}</legend>
            <FormInput label={t("maininfocontainer.invoiceemail")} value={data.invoiceEmail} type='email'
                       onChange={(input) => onChange('invoiceEmail', input)}/>
            <FormInput label={t("maininfocontainer.streetaddress")} value={data.address1}
                       onChange={(input) => onChange('address1', input)}/>
            <FormInput label={t("maininfocontainer.postalcode")} value={data.postalCode} type='number'
                       onChange={(input) => onChange('postalCode', input)}/>
            <FormInput label={t("maininfocontainer.postalcity")} value={data.city}
                       onChange={(input) => onChange('city', input)}/>
            <FormInput label={t("maininfocontainer.coaddress")} value={data.address2}
                       onChange={(input) => onChange('address2', input)}/>
        </fieldset>
    </>
}