import { orderActionTypes as actionTypes } from '../ActionTypes/orderActionTypes'

import commonActions from './commonActions'
import customerActions from './customerActions'
import * as formValidationRules from '../Constants/formValidationRules'

import helpers from '../Utils/helpers'
import { httpFetch } from '../Utils/httpUtils'
import leadActions from './leadActions';
import appointmentActions from './appointmentActions';

import system from '../Constants/system'
import * as calcTypes from '../Constants/priceCalculationTypes'
import quotationActions from './quotationActions';

const orderActions = {

    startOrder(customerNo, leadId, pushedQuotation) {
        return dispatch => {

            dispatch(customerActions.getCustomer(customerNo));

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify({ customerNumber: customerNo, leadId })
            }

            if (pushedQuotation) {
                dispatch(orderActions.getOrder(customerNo, pushedQuotation.orderId, pushedQuotation))
                dispatch(leadActions.getMyLeadsForCustomer(customerNo));
                dispatch(appointmentActions.getMyCustomerAppointments(customerNo));
            }
            else {
                httpFetch('/api/order/StartOrder', dispatch, httpRequest)
                    .then(json => {
                        dispatch({ type: actionTypes.START_ORDER, orderId: json.orderId, customerNumber: customerNo, isVonEnabled: json.isVonEnabled });
                        dispatch(orderActions.getOrder(customerNo, json.orderId, pushedQuotation))
                        dispatch(leadActions.getMyLeadsForCustomer(customerNo));
                        dispatch(appointmentActions.getMyCustomerAppointments(customerNo));
                    })
            }
        }
    },

    clearOrder() {
        return {
            type: actionTypes.CLEAR_ORDER
        }
    },

    getOrder(customerNumber, orderId, pushedQuotation) {
        return (dispatch, getState) => {
            if (pushedQuotation) {
                dispatch(orderActions.showOrderDetails(null, pushedQuotation))
                dispatch(commonActions.startFormEditing("orderForm", pushedQuotation, formValidationRules.OrderForm));
            }
            else {
                return httpFetch(`/api/order/GetOrder/${customerNumber}/${orderId}`, dispatch)
                    .then(json => {
                        dispatch(orderActions.showOrderDetails(json, pushedQuotation))
                        dispatch(commonActions.startFormEditing("orderForm", json, formValidationRules.OrderForm));
                    })
            }
        }
    },

    getOrderById(orderId) {
        return (dispatch, getState) => {
            return httpFetch(`/api/order/GetOrderById/${orderId}`, dispatch)
                .then(json => {
                    dispatch(orderActions.showOrderDetails(json, null))
                    dispatch(commonActions.startFormEditing("orderForm", json, formValidationRules.OrderForm));
                })
        }
    },

    getQuotationOrder(currentOrder, customerNumber, conflictingOrderId) {
        return (dispatch) => {

            var request = {
                method: 'POST',
                body: JSON.stringify({ currentMergeOrderId: currentOrder.orderId, customerNumber: customerNumber, quotationOrderId: conflictingOrderId, quotationId: null, organizationNumber: null })
            }

            return httpFetch(`/api/quotation/MergeQuotationWithCurrentOrder/`, dispatch, request)
                .then(json => {
                    //dispatch(orderActions.cancel(currentOrder.orderId, currentOrder.customerNumber, null, true))
                    dispatch(orderActions.showOrderDetails(json, null))
                    dispatch(commonActions.startFormEditing("orderForm", json, formValidationRules.OrderForm));
                })
        }
    },

    search(customerNumber, input, isVonEnabled) {

        return dispatch => {
            return httpFetch(`/api/order/SearchProducts/${customerNumber}/${input}?searchVon=${isVonEnabled}`, dispatch)
                .then(json => {
                    dispatch(orderActions.showSearchResults(json));
                })
        }
    },

    trialSearch(input) {

        return dispatch => {
            return httpFetch(`/api/order/SearchTrial/${input}`, dispatch)
                .then(json => {
                    dispatch(orderActions.showTrialSearchResults(json));
                })
        }
    },

    getProductDetails(productId, customerNumber, additionalUsers, api, competitorDiscount, orderId, loyaltyDiscountCustomer) {

        return (dispatch, getState) => {

            if (additionalUsers === undefined) {
                const product = getState().OrderReducer.product;
                additionalUsers = (product && product.additionalUsersCount) || 0
                api = (product && product.api === "yes") || false
                competitorDiscount = (product && product.competitorDiscount === "yes") || false
                loyaltyDiscountCustomer = product.loyaltyDiscountCustomer
            }

            const request = {
                method: 'POST',
                body: JSON.stringify({
                    priceCalculationType: calcTypes.priceCalculationTypes.order,
                    productId,
                    customerNumber,
                    additionalUsersCount: additionalUsers || 0,
                    api: api || false,
                    competitorDiscount: competitorDiscount || false,
                    loyaltyDiscountCustomer: loyaltyDiscountCustomer,
                    orderId
                })
            }

            return httpFetch("/api/order/GetProductDetails", dispatch, request)
                .then(json => {
                    dispatch(orderActions.showProductDetails(json, system.Vb));
                })
                .catch(() => {
                    dispatch(orderActions.hideProductDetails());
                });
        }
    },

    getVonProductDetails(productId, customerNumber, period, orderId, additionalUsers, vonAddonsIds, getProductFromState, invoiceStartDate) {

        return (dispatch, getState) => {

            if (getProductFromState) {
                const product = getState().OrderReducer.product;
                additionalUsers = (product && product.additionalUsersCount) || 0
            }

            const customerGroup = getState().CustomerReducer.selectedCustomer == null ? 0 : getState().CustomerReducer.selectedCustomer.group;

            const request = {
                method: 'POST',
                body: JSON.stringify({
                    priceCalculationType: calcTypes.priceCalculationTypes.order,
                    productId,
                    customerNumber,
                    customerGroup,
                    period,
                    additionalUsersCount: additionalUsers,
                    vonAddonsIds: vonAddonsIds && Object.values(vonAddonsIds),
                    orderId,
                    invoiceStartDate
                })
            }

            return httpFetch("/api/order/GetVonProductDetails", dispatch, request)
                .then(json => {
                    dispatch(orderActions.showProductDetails(json, system.Von));
                })
                .catch(() => {
                    dispatch(orderActions.hideProductDetails());
                });
        }
    },

    getVossProductDetails(productOfferId, customerNumber, orderId, subscription) {

        return (dispatch, getState) => {

            const customerGroup = getState().CustomerReducer.selectedCustomer == null ? 0 : getState().CustomerReducer.selectedCustomer.group;

            const request = {
                method: 'POST',
                body: JSON.stringify({
                    priceCalculationType: calcTypes.priceCalculationTypes.order,
                    productId : productOfferId,
                    customerNumber,
                    VossOrderlineData: {
                        productOfferId: productOfferId,
                        planId: subscription?.planId,
                        tierId: subscription?.tierId,
                        optionalProductGroupIds: subscription?.optionalProductGroupIds,
                        intermediaryId: subscription?.intermediaryId,
                        discountAgreementIds: subscription?.discountAgreementIds,
                    },
                    customerGroup,
                    orderId,
                })
            }

            return httpFetch("/api/order/GetVossProductDetails", dispatch, request)
                .then(json => {
                    dispatch(orderActions.showProductDetails(json, system.Voss));
                })
                .catch(() => {
                    dispatch(orderActions.hideProductDetails());
                });
        }
    },

    getVossPriceCalculations(offer, priceCalculation, prevPriceCalculation) {
        return (dispatch, getState) => {

            const request = {
                method: 'POST',
                body: JSON.stringify({
                    productId : offer.productOfferId,
                    VossOrderlineData: {
                        customerId: offer.customerId,
                        productOfferId: offer.productOfferId,
                        planId: priceCalculation?.planId,
                        subscriptionId: offer?.subscriptionId,
                        tierId: priceCalculation?.tierId,
                        optionalProductGroupIds: priceCalculation?.optionalProductGroups.map(pg => pg.id),
                        licenses: priceCalculation?.licenses,
                        intermediaryId: priceCalculation?.intermediaryId,
                        discountAgreementIds: priceCalculation?.discountAgreementIds,
                    }
                })
            }

            return httpFetch("/api/order/GetVossPriceCalculations", dispatch, request)
                .then(json => {
                    const product = {... getState().OrderReducer.product, priceCalculation : json}
                    dispatch(orderActions.showProductDetails(product, system.Voss));
                })
                .catch(() => {
                    const product = {... getState().OrderReducer.product, priceCalculation : prevPriceCalculation}
                    dispatch(orderActions.showProductDetails(product, system.Voss));
                });
        }
    },

    getVossIntermediaries() {
        return (dispatch) => {
            return httpFetch("/api/order/GetVossIntermediaries", dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_VOSS_INTERMEDIARIES, data: json });
                })
        }
    },

    getCompanyExpenseProductDetails(productId, customerNumber, additionalUsers, orderId) {

        return (dispatch, getState) => {

            if (additionalUsers === undefined) {
                const product = getState().OrderReducer.product;
                additionalUsers = (product && product.additionalUsersCount) || 0
            }

            const request = {
                method: 'POST',
                body: JSON.stringify({
                    priceCalculationType: calcTypes.priceCalculationTypes.order,
                    productId,
                    customerNumber,
                    additionalUsersCount: additionalUsers,
                    orderId
                })
            }


            return httpFetch(`/api/order/GetCompanyExpenseProductDetails`, dispatch, request)
                .then(json => {
                    json.langCode = json.langCode || "sv";
                    dispatch(orderActions.showProductDetails(json, system.CompanyExpense));

                    if (json.adminUser !== undefined && json.adminUser !== null) {
                        dispatch(commonActions.changeMultiFormField('orderForm', 'adminFirstName', json.adminUser.firstName));
                        dispatch(commonActions.changeMultiFormField('orderForm', 'adminLastName', json.adminUser.lastName));
                        dispatch(commonActions.changeMultiFormField('orderForm', 'adminEmail', json.adminUser.email));
                    }

                    const partnerId = json.partnerId || "";
                    if (partnerId)
                        dispatch(commonActions.changeMultiFormField('orderForm', 'partnerId', partnerId));

                    dispatch(orderActions.getCompanyExpenseTemplateOrganizations(partnerId));

                    if (json.retailerNumber !== undefined && json.retailerNumber !== null)
                        dispatch(commonActions.changeMultiFormField('orderForm', 'supplierNumber', json.retailerNumber));
                })
                .catch(() => {
                    dispatch(orderActions.hideProductDetails());
                    dispatch(commonActions.showError("Something went wrong!", ""));
                });
        }
    },

    getCompanyExpenseTemplateOrganizations(partnerId) {
        var url = partnerId === "" ? `/api/order/GetCompanyExpenseMainTemplates/` : `/api/order/GetCompanyExpenseTemplates/${partnerId}`;
        return (dispatch) => {
            return httpFetch(url, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.SHOW_TEMPLATE_ORGANIZATIONS, values: json });
                    if (json.length > 0)
                        dispatch(orderActions.changeAssignedTemplateOrganization(json[0].id));
                })
        }
    },


    getBlikkProductDetails(productId, customerNumber, orderId, tenantGuid, reload) {

        return (dispatch, getState) => {

            let licenseTypes = null;
            if (!reload)
                licenseTypes = getState().OrderReducer.blikkLicenseTypes;

            let transactionTypes = null;
            if (!reload)
                transactionTypes = getState().OrderReducer.blikkTransactionTypes;

            let receiptInterpretation = false;
            if (!reload)
                receiptInterpretation = getState().OrderReducer.receiptInterpretation;

            const body = {
                productId,
                customerNumber,
                tenantGuid,
                licenseTypes,
                orderId,
                transactionTypes,
                receiptInterpretation,
                reload
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(body)
            }

            return httpFetch(`/api/order/getBlikkProductDetails`, dispatch, httpRequest)
                .then(json => {
                    dispatch(orderActions.changeBlikkUserLicenses(json.licenseTypes));
                    dispatch(orderActions.changeBlikkTransactions(json.transactionTypes));
                    dispatch(orderActions.toggleReceiptInterpretation(json.receiptInterpretation))
                    dispatch(orderActions.showProductDetails(json, system.Blikk));
                })
                .catch(() => {
                    dispatch(orderActions.hideProductDetails());
                });
        }
    },


    showSearchResults(json) {
        return {
            type: actionTypes.SHOW_SEARCH_RESULTS,
            results: json
        }
    },

    showTrialSearchResults(json) {
        return {
            type: actionTypes.SHOW_TRIAL_SEARCH_RESULTS,
            results: json
        }
    },

    showProductDetails(product, sourceSystem) {

        return {
            type: actionTypes.SHOW_PRODUCT_DETAILS,
            product,
            sourceSystem
        }
    },

    hideProductDetails() {
        return { type: actionTypes.HIDE_PRODUCT_DETAILS };
    },

    showOrderDetails(order, pushedQuotation) {

        order = pushedQuotation ? pushedQuotation : order

        return {
            type: actionTypes.SHOW_ORDER_DETAILS,
            order
        }
    },

    editOrderline(orderline, customerNumber, orderId) {

        return dispatch => {
            dispatch({ type: actionTypes.EDIT_ORDERLINE, orderline })

            if (orderline.sourceSystem === system.Von)
                dispatch(orderActions.getVonProductDetails(orderline.productId, customerNumber, orderline.period, orderId, orderline.additionalUsersCount, orderline.addons && orderline.addons.map(addon => addon.productId)))
            else if (orderline.sourceSystem === system.Vb)
                dispatch(orderActions.getProductDetails(orderline.productId, customerNumber, orderline.additionalUsersCount, orderline.api, orderline.competitorDiscount, orderId))
            else if (orderline.sourceSystem === system.CompanyExpense)
                dispatch(orderActions.getCompanyExpenseProductDetails(orderline.productId, customerNumber, orderline.additionalUsersCount, orderId))
            else if (orderline.sourceSystem === system.Blikk)
                dispatch(orderActions.getBlikkProductDetails(orderline.productId, customerNumber, orderId))
            else if(orderline.sourceSystem === system.Voss)
                dispatch(orderActions.getVossProductDetails(orderline.productId, customerNumber, orderId, orderline.vossOrderlineData))
        }
    },

    hideOrderDetails() {
        return orderActions.showOrderDetails(null)
    },

    addOrderLine(orderId, quotationFromOrderContainer) {
        return (dispatch, getState) => {
            const orderState = getState().OrderReducer;
            const orderline = orderState.editOrderline;
            const orderlineId = (orderline && orderline.orderlineId) || 0;
            const product = orderState.product;
            const customerNumber = getState().CustomerReducer.selectedCustomer == null ? 0 : getState().CustomerReducer.selectedCustomer.customerNumber;
            const sourceSystem = orderState.sourceSystem;
            const vonAddons = orderState.addons;
            const data = getState().CommonReducer.forms.orderForm.data;
            const adminEmail = data.adminEmail;
            const adminFirstName = data.adminFirstName;
            const adminLastName = data.adminLastName;
            const retailerNumber = data.supplierNumber;
            const partnerId = data.partnerId;
            const templateOrganizationId = product.templateOrganizationId ? product.templateOrganizationId : orderState.templateOrganizations && orderState.templateOrganizations.length > 0 ? orderState.templateOrganizations[0].id : "";
            const langCode = product.langCode || "sv";
            const httpRequest = {
                method: 'POST',
                body: JSON.stringify({
                    priceCalculationType: calcTypes.priceCalculationTypes.order,
                    productId: product.productId,
                    orderId,
                    orderlineId,
                    additionalUsersCount: product.additionalUsersCount,
                    api: product.api === "yes",
                    competitorDiscount: product.competitorDiscount === "yes",
                    customerNumber,
                    sourceSystem: sourceSystem,
                    productsToInclude: product.productsToInclude,
                    Period: product.period,
                    VonAddonsIds: vonAddons && Object.values(vonAddons),
                    leadId: product.leadId,
                    appointmentId: product.appointmentId,
                    adminEmail,
                    adminFirstName,
                    adminLastName,
                    partnerId,
                    VossOrderlineData: {
                        customerId: product.offer?.customerId,
                        productOfferId: product.offer?.productOfferId,
                        planId: product.priceCalculation?.planId,
                        subscriptionId: product.offer?.subscriptionId,
                        tierId: product.priceCalculation?.tierId,
                        optionalProductGroupIds: product.priceCalculation?.optionalProductGroups.map(pg => pg.id),
                        intermediaryId: product.priceCalculation?.intermediaryId,
                        discountAgreementIds: product.priceCalculation?.discountAgreementIds,
                    },
                    loyaltyDiscountCustomer: product.loyaltyDiscountCustomer,
                    invoiceStartDate: product.invoiceStartDate,
                    templateOrganizationId: templateOrganizationId,
                    langCode: langCode,
                    retailerNumber,
                    isNew: product.isNew,
                    ActiveUsers: product.activeUsers,
                    createdDate: product.createdDate,
                    blikkLicenseTypes: product.licenseTypes,
                    blikkTransactionTypes: product.transactionTypes,
                    blikkTenantGuid: product.tenantGuid,
                    receiptInterpretation: product.receiptInterpretation
                })
            }

            if (quotationFromOrderContainer && !orderState.order.quotationInProgress) {
                const customer = getState().CustomerReducer.selectedCustomer;

                const httpRequestForQuotation = {
                    method: 'POST',
                    body: JSON.stringify({
                        organizationNumber: customer.organizationNumber,
                        orderId,
                        email: customer.emailAddress
                    })
                }

                httpFetch('/api/quotation/startQuotationCustomerExist', dispatch, httpRequestForQuotation)
                    .then(json => {
                        //dispatch(orderActions.setQuotationCurrentlyInProgress(true))
                        httpFetch('/api/order/AddOrderLine', dispatch, httpRequest)
                            .then(json => {

                                dispatch(orderActions.hideProductDetails());
                                dispatch(orderActions.getOrder(customerNumber, orderId))
                            })
                    })
            }
            else {
                httpFetch('/api/order/AddOrderLine', dispatch, httpRequest)
                    .then(json => {

                        dispatch(orderActions.hideProductDetails());
                        dispatch(orderActions.getOrder(customerNumber, orderId))
                    })
            }
        }
    },

    removeOrderLines(orderId, orderlineIds, customerNumber) {
        return (dispatch, getState) => {
            const editorderline = getState().OrderReducer.editOrderline;

            if (editorderline && orderlineIds.includes(editorderline.orderlineId)) {
                dispatch(orderActions.hideProductDetails());
            }

            const httpRequest = { method: 'DELETE', body: JSON.stringify(orderlineIds) }

            httpFetch(`/api/order/RemoveOrderLines/${customerNumber}/${orderId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(orderActions.getOrder(customerNumber, orderId));

                    const order = getState().OrderReducer;

                    if (order.product) {
                        const product = order.product;

                        if (order.sourceSystem === system.Von) {
                            dispatch(orderActions.getVonProductDetails(product.productId, customerNumber, product.Period, orderId, product.additionalUsersCount, product.addons
                                .filter(addon => addon.isChecked)
                                .map(addon => addon.productId)))
                        }
                        else if (order.sourceSystem === system.Vb) {
                            dispatch(orderActions.getProductDetails(product.productId, customerNumber, product.additionalUsers, product.api, orderId))
                        }
                        else if (order.sourceSystem === system.CompanyExpense) {
                            dispatch(orderActions.getCompanyExpenseProductDetails(product.productId, customerNumber, product.additionalUsersCount, orderId))
                        }
                    }

                })
        }
    },

    startQuotationModal(order, quotationCustomerForm, fromOrderContainer) {
        let customerDetails = null;
        return (dispatch, getState) => {
            return dispatch(orderActions.getQuotationEmailTemplate(order.customerNumber, order.orderId))
                .then(json => {
                    if (fromOrderContainer)
                        customerDetails = getState().CustomerReducer.selectedCustomer

                    const form = { ...{ ...json }, order, orgNr: quotationCustomerForm ? quotationCustomerForm.data.orgNr : customerDetails.organizationNumber, to: quotationCustomerForm ? quotationCustomerForm.data.emailAddress : customerDetails.emailAddress, formName: 'quotationMailForm' }

                    dispatch(commonActions.startFormEditing('quotationMailForm', form, null));
                })
        };
    },

    getQuotationEmailTemplate(customerNumber, orderId) {
        return (dispatch) => {
            return httpFetch(`/api/quotation/getQuotationEmailTemplate/${customerNumber}/${orderId}`, dispatch)
        }
    },

    sendQuotation(form, closeModal, fromOrderContainer, navigateCallback) {
        return (dispatch, getState) => {
            dispatch(commonActions.hideAlert());

            // const validationResult = helpers.validateForm(form);

            // if (validationResult) {
            //     dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
            //     return null;

            let customerNumber = null;
            if (fromOrderContainer)
                customerNumber = getState().CustomerReducer.selectedCustomer.customerNumber;

            const data = form.data;

            var payload = {
                customerNumber: customerNumber,
                orderId: data.order.orderId,
                quotationEmailModel: { body: data.body, to: data.to, from: data.from, subject: data.subject, templateType: data.templateType }
            };

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(payload)

            }

            dispatch(commonActions.progressLoader(true))
            return httpFetch('/api/quotation/sendquotation', dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess("Quotation has been sent!"));
                    this.clearQuotation(true);
                    closeModal();
                    if (fromOrderContainer)
                        navigateCallback();
                    else
                        dispatch(quotationActions.getQuotations(null));
                })
        }
    },

    clearQuotation(setupFormsAgain) {
        return (dispatch) => {
            if (setupFormsAgain) {
                dispatch(commonActions.cancelFormEditing("quotationCustomerForm"));
                dispatch(commonActions.cancelFormEditing("quotationMailForm"));
                dispatch(orderActions.clearOrder());

                const customerFields = {
                    orgNr: '',
                    emailAddress: ''
                }

                dispatch(commonActions.startFormEditing('quotationCustomerForm', customerFields, formValidationRules.quotationCustomer));
            }
            else {
                dispatch(commonActions.cancelFormEditing("quotationCustomerForm"));
                dispatch(commonActions.cancelFormEditing("quotationMailForm"));
                dispatch(orderActions.clearOrder());
            }
        }
    },

    pushQuotationToCustomerOrder(form, quotation, callback) {
        return (dispatch) => {

            const validationResult = helpers.validateForm(form);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const test = quotation;
            const data = form.data;

            var payload = {
                quotationId: data.id,
                quotationOrderId: data.order.orderId,
                customerNumber: data.customerNumber,
                organizationNumber: data.organizationNumber
            };

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(payload)

            }

            dispatch(commonActions.progressLoader(true))
            return httpFetch('/api/quotation/validatequotation', dispatch, httpRequest)
                .then(json => {
                    dispatch(orderActions.setPushedQuotation(form, json));
                    callback();
                })
        }
    },

    setPushedQuotation(form, json) {
        return {
            type: actionTypes.SET_PUSHED_QUOTATION,
            pushedQuotation: json,
            quotationId: form.data.id
        }
    },

    clearPushedQuotation() {
        return {
            type: actionTypes.CLEAR_PUSHED_QUOTATION,
            value: null
        }
    },

    setQuotationCurrentlyInProgress(value) {
        return {
            type: actionTypes.SET_QUOTATION_CURRENTLY_IN_PROGRESS,
            value
        }
    },

    cancel(orderId, customerNumber, callback) {
        return (dispatch, getState) => {
            dispatch(commonActions.cancelFormEditing("orderForm"));
            dispatch(commonActions.progressLoader(true));
            //TODO: Risky to call getState, state could have been modified before cancel is called with orderId and customerNumber
            //Another order might be picked up from state than the orderId suggests on call
            var order = getState().OrderReducer.order

            const httpRequest = {
                method: 'DELETE'
            }
            if (order.status !== 4) {
                httpFetch(`/api/order/cancel/${customerNumber}/${orderId}`, dispatch, httpRequest)
                    .then(json => {
                        dispatch({ type: actionTypes.CANCEL_ORDER });
                        callback();
                        dispatch(commonActions.progressLoader(false));
                    })
            }
            else {
                dispatch({ type: actionTypes.CANCEL_ORDER });
                callback();
                dispatch(commonActions.progressLoader(false));
            }
        }
    },

    cancelOrderRequest(orderId, callback) {
        return (dispatch, getState) => {
            dispatch(commonActions.cancelFormEditing("orderForm"));
            dispatch(commonActions.progressLoader(true));
            //TODO: Risky to call getState, state could have been modified before cancel is called with orderId and customerNumber
            //Another order might be picked up from state than the orderId suggests on call
            var order = getState().OrderReducer.order

            const httpRequest = {
                method: 'DELETE'
            }

            httpFetch(`/api/order/cancelOrderRequest/${orderId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch({ type: actionTypes.CANCEL_ORDER });
                    callback();
                    dispatch(commonActions.progressLoader(false));
                })
        }
    },


    complete(orderId, customerNumber, orderForm, callback) {
        return dispatch => {

            const validationResult = helpers.validateForm(orderForm, true);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult.errorMessage, 'Please validate!'));
                dispatch(commonActions.setFieldErrorFlags(orderForm.id, validationResult.fieldErrorFlags))
                return null;
            }

            dispatch(commonActions.progressLoader(true));

            const httpRequest = {
                method: 'PUT',
                body: JSON.stringify({
                    yourReference: orderForm.data.yourReference,
                    emailAddress: orderForm.data.emailAddress
                })
            }

            httpFetch(`/api/order/complete/${customerNumber}/${orderId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess("Order has been processed!" + " " + json.message));
                    dispatch(commonActions.cancelFormEditing("orderForm"));
                    dispatch(appointmentActions.getOpenAppointmentsCount());
                    dispatch(leadActions.getOpenLeadsCount());
                    callback(json.orderId);
                    dispatch({ type: actionTypes.CANCEL_ORDER });
                    dispatch(commonActions.progressLoader(false));

                })
        }
    },

    sendApprovalRequest(orderId, customerNumber, orderForm, callback) {
        return dispatch => {

            const validationResult = helpers.validateForm(orderForm, true);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult.errorMessage, 'Please validate!'));
                dispatch(commonActions.setFieldErrorFlags(orderForm.id, validationResult.fieldErrorFlags))
                return null;
            }

            dispatch(commonActions.progressLoader(true));

            const httpRequest = {
                method: 'PUT',
                body: JSON.stringify({
                    yourReference: orderForm.data.yourReference,
                    emailAddress: orderForm.data.emailAddress,
                    status: orderForm.data.status
                })
            }

            httpFetch(`/api/order/sendApprovalRequest/${customerNumber}/${orderId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess(json.message));
                    dispatch(commonActions.cancelFormEditing("orderForm"));
                    dispatch(appointmentActions.getOpenAppointmentsCount());
                    dispatch(leadActions.getOpenLeadsCount());
                    callback()
                    dispatch({ type: actionTypes.CANCEL_ORDER });
                    dispatch(commonActions.progressLoader(false));
                })
        }
    },

    getOrderApprovalRequests() {
        return (dispatch) => {
            return httpFetch(`/api/order/GetApprovalRequests/`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_APPROVAL_REQUESTS, data: json });
                })
        }
    },

    approveOrderRequest(orderId, customerNumber, orderForm, callback) {
        return dispatch => {


            dispatch(commonActions.progressLoader(true));

            const httpRequest = {
                method: 'PUT',
                body: JSON.stringify({
                    yourReference: orderForm.data.yourReference,
                    emailAddress: orderForm.data.emailAddress,
                    comment: orderForm.data.comment,
                    salesPersonEmail: orderForm.data.salesPersonEmail
                })
            }

            httpFetch(`/api/order/approveOrderRequest/${customerNumber}/${orderId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess("Order has been approved"));
                    dispatch(commonActions.cancelFormEditing("orderForm"));
                    dispatch(appointmentActions.getOpenAppointmentsCount());
                    dispatch(leadActions.getOpenLeadsCount());
                    callback();
                    dispatch({ type: actionTypes.CANCEL_ORDER });
                    dispatch(commonActions.progressLoader(false));

                })
        }
    },

    denyOrderRequest(orderId, customerNumber, orderForm, callback) {
        return dispatch => {


            dispatch(commonActions.progressLoader(true));

            const httpRequest = {
                method: 'PUT',
                body: JSON.stringify({
                    yourReference: orderForm.data.yourReference,
                    emailAddress: orderForm.data.emailAddress,
                    comment: orderForm.data.comment,
                    salesPersonEmail: orderForm.data.salesPersonEmail
                })
            }

            httpFetch(`/api/order/denyOrderRequest/${customerNumber}/${orderId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess("Order has been denied"));
                    dispatch(commonActions.cancelFormEditing("orderForm"));
                    dispatch(appointmentActions.getOpenAppointmentsCount());
                    dispatch(leadActions.getOpenLeadsCount());
                    callback();
                    dispatch({ type: actionTypes.CANCEL_ORDER });
                    dispatch(commonActions.progressLoader(false));

                })
        }
    },


    changeAdditionalUsersCount(count) {
        return {
            type: actionTypes.CHANGE_ADDITIONAL_USERS,
            count
        }
    },

    clearBlikkUserLicenses() {
        return {
            type: actionTypes.CLEAR_BLIKK_ADDITIONAL_USERS
        }
    },

    changeBlikkUserLicenses(licenseTypes) {
        return {
            type: actionTypes.CHANGE_ADDITIONAL_BLIKK_USERS,
            licenseTypes
        }
    },
    toggleReceiptInterpretation(receiptInterpretation) {
        return {
            type: actionTypes.TOGGLE_RECEIPT_INTERPRETATION,
            receiptInterpretation
        }
    },

    clearBlikkTransactions() {
        return {
            type: actionTypes.CLEAR_BLIKK_TRANSACTIONS
        }
    },

    changeBlikkTransactions(transactionTypes) {
        return {
            type: actionTypes.CHANGE_BLIKK_TRANSACTIONS,
            transactionTypes
        }
    },

    changeApi(apiSelected) {
        return {
            type: actionTypes.CHANGE_API,
            api: apiSelected
        }
    },

    changePeriod(newPeriod) {
        return {
            type: actionTypes.CHANGE_PERIOD,
            period: newPeriod
        }
    },

    changeCompetitorDiscount(discountSelected) {
        return {
            type: actionTypes.CHANGE_COMPETITOR_DISCOUNT,
            discountSelected
        }
    },

    changeLoyaltyDiscountCustomer(loyaltyDiscountCustomerSelected) {
        return {
            type: actionTypes.CHANGE_LOYALTY_DISCOUNT_CUSTOMER,
            loyaltyDiscountCustomerSelected
        }
    },

    changeAssignedLead(leadId) {
        return {
            type: actionTypes.CHANGE_ASSIGNED_LEAD,
            leadId
        }
    },

    changeAssignedAppointment(appointmentId) {
        return {
            type: actionTypes.CHANGE_ASSIGNED_APPOINTMENT,
            appointmentId
        }
    },

    changeLangCode(langCode) {
        return {
            type: actionTypes.CHANGE_LANG_CODE,
            langCode
        }
    },

    changeAssignedTemplateOrganization(templateOrganizationId) {
        return {
            type: actionTypes.CHANGE_TEMPLATE_ORGANIZATION,
            templateOrganizationId
        }
    },

    updateAddons(vonAddonsIds) {
        return {
            type: actionTypes.CHANGE_ADDONS,
            values: vonAddonsIds
        }
    },

    getConfirmationFormTemplate(customerNumber, orderId) {
        return dispatch => {
            dispatch(commonActions.cancelFormEditing("confirmationMail"))
            dispatch(commonActions.progressLoader(true));
            return httpFetch(`/api/order/getConfirmationTemplate/${customerNumber}/${orderId}`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing('confirmationMail', json, formValidationRules.EmailTemplate));
                })
        }
    }
}

export default orderActions;
