import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import CustomerForm from '../../Components/Customer/CustomerForm'
import routes from '../../Constants/routes'
import customerActions from '../../Actions/customerActions'
import ConnectedCustomersVon from '../../Components/Customer/ConnectedCustomersVon';
import { withTranslation } from 'react-i18next';
import DropDown from '../../Components/DropDown';
import commonActions from '../../Actions/commonActions'
import { useNavigate } from 'react-router-dom';

function CustomerFormContainer({ fromMainpage, customer, t, customerForm, customerActions, commonActions }) {

    const navigate = useNavigate();
    const [selectedProductId, setSelectedProductId] = useState();

    useEffect(() => {
        if (customer.isNonInteractive) {
            navigate(routes.customerMain(customer.customerNumber));
        }
        customerActions.showCustomerEditForm(customer);
    }, [customer])

    const navigateAfterAction = () => {
        return () => navigate(routes.customerDetails(customer.customerNumber));
    }

    const onSaveCustomerConnectionsClick = (products) => {

        var data = {
            currentCustomer: customer.customerNumber,
            toAdd: { mainCustomers: [], subCustomers: [] },
            toDelete: { mainCustomers: customerForm.data.mainCustomersToDelete || [], subCustomers: customerForm.data.subCustomersToDelete || [] }
        }

        products.map(product => {
            if (product.subCustomers.customers) {
                var subCustomersToAdd = product.subCustomers.customers.filter(subCustomer => subCustomer.id == null && subCustomer != "" && subCustomer.customerNumber != "")
                if (subCustomersToAdd.length > 0) {
                    Array.prototype.push.apply(data.toAdd.subCustomers, subCustomersToAdd);
                }
            }
            if (product.mainCustomer.customer && product.mainCustomer.customer.id == null && product.mainCustomer.customer != "" && product.mainCustomer.customer.customerNumber != "") {
                data.toAdd.mainCustomers.push(product.mainCustomer.customer)
            }
        })

        customerActions.saveCustomerConnections(data, navigateAfterAction())
    }

    const handleProductChange = (selectedProduct) => {
        setSelectedProductId(selectedProduct)
    }

    const onArrayFieldChange = (field, arr, index, value) => {

        commonActions.changeFormArrayField(customerForm.id, field, arr, index, value)
    }

    const onFieldChange = (field, value) => {

        commonActions.changeMultiFormField(customerForm.id, field, value)
    }

    const onDelete = (field, customer) => {
        var toDelete = [];

        if (customerForm.data[field])
            toDelete = customerForm.data[field]

        toDelete.push(customer)

        commonActions.changeMultiFormField(customerForm.id, field, toDelete)
    }

    const isMainPage = fromMainpage ? fromMainpage : false;

    if (!customerForm) {
        return null;
    }

    const productDropdown = [{ name: "Lön Smart", subCustomers: { customers: customerForm.data.subLonSmartCustomers, field: "subLonSmartCustomers"}, mainCustomer: { customer: customerForm.data.mainLonSmartCustomer, field: "mainLonSmartCustomer" }, id: "LonSmart" },
    { name: "Skatt & Bokslut Pro", subCustomers: {customers: customerForm.data.subSkattBokslutCustomers, field: "subSkattBokslutCustomers"}, mainCustomer: { customer: customerForm.data.mainSkattBokslutCustomer, field: "mainSkattBokslutCustomer" }, id: "SkattBokslut" }]

    var selectedProduct = productDropdown.find(product => product.id === selectedProductId)

    return (
        <div className="panel">
            <div className="panel-heading">
                <div className="panel-title">{t("leftnavigation.editcustomer")}</div>
            </div>
            <div className="panel-body">
                <div className='left-customer-col'>
                    <CustomerForm callback={navigateAfterAction()} isMainPage={isMainPage} isCreation={false} />
                </div>
                {!isMainPage && <>
                    <fieldset className="col-xs-6">
                        <legend>{t('customereditcontainer.customerconnectionsvon')}</legend>
                        <DropDown label={t('customereditcontainer.product')} blankOption={true} list={productDropdown} onChange={handleProductChange.bind(this)} />
                        <div className='left-customer-col'>
                            {selectedProduct && <ConnectedCustomersVon name={selectedProduct.name} customer={customer} subCustomers={selectedProduct.subCustomers} mainCustomer={selectedProduct.mainCustomer}
                                onArrayChange={onArrayFieldChange.bind(this)} onChange={onFieldChange.bind(this)} commonActions={commonActions} id={selectedProduct.id} customerForm={customerForm} onDelete={onDelete.bind(this)} />}
                        </div>
                        <div className="clearfix"></div>

                        <div className="form-group padding-top no-left-margin btn-toolbar">
                            <button type="button" className="btn btn-primary" onClick={onSaveCustomerConnectionsClick.bind(this, productDropdown)}>{t('customerform.saveconnections')}</button>
                        </div>
                    </fieldset>
                </>}
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.selectedCustomer,
        customerForm: state.CommonReducer.forms && state.CommonReducer.forms.customerFormData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerFormContainer));