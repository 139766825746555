import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import LeftNavigationBarItem from '../LeftNavigationBarItem'
import routes from '../../Constants/routes'

class EducationLinks extends Component {

    constructor(props) {
        super(props);
        this.initState = {
        }

        this.state = this.initState;
    }

    getLinks() {
        const { customer: { customerNumber }, t } = this.props;

        return [
            {
                title: t('leftnavigation.education'), id: "education", onClick: () => this.props.setHeader("education"), subItems: [
                    { title: t('leftnavigation.bookeducation'), path: routes.educations(customerNumber) },
                    { title: t('leftnavigation.attendededucations'), path: routes.customerAttendedEducations(customerNumber) },
                ]
            }
        ];
    }
    
    render() {
        return <>
            {this.getLinks().filter(link => !link.disabled).map((item, index) => {
                return <LeftNavigationBarItem key={index} link={item} location={this.props.location} openHeader={this.props.openHeader} />
            })}
            </>
    }
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.selectedCustomer,
        openHeader: state.CommonReducer.openHeader
    }
}

export default connect(mapStateToProps)(withTranslation()(EducationLinks));