import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

import OrderGrid from '../../Components/Order/OrderGrid'

import TextInput from '../../Components/TextInput'

import orderActions from '../../Actions/orderActions'
import commonActions from '../../Actions/commonActions'

import DropDown from '../../Components/DropDown'

import * as calcTypes from '../../Constants/priceCalculationTypes'

class OrderDetailsContainer extends Component {
  
    onChange(field, value) {
        const { commonActions, orderForm } = this.props;

        commonActions.changeMultiFormField(orderForm.id, field, value)
    }

    onOrderLineEdit(orderline) {
        const order = this.props.order;

        this.props.genericActions.editOrderline(orderline, order.customerNumber, order.orderId);
    }

    onOrderLineRemove(orderlineId) {
        const { genericActions, order } = this.props;

        genericActions.removeOrderLines(order.orderId, [orderlineId], order.customerNumber)
    }

    copyContactInfo() {
        const { commonActions, orderForm, customer } = this.props;
       
        commonActions.changeMultiFormField(orderForm.id, 'yourReference', customer.contactPerson)
        commonActions.changeMultiFormField(orderForm.id, 'emailAddress', customer.emailAddress)
    }

    onFieldChange = (field, value) => {
        const { commonActions, orderForm } = this.props;
    
        commonActions.changeMultiFormField(orderForm.id, field, value);
    }

    render() {
        const order = this.props.order;
        
        if (!order || (order.orderlines && order.orderlines.length === 0)) 
            return null;
        
        const {t} = this.props
        const fieldData = this.props.orderForm && this.props.orderForm.data;
        const yourReference = (fieldData && fieldData.yourReference)
        const emailAddress = (fieldData && fieldData.emailAddress)

        return (
            <div className="orderDetails">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h3 className="panel-title">{this.props.priceCalcType === calcTypes.priceCalculationTypes.quotation 
                            ? calcTypes.priceCalculationTypeText.quotation 
                            : calcTypes.priceCalculationTypeText.order}  {t('orderdetailscontainer.details')}</h3>
                    </div>
                    <div className="panel-body ">
                        <div className="form-horizontal order-body">
                        
                            <div className="row padding-bottom">
                                {this.props.priceCalcType === calcTypes.priceCalculationTypes.order && !order.quotationInProgress && <div className="col-xs-12 col-md-9">
                                    <TextInput label={t('orderdetailscontainer.name')} value={yourReference} onChange={this.onChange.bind(this, 'yourReference')}/>
                                    <TextInput label={t('orderdetailscontainer.confirmationemail')} value={emailAddress} onChange={this.onChange.bind(this, 'emailAddress')}/>
                                </div> 
                                }
                                {this.props.priceCalcType === calcTypes.priceCalculationTypes.order && !order.quotationInProgress && <div className="col-xs-3 col-xs-offset-4 col-md-offset-0 use-contact-button-container">
                                    <button className="btn btn-small btn-default copy use-contact-button" onClick={this.copyContactInfo.bind(this)}>{t('orderdetailscontainer.usecontacts')}</button>
                                </div>
                                }
                            </div>
                            <OrderGrid order={order} onEditClick={this.onOrderLineEdit.bind(this)} onRemoveClick={this.onOrderLineRemove.bind(this)} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        order: state.OrderReducer.order,
        customer: state.CustomerReducer.selectedCustomer,
        orderForm: state.CommonReducer.forms && state.CommonReducer.forms.orderForm,        
    }
}

function mapDispatchToProps(dispatch) {
   return {
       //orderActions : bindActionCreators(orderActions, dispatch),
       commonActions : bindActionCreators(commonActions, dispatch)
    } 
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderDetailsContainer)); 