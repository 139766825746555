import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import VbProductsContainer from './VbProductsContainer'
import VonProductsContainer from './VonProductsContainer'
import ThirdPartyProductsContainer from './ThirdPartyProductsContainer'
import VismaVceModal from '../../Components/Customer/VismaVceModal'
import customerActions from '../../Actions/customerActions'
import commonActions from '../../Actions/commonActions'
import {withTranslation} from 'react-i18next';
import LeadSendEmailModal from '../../Components/Customer/LeadSendEmailModal'
import InternalLeadSendEmailModal from '../../Components/Customer/SendInternalLeadEmailModal'
import actionLogActions from '../../Actions/actionLogActions';
import AOCustomerInformationModal from './AOCustomerInformationModal';
import Panel from "../../Components/Panel";
import {
    InyettDetails,
    MainInfoContainer,
    InternalInfo,
    ContactInfo,
    LonSmart,
    TransactionServices,
    ConnectedCustomers,
    VismaFinanceProductContainer,
    VonAccounts,
    RelatedCustomers,
    CustomerActionHistory
} from "../../Components/Customer/CustomerComponents";

import AoCustomerInformation from "../../Components/Customer/CustomerComponents/AoCustomerInformation";

import helpers from "../../Utils/helpers";

const availibleComponents = {
    "main" : { id: "main", title: "maininfocontainer.header" },
    "internal": { id: "internal", title: "internalinfocontainer.header" },
    "inyettdetails": { id: "inyettdetails", title: "inyettdetailscontainer.header" },
    "customers": { id: "customers", title: "connectedcustomercontainer.header" },
    "vbproducts": { id: "vbproducts", title: "vbproductscontainer.header" },
    "saasproducts": { id: "saasproducts", title: "vonproductcontainer.header" },
    "contact": { id: "contact", title: "contactinfocontainer.header" },
    "lonsmart": { id: "lonsmart", title: "connectedcustomervoncontainer.header" },
    "transactions": { id: "transactions", title: "transactionservices.header" },
    "thirdpartyproducts": { id: "thirdpartyproducts", title: "thirdpartyproductscontainer.thirdpartyproducts" },
    "aocustomerinfo": { id: "aocustomerinfo", title: "aocustomerinformation.cardheader", isDisabledType: "isNotAoCustomer" },
    "vismaFinance": { id: "vismaFinance", title: "vismafinance.header" },
    //"collaborations": { id: "collaborations", title: "vonproducts.collaboration" },
    "actionhistory": { id: "actionhistory", title: "actionhistory.actionhistory" },
    "relatedcustomers": { id: "relatedcustomers", title: "relatedcustomerscontainer.relatedcustomer" },
    "vonaccounts": { id: "vonaccounts", title: "vonaccountscontainer.vonaccounts" },
}

const defaultLayout = {
    layoutName: 'default',
    blockList:
    {
        col1: [
            availibleComponents.main,
            availibleComponents.internal,
            availibleComponents.inyettdetails,
            availibleComponents.vbproducts,
            availibleComponents.saasproducts,
            availibleComponents.thirdpartyproducts
        ],
        col2: [
            availibleComponents.contact,
            availibleComponents.customers,
            availibleComponents.lonsmart,
            availibleComponents.aocustomerinfo,
            availibleComponents.transactions,
            availibleComponents.vismaFinance
        ]
    }
}

const mainLayout = {
    layoutName: 'main',
    blockList:
    {
        col1: [
            availibleComponents.main,
            availibleComponents.internal,
            availibleComponents.inyettdetails
        ],
        col2: [
            availibleComponents.contact,
            availibleComponents.customers
        ]
    }
}

export const agreementsLayout = {
    layoutName: 'agreements',
    blockList:
    {
        col1: [
            availibleComponents.vbproducts,
            availibleComponents.saasproducts,
            availibleComponents.thirdpartyproducts
        ],
        col2: [
            availibleComponents.transactions,
            availibleComponents.vismaFinance
        ]
    }

}

const customerAdministration = {
    layoutName: 'customerAdmin',
    blockList:
    {
        col1: [
            availibleComponents.internal,
            availibleComponents.vbproducts,
            availibleComponents.saasproducts,
            availibleComponents.thirdpartyproducts
        ],
        col2: [
            availibleComponents.contact,
            availibleComponents.customers,
            //availibleComponents.collaborations,
            availibleComponents.actionhistory,
            availibleComponents.transactions
        ]
    }
}

const supportL1 = {
    layoutName: 'supportL1',
    blockList:
    {
        col1: [
            availibleComponents.internal,
            availibleComponents.vbproducts,
            availibleComponents.saasproducts,
            availibleComponents.thirdpartyproducts
        ],
        col2: [
            availibleComponents.contact,
            //availibleComponents.collaborations,
            availibleComponents.transactions
        ]
    }
}

const supportL2 = {
    layoutName: 'supportL2',
    blockList:
    {
        col1: [
            availibleComponents.internal,
            availibleComponents.vbproducts,
            availibleComponents.saasproducts,
            availibleComponents.thirdpartyproducts
        ],
        col2: [
            //availibleComponents.collaborations,
            availibleComponents.customers,
            availibleComponents.relatedcustomers,
            availibleComponents.actionhistory,
            availibleComponents.transactions
        ]
    }
}

const salesPartner = {
    layoutName: 'salesPartner',
    blockList:
    {
        col1: [
            availibleComponents.contact
        ],
        col2: [
            availibleComponents.actionhistory
        ]
    }
}

const salesSMB = {
    layoutName: 'salesSMB',
    blockList:
    {
        col1: [
            availibleComponents.internal,
            availibleComponents.vbproducts,
            availibleComponents.saasproducts,
            availibleComponents.thirdpartyproducts
        ],
        col2: [
            availibleComponents.contact,
            //availibleComponents.collaborations,
            availibleComponents.vonaccounts, // TODO
            availibleComponents.transactions
        ]
    }
}

const salesAO = {
    layoutName: 'salesAO',
    blockList:
    {
        col1: [
            availibleComponents.internal,
            availibleComponents.vbproducts,
            availibleComponents.saasproducts,
            availibleComponents.thirdpartyproducts
        ],
        col2: [
            availibleComponents.contact,
            //availibleComponents.collaborations,
            availibleComponents.actionhistory
        ]
    }
}

const layouts = [defaultLayout, mainLayout, agreementsLayout, customerAdministration, supportL1, supportL2, salesPartner, salesSMB, salesAO]


async function onSaveClick(props){
    const { commonActions, customerForm, isCreation, customerActions } = props;

    if (customerForm.data.mainCustomer == "" || (customerForm.data.subCustomers && customerForm.data.subCustomers.length > 0 && customerForm.data.subCustomers.every(i => i == ""))) {
        commonActions.showWarning("Main customer is either not selected, or every added sub customer is empty, select main customer or remove open sub customer fields. Please validate!");
        return;
    }
    else if (customerForm.data.group === 1001 && (customerForm.data.mainCustomer == null)) {
        commonActions.showWarning("Main customer is missing or isn't selected. Add main customer and try again. Please validate!");
        return;
    }

    await customerActions.saveCustomer(customerForm, isCreation)
    props.changeEditInternalInfo();
}

const saveSearchHistory = customer => {
    const storage = localStorage.getItem("CustomerSearchHistory");
    const history = storage ? JSON.parse(storage) : [];
    const customerSearchHistory = {
        customerNumber: customer.customerNumber,
        organizationNumber: customer.organizationNumber,
        name: customer.name
    }

    const index = history.findIndex(item => item.customerNumber === customer.customerNumber)

    if (index >= 0)
        history.splice(index, 1);

    if (history.length === 10)
        history.splice(0, 1);

    history.push(customerSearchHistory);

    localStorage.setItem("CustomerSearchHistory", JSON.stringify(history))
}

class CustomerMain extends Component {

    constructor(props) {
        super(props);

        this.initState = {
            showAoLeadEmailModal: false,
            showLeadSendEmailModal: false,
            showVismaVceModal : false,
            showInternalLeadEmailModal: false,
            editInternalInfo: false,
            editContact: false,
            editAOCustomerInfo: false,
            supplier: false,
            assignments: false,
            eekonomi: false
        }

        this.state = this.initState;
    }

    componentDidMount() {
        this.props.customerActions.getCustomer(this.props.customer.customerNumber);
        //this.props.customerActions.getCollaborations(this.props.customer.customerNumber);
        this.props.actionLogActions.getCustomerActionLog(this.props.customer.customerNumber);
        this.props.customerActions.getRelatedCustomers(this.props.customer.customerNumber);
        //this.props.customerActions.getVismaFinanceProducts(this.props.customer.customerNumber);

        if(this.props.customer.hasVonAccount) {
            this.props.customerActions.getVonAccounts(this.props.customer.customerNumber);
        }

        if(helpers.isAoCustomer(this.props.customer)) {
            this.props.commonActions.loadDropdownList("AoSuppliers");
            this.props.customerActions.getAOCustomerInfo(this.props.customer.customerNumber);
        }

        saveSearchHistory(this.props.customer);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.customer.customerNumber !== this.props.customer.customerNumber && helpers.isAoCustomer(this.props.customer)) {
            this.props.customerActions.getAOCustomerInfo(this.props.customer.customerNumber);
        }
    }

    toggleModal = (modalName, emailTemplate) => {

        if (emailTemplate)
            this.props.customerActions.setInternalLeadsEmailTemplate(emailTemplate);
        this.setState({
            [modalName]: !this.state[modalName]
        });
        //
        // if (activeModal === modalName)
        //     setActiveModal("")
        // else {
        //     setActiveModal(modalName)
        // }
    };

    changeEditInternalInfo = () => {
        this.setState({editInternalInfo: !this.state.editInternalInfo});
    }

    updateContact = () => {
        this.setState({editContact: !this.state.editContact});
    }

    setHoverState = (hoverType) => {
        this.setState({[hoverType]: !this.state[hoverType]});
    }

    renderColumnBlock(block) {
        return <div className="row">
            <div className="col-12 col-lg-8 p-0">
                {block.col1.map(b => !this.checkIsDisabled(b) && <Panel key={b.id} title={this.props.t(b.title)}>{this.renderDynamicPage(b)}</Panel>)}
            </div>
            <div className="col-12 col-lg-4 p-0">
                {block.col2.map(b => !this.checkIsDisabled(b) && <Panel key={b.id} title={this.props.t(b.title)}>{this.renderDynamicPage(b)}</Panel>)}
            </div>
        </div>
    }

    checkIsDisabled(block) {
        switch (block.isDisabledType) {
            case "isNotAoCustomer": return !helpers.isAoCustomer(this.props.customer)
            default: return
        }
    }

    renderDynamicPage(block) {
        switch (block.id) {
            case "main": return <MainInfoContainer key={block.id} {...this.props} toggleModal={this.toggleModal.bind(this)}/>
            case "contact": return <ContactInfo key={block.id} {...this.props} updateContact={this.updateContact} editContact={this.state.editContact}/>
            case "internal": return <InternalInfo key={block.id} {...this.props} editInternalInfo={this.state.editInternalInfo} changeEditInternalInfo={this.changeEditInternalInfo}/>
            case "inyettdetails": return <InyettDetails key={block.id} {...this.props} />
            case "customers": return <ConnectedCustomers key={block.id} {...this.props} />
            case "transactions": return <TransactionServices key={block.id} {...this.props} toggleModal={this.toggleModal.bind(this)}/>
            case "vismaFinance": return <VismaFinanceProductContainer key={block.id} {...this.props} />
            case "vbproducts": return <VbProductsContainer />
            case "lonsmart": return <LonSmart key={block.id} {...this.props} />
            case "saasproducts": return <VonProductsContainer />
            case "thirdpartyproducts": return <ThirdPartyProductsContainer />
            //case "collaborations": return <CollaborationsList key={block.id} {...this.props} />
            case "actionhistory": return <CustomerActionHistory key={block.id} {...this.props} />
            case "relatedcustomers": return <RelatedCustomers key={block.id} {...this.props} />
            case "vonaccounts": return <VonAccounts key={block.id} {...this.props} />
            case "aocustomerinfo": return <AoCustomerInformation key={block.id} {...this.props} toggleModal={this.toggleModal.bind(this)} setHoverState={this.setHoverState.bind(this)} hoverState={this.state}/>
            default: return <></>
        }
    }

    selectLayout() {

        if(!this.props.customerInfoOption)
            return defaultLayout
                     
        var selectedLayout = layouts.find(e => e.layoutName === this.props.customerInfoOption)

        if (selectedLayout)
            return selectedLayout
        else
            return defaultLayout
    }
    
    render() {
        const layout = this.selectLayout();

        return (
            <>
                {this.state.showLeadSendEmailModal && <LeadSendEmailModal onClose={this.toggleModal.bind(this, "showLeadSendEmailModal")} />}
                {this.state.showVismaVceModal && <VismaVceModal onClose={this.toggleModal.bind(this, "showVismaVceModal")} />}
                {this.state.showInternalLeadEmailModal && <InternalLeadSendEmailModal onClose={this.toggleModal.bind(this, "showInternalLeadEmailModal")} />}
                {this.state.editAOCustomerInfo && <AOCustomerInformationModal {...this.props} onClose={this.toggleModal.bind(this, "updateAOCustomerInfoModal")} />}
                {this.renderColumnBlock(layout.blockList)}
            </>
        )

        // return (
        //     <div className="form-horizontal customer-details">
        //         {activeModal === 'showLeadSendEmailModal' && <LeadSendEmailModal onClose={toggleModal.bind(this, "showLeadSendEmailModal")} />}
        //         {activeModal === 'showVismaVceModal' && <VismaVceModal onClose={toggleModal.bind(this, "showVismaVceModal")} />}
        //         {activeModal === 'showInternalLeadEmailModal' && <InternalLeadSendEmailModal onClose={toggleModal.bind(this, "showInternalLeadEmailModal")} />}
        //         <div>{renderDynamicPage(defaultLayout.blockList)}</div>
        //     </div>
        // )
    }
}

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
        customer: state.CustomerReducer.selectedCustomer,
        isCustomerValid: state.CustomerReducer.isValid,
        dropdownlists: state.CommonReducer.dropdownlists,
        userSalesNumber: state.CommonReducer.user && state.CommonReducer.user.salesNumber,
        actionLogs: state.ActionLogReducer.actionLogs,
        relatedCustomers: state.CustomerReducer.relatedCustomers,
        vonAccounts: state.CustomerReducer.vonAccounts,
        customerForm: state.CommonReducer.forms && state.CommonReducer.forms.customerFormData,
        updatedInfo: state.CommonReducer.forms && state.CommonReducer.forms.updateCustomerForm,
        aoCustomerInfo: state.CustomerReducer.aoCustomerInfo,
        vismaFinance: state.CustomerReducer.vismaFinance,
        vismaFinanceProductLoading: state.CustomerReducer.vismaFinanceProductLoading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        actionLogActions : bindActionCreators(actionLogActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerMain));