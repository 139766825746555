import { connect } from "react-redux";
import Card from "../../../../Components/Card/Card";
import ActivityLogForm from "../../../../Components/InternalCustomerAdmin/ActivityLog/ActivityLogForm";
import { ActivityLogTable } from "../../../../Components/InternalCustomerAdmin/ActivityLog/ActivityLogTable";
import { useTranslation } from "react-i18next";
import activityLogActions from "../../../../Actions/InternalCustomerAdmin/activityLogActions";
import { useEffect } from "react";
import { bindActionCreators } from "redux";
import Loader from "../../../../Components/Loader";

function ActivityLogContainer({ ...props }) {

    const { t } = useTranslation();
    const { activityLogs, activityLogActions, isLoading } = props;

    useEffect(() => {
        return () => {
            activityLogActions.clearLogs();
        }
    }, []);

    function noLogsFound() {
        return <div className="w-100 text-center">
            <p>{t("activitylogcontainer.nologsfound")}</p>
        </div>
    }

    return <Card>
        <Card.Header>
            <Card.Header.Title>{t('activitylogcontainer.showlogging')}</Card.Header.Title>
        </Card.Header>
        <Card.Content>
            <div>
                <div>
                    <ActivityLogForm {...props} />
                </div>
                <div>
                    {!isLoading && (activityLogs ? (activityLogs.length > 0 ? <ActivityLogTable {...props} /> : noLogsFound()) : null)}
                </div>
                <Loader />
            </div>
        </Card.Content>
    </Card>
}

function mapStateToProps(state) {
    return {
        activityLogs: state.InternalCustomerAdminReducer.activityLogs,
        isLoading: state.CommonReducer.isLoading
    };
}

function mapDispatchToProps(dispatch) {
    return {
        activityLogActions: bindActionCreators(activityLogActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLogContainer);