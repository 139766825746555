import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import customerActions from '../../Actions/customerActions'
import { useTranslation, withTranslation } from 'react-i18next';
import withModalForm from '../../Components/HOC/withModalForm'
import VonProducts from '../../Components/Customer/VonProducts'
import VonChangePeriodModal from '../../Components/Customer/VonChangePeriodModal'
import ProductTerminationModal from '../../Components/Customer/ProductTerminationModal'
import { NoInformation } from "../../Components/Panel";

function VonProductsContainer({customer, customerActions, terminationForm, reasons, pendingTerminationRequests, 
                                  vonAccounts, payload, onFieldChange, onCloseClick, onOpenModal, isModalActive }) {

    useEffect(() => {

        if (customer.hasVonAccount)
            customerActions.getVonAccounts(customer.customerNumber);

        customerActions.getPendingTerminationRequests(customer.customerNumber);
        
    }, [])
    
    const { t } = useTranslation();
    
    if (!customer.vonProducts || customer.vonProducts.length < 1 || !pendingTerminationRequests) 
        return <NoInformation />
    
    const terminationModalAttr = {
        form: terminationForm,
        reasons: reasons,
        onChange: onFieldChange,
        onClose: onCloseClick,
        onLoad: () => customerActions.getSaasTerminationEmailTemplate(customer.customerNumber, payload),
        onSave: () => customerActions.cancelSaasAgreement(terminationForm, () => onCloseClick(terminationForm.id))
    }

    const renderEndDate = (product) => {
        if(product.isTrial){
            return product.trialExpireDate
        }
        else if(!product.isProductValid){
            return <span style={{ color: "red" }}>{product.endDate}</span>
        }else{
            return product.endDate
        }
    }


    const addons = customer.addons;

    return <>
        <VonProducts products={customer.vonProducts} vonAccounts={vonAccounts}
                     pendingTerminationRequests={pendingTerminationRequests} onButtonClick={onOpenModal}
                     customerGroup={customer.group}
                     getEndDate={renderEndDate}/>
        {isModalActive('period') &&
            <VonChangePeriodModal onChange={onFieldChange} onClose={onCloseClick} service={payload}/>}
        {isModalActive('terminate') && <ProductTerminationModal {...terminationModalAttr} />}

        {addons && <div className="table-responsive">
            <table className="table table-stripe">
                <thead>
                <tr>
                    <th>{t('addons.addons')}</th>
                </tr>
                </thead>
                <tbody>
                {addons.map((item, index) =>
                    <tr key={index}>
                        <td>{item.name}</td>
                    </tr>)
                }

                </tbody>
            </table>
        </div>}
    </>
}

function mapStateToProps(state) {
    return {
        terminationForm: state.CommonReducer.forms && state.CommonReducer.forms.CancelSaasAgreement,
        reasons: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists['SaasTerminationReasons'],
        customer: state.CustomerReducer.selectedCustomer,
        vonAccounts: state.CustomerReducer.vonAccounts,
        pendingTerminationRequests: state.CustomerReducer.pendingTerminationRequests
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
    }
}

export default withModalForm(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VonProductsContainer)));