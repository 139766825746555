import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import TextInput from './TextInput'
import DropDown from './DropDown'
import {FormEditor} from './Editor'
import Dropzone from 'react-dropzone'
import SendTemplateApprovalEmailModal from '../Components/Customer/SendTemplateApprovalEmailModal'
import StaticTextInput from '../Components/StaticTextInput'

class EmailTemplate extends Component {

    state = {
        file: null,
        showSendEmailModal: false,
        loading: false,
        selectedOption: ''
    }

    handleUploadClick = () => {
        this.setState({ loading: true })
        this.props.settingsActions.uploadLeadsList(this.state.file);
    }

    onVbClick() {
        this.props.onSenderChange('to', this.props.vbEmail)
        this.setState({ selectedOption: this.props.vbEmail })
    }

    onVonClick() {
        if (this.props.vonAccounts) {
            var primaryContact = this.props.vonAccounts.find(vonAccount => vonAccount.isPrimaryContact === true)
            this.props.onSenderChange('to', primaryContact.email)
            this.setState({ selectedOption: primaryContact.email })
        }
    }

    toggleModal = (modalName) => {
        this.setState({
            [modalName]: !this.state[modalName]
        });
    }

    onFromChange(event, value) {
        const { onSenderChange, user, userSignature, data, onChange, sendDemo } = this.props;

        if (sendDemo) {
            if (value.includes(user.email) && userSignature.signature) {
                onChange('body', data.body + "<signature>" + userSignature.signature + "</signature>")
            } else if (userSignature.signature) {
                var signature = data.body.substring(
                    data.body.lastIndexOf("<signature>"),
                    data.body.lastIndexOf("</signature>")
                );
                onChange('body', data.body.replace(signature, ""))
            }
        }
        onSenderChange(event, value)
    }

    onDropDownSelect(event) {
        this.props.onSenderChange('to', event)
        this.setState({ selectedOption: event })
    }

    render() {

        const { data, templates, onTemplateChange, onChange, onSenderChange, senderEmails, receiverEmails, isAdminForm, hasVonAccount, vonAccounts, t } = this.props;

        if (!data) {
            return null;
        }

        const contactList = [];

        if (vonAccounts) {
            {
                this.props.vonAccounts.map((vonAccount) => {
                    if (vonAccount.roles.find(role => role.role === "customeradmin")) {
                        contactList.push({ name: vonAccount.name, id: vonAccount.email })
                    }
                })
            }
        }

        return (
            <form className="skynet-form">
                <div className="margin-top">
                    {isAdminForm && hasVonAccount &&
                        <div>
                            <div className="form-group">
                                <label className="col-xs-4 control-label"></label>
                                <div className="col-xs-8">
                                    <button className="btn btn-primary" type="button" onClick={this.onVonClick.bind(this)} style={{ margin: "0px 10px 0px 0px" }}>{t('emailtemplate.voncontact')}</button>
                                    <button className="btn btn-primary" type="button" onClick={this.onVbClick.bind(this)}>{t('emailtemplate.vbcontact')}</button>
                                </div>
                            </div>
                            <DropDown label=" " blankOption={true} value={this.state.selectedOption} onChange={this.onDropDownSelect.bind(this)} list={contactList} />
                            <br />
                        </div>
                    }

                    {data.helperText && this.showHelperText(data.helperText)}
                    {templates && <DropDown label={t('emailtemplate.emailtemplate')} blankOption={true} onChange={onTemplateChange} list={templates} />}
                    {receiverEmails && receiverEmails.length > 1 ? <DropDown label={t('emailtemplate.to')} onChange={onSenderChange.bind(this, 'to')} list={receiverEmails} nameValued={true} /> : <TextInput label={t('emailtemplate.to')} value={data.to} onChange={onChange.bind(this, 'to')} />}
                    {senderEmails && senderEmails.length > 1 ? <DropDown label={t('emailtemplate.from')} onChange={this.onFromChange.bind(this, 'from')} list={senderEmails} nameValued={true} /> : <TextInput label={t('emailtemplate.from')} value={data.from} onChange={onChange.bind(this, 'from')} disabled={true} />}
                    {data.subjectDropdown && data.subjectDropdown.length > 1 ? <DropDown label={t('emailtemplate.subject')} blankOption={true} onChange={onSenderChange.bind(this, 'subject')} list={data.subjectDropdown} nameValued={true} /> : <TextInput label={t('emailtemplate.subject')} value={data.subject} onChange={onChange.bind(this, 'subject')} />}
                    {data.rules && this.generateAdditionalFields(data, onChange)}
                    <FormEditor label={!data.rules ? t('emailtemplate.body') : t('emailtemplate.preview')} toolbar={false} value={data.body} onChange={!!data.rules ? null : onChange.bind(this, 'body')} readOnly={!!data.rules} />
                    {data.hasCustomAttachment === true &&
                        <div>
                            <Dropzone
                                accept="application/pdf, image/png"
                                onDrop={async ([file]) => { this.handleOnDrop(data, file) }}
                                multiple={true}>
                                {({ getRootProps }) => (
                                    <div {...getRootProps()} className='text-center padding-top'>
                                        <span className='vismaicon vismaicon-lg vismaicon-filled vismaicon-info' />
                                        <h1>{t('emailtemplate.dropfile')}</h1>
                                    </div>
                                )}
                            </Dropzone>

                            <div className='col-xs-9'>
                                <aside>
                                    {this.state.file &&
                                        <div>
                                            <h2>{t('emailtemplate.fileinfo')}</h2>
                                            <ul>
                                                <li>{t('emailtemplate.name')}{this.state.file.name}</li>
                                                <li>{t('emailtemplate.size')}{(this.state.file.size / 1024 / 1024).toFixed(2)} Mb</li>
                                            </ul>
                                        </div>
                                    }
                                </aside>
                            </div>
                        </div>}
                    {(this.props.hideWarning != true) && (!data.approved && !data.author) &&
                        <div>
                            <button type="button" className={`btn " ${data.approved ? "btn-primary " : "btn-danger"}`} disabled={true}>{data.approved ? t("emailtemplate.approved") : t("emailtemplate.notapproved")}</button>
                            {!data.approved &&
                                <div className="emailtemplate-warningtext">
                                    <strong>{t('emailtemplate.thistemplateisnotapproved')}</strong> {data.responsible && <strong><a onClick={() => this.toggleModal("showSendEmailModal")}>{t('emailtemplate.clickhere')}</a>{t('emailtemplate.tocontactthepersonresponsible')}</strong>}
                                </div>
                            }
                            {this.state.showSendEmailModal && <SendTemplateApprovalEmailModal onClose={this.toggleModal.bind(this, "showSendEmailModal")} onSave={() => this.onCloseClick("showSendEmailModal")} templateId={data.templateId} />}
                        </div>
                    }
                </div>
            </form>
        );
    }

    replacePlaceholdersInBody(data, bodyText, onChange) {
        let newBody = bodyText;

        if (data.checkboxValues) {
            newBody = newBody.replace(`[checkboxes]`, data.checkboxValues.join('<br/>'));
        }

        data.rules.forEach(r => {
            newBody = newBody.replace(`[${r.id}]`, data[r.id] || '');
        });

        onChange('body', newBody);
    }

    editorOnChange(data, rule, onChange) {
        return value => {
            let newBody = data.body_copy;
            data[rule.id] = value;
            newBody = newBody.replace(`[${rule.id}]`, value);
            this.replacePlaceholdersInBody(data, newBody, onChange);
            onChange(rule.id, value);
        }
    }

    checkboxToggle(data, rule, onChange) {
        return (event) => {
            let newBody = data.body_copy;
            if (!data.checkboxValues) {
                data.checkboxValues = [];
            }
            if (event.target.checked) {
                data.checkboxValues.push(rule.value);
            } else {
                data.checkboxValues = data.checkboxValues.filter(value => value !== rule.value);
            }
            const checkboxValueString = data.checkboxValues.join('<br/>');
            newBody = newBody.replace(`[checkboxes]`, checkboxValueString);
            onChange('body', newBody);
            this.replacePlaceholdersInBody(data, newBody, onChange);
        };
    }

    handleOnDrop(data, file) {

        this.setState({ file: file })
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = function (ev) {
            const array = new Uint8Array(ev.target.result);
            const fileByteArray = [];
            for (let i = 0; i < array.length; i++) {
                fileByteArray.push(array[i]);
            }
            data.attachmentContent = [].slice.call(array);
            data.attachmentFileName = file.name
        }
    }

    generateAdditionalFields(data, onChange) {
        return data.rules.filter(rule => rule.rule != 'invisible').map((rule, index) => {

            if (rule.type === "editor") {

                return <FormEditor key={index} label={rule.label} value={data[rule.id]} onChange={this.editorOnChange(data, rule, onChange)} />
            }

            if (rule.type === 'checkbox')
                return <TextInput key={rule.id} checkbox={true} checkboxToggle={() => this.checkboxToggle(data, rule, onChange)} label={rule.label} value={data[rule.id]} onChange={this.editorOnChange(data, rule, onChange)} disabled={!data.checkboxValues || !data.checkboxValues.includes(rule.value)} />
                        

            if (rule.defaultValue)
                data[rule.id] = rule.defaultValue

            return <TextInput key={index} label={rule.label} value={data[rule.id]} onChange={this.editorOnChange(data, rule, onChange)} disabled={rule.defaultValue} />

        })
    }

    showHelperText(helperText) {
        return <StaticTextInput label="" value={helperText} col2Width="12" type="raw" marginBottom={20} />
    }
}

function mapStateToProps(state) {
    return {
        user: state.CommonReducer.user,
        userSignature: state.CommonReducer.userSignature
    }
}


export default connect(mapStateToProps, null)(withTranslation()(EmailTemplate));