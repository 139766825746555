import React, { useState } from 'react';
import PastActionInfo from '../PastActionInfo'
import helpers from '../../Utils/helpers'
import { useTranslation } from 'react-i18next';
import Modal from "../Modal";
import HorizontalText, { HorizontalRow } from "../HorizontalText";

function InvoiceList({ invoices, customerNumber, onSendClick, onCreditClick}) {

    const { t } = useTranslation();
    const [isModalActive, setModalActive] = useState(false)
    const [invoice, setSelectedInvoice] = useState(null)


    const renderSendButton = (invoice, onSendClick) => {
        if (!invoice.filePath || invoice.deleted) {
            return null;
        }

        return <button className="btn btn-default" onClick={onSendClick.bind(this, invoice.solumInvoiceOCR, invoice.vbInvoiceOCR, invoice.invoiceNumber, invoice.id)}><span className="vismaicon vismaicon-sm vismaicon-email" title="Send" /> {t('invoicelist.send')}</button>
    }

    const renderLastActionInfo = (invoice) => {
        if (!invoice.sentDate) {
            return null;
        }

        return <PastActionInfo date={invoice.sentDate} sender={invoice.sender} />
    }

    const renderLinkToInvoice = (customerNumber, invoice) => {
        const { solumInvoiceOCR, invoiceNumber } = invoice

        if (!invoice.filePath || invoice.deleted) {
            return invoiceNumber;
        }

        return <a href={`${helpers.getFullApiHostname()}/api/invoice/pdf/${customerNumber}/${solumInvoiceOCR}/${invoiceNumber}`} target='_blank' rel="noopener noreferrer">{invoiceNumber}</a>
    }

    const showDetailsModal = (invoice) => () => {
        setSelectedInvoice(invoice);
        setModalActive(true);
    }

    const mapTransportService = (transportService) => {

        // Enum values in backend
        // Electronic = 1,
        // Print = 2,
        // Epdf = 4

        switch (transportService) {
            case 1:
                return 'Electronic';
            case 2:
                return 'Print';
            case 4:
                return 'Epdf';
            default:
                return '';
        }
    }

    const modalProps = {
        title: t('invoicelist.deliveryInfo'),
        onCancelClick: () => setModalActive(false),
        stateful: true,
        open: true,
        showButtons: false,
        customClassName: 'modal-lg',
    }

    const renderCreditButton = (invoice) => {
        if(invoice.amount > 0)
            return <button className="btn btn-default" onClick={() => onCreditClick('sendInvoiceCredit', invoice)}><span className="vismaicon vismaicon-sm vismaicon-email" title={t('invoicelist.credit')} />{t('invoicelist.credit')}</button>
    }

    return (
        <div className="col-xs-12">
            <table className="table table-stripe table-invoices">
                <thead>
                    <tr>
                        <th className="text-left">{t('invoicelist.invoicenumber')}</th>
                        <th className="text-left">{t('invoicelist.date')}</th>
                        <th className="text-left">{t('invoicelist.expirydate')}</th>
                        <th className="text-right">{t('invoicelist.amount')}</th>
                        <th className="text-right" style={{ width: 140 }}>{t('invoicelist.residualamount')}</th>
                        <th className="text-left">{t('invoicelist.dateofpayment')}</th>
                        <th className="text-center" style={{ width: 140 }}></th>
                        <th className="text-center" style={{ width: 140 }}></th>
                        <th className="text-center" style={{ width: 140 }}></th>
                    </tr>
                </thead>
                <tbody>
                    {invoices.map((invoice, index) => {
                        return <tr key={index}>
                            <td>{renderLinkToInvoice(customerNumber, invoice)} {invoice.reminder && <span className="vismaicon vismaicon-sm vismaicon-filled vismaicon-info" title={t('invoicelist.reminder')} />} </td>
                            <td>{invoice.invoiceDate}</td>
                            <td>{invoice.dueDate}</td>
                            <td className="text-right">{invoice.amount}</td>
                            <td className="text-right">{invoice.balance}</td>
                            <td>{helpers.checkForInvalidDate(invoice.latestPayDate)}</td>
                            <td>{!!invoice.transportService && <button className="btn btn-default" onClick={showDetailsModal(invoice)}>{t('invoicelist.deliveryInfo')}</button>}</td>
                            <td className="text-center infoColumn">{renderSendButton(invoice, onSendClick)} {renderLastActionInfo(invoice)}</td>
                            <td className="text-center infoColumn">{renderCreditButton(invoice)}</td>
                        </tr>
                    })}
                </tbody>
            </table>
            {isModalActive && <Modal {...modalProps}>

                <HorizontalRow>
                    <HorizontalText columns={1} label={t('invoicelist.invoicenumber')} value={invoice.invoiceNumber}
                                    copyToClipBoard={true}/>
                    {invoice.pdfRecipientEmailAddress && <HorizontalText columns={1} label={t('invoicelist.pdfRecipientEmailAddress')}
                                    value={invoice.pdfRecipientEmailAddress} copyToClipBoard={true}/>}
                    <HorizontalText columns={1} label={t('invoicelist.transportService')}
                                    value={mapTransportService(invoice.transportService)}/>

                    {/*<HorizontalText columns={1} label="Fakturalänk"*/}
                    {/*                value={invoice.invoiceLink} />*/}
                </HorizontalRow>

                {!!invoice.messages.length &&
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th>{t('invoicelist.date')}</th>
                        <th>{t('invoicelist.message')}</th>
                        <th>{t('invoicelist.user')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    { invoice.messages.map((message, index) => {
                        return <tr key={index}>
                            <td className="text-nowrap">{helpers.formatDate(message.date)}</td>
                            <td>{message.message}</td>
                            <td>{message.user}</td>
                        </tr>
                    })
                    }
                    </tbody>
                </table>}

            </Modal>}
        </div>
    );
}

export default InvoiceList;