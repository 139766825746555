import React from "react";
import './Card.css'

export function CardContainer({ children }) {
    return (
        <div className="card-container">
            {React.Children.map(children, (child) => child && React.cloneElement(child))}
        </div>
    )
}

export function CardRow({ children, orientation, gap }) {
    const orientationClassName = orientation === "horizontal" ? "card-row-horizontal" : "card-row-vertical";

    const style = gap ? { gap: `${gap}px` } : { gap: '32px' };

    return (
        <div className={`card-row ${orientationClassName}`} style={style}>
            {React.Children.map(children, (child, index) => (
                child &&
                <div key={index} className={orientationClassName === "card-row-horizontal" ? "card-column" : "card-row-item"}>
                    {React.cloneElement(child)}
                </div>
            ))}
        </div>
    );
}

function Title({ children }) {
    return (<h3 className="card-title">{children}</h3>)
}

function Buttons({ children }) {
    return (
        <div className="card-btn-group btn-group">{children}</div>
    )
}

function Header({ children}) {
    return (
        <div className={"card-heading"}>
            {children}
        </div>
    );
}

function CollapsibleHeader({ children, disabled }) {
    const [isOpen, setIsOpen] = React.useState(true);

    return (<div className={`card-heading${disabled ? '' : ' collapsible cursorpointer'}${isOpen ? '' : ' collapse'}`} onClick={() => setIsOpen(!isOpen)}>
        {<div className="mr-16"><span className="caret"></span></div>}
        {children}
    </div>
    );
}

function Content({ children }) {
    return (
    <div className="card-body p-24">
        {React.Children.map(children, (child) => child && React.cloneElement(child))}
    </div>)
}

function Footer({ children }) {

    return (
        <div className="skynet-card-footer">
            {children}
        </div>
    );
}

Card.Header = Header;
Card.Header.Title = Title;
Card.Header.Buttons = Buttons;
Card.CHeader = CollapsibleHeader;
Card.Content = Content;
Card.Footer = Footer;
Card.Footer.Buttons = Buttons;

export default function Card({ children }) {
    return ( 
        <div className={`card rounded-8 shadow-10`}>
            {children}
        </div>
    );
}

export function DefaultCard({ title, children}) {
    return <Card>
        <Card.CHeader>
            <Card.Header.Title>{title}</Card.Header.Title>
        </Card.CHeader>
        <Card.Content>
            {children}
        </Card.Content>
    </Card>
}