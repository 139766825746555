export const RegexLib = {
    'email': '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$',
    'postalCode': '^[0-9]{5}$',
    'non0': '[^0]+',
    'organizationNumber': '^(([0-9]{10}$)|([0-9]{6}[-]{1}[0-9]{4}$))',
    'internalEmail': '^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+.)?[a-zA-Z]+.)?(visma).com$',
    'onlyNumbers': '^[0-9]+([.,][0-9]+)?$',
    'lessThan100': '^([0-9]|[1-9][0-9]|100)$'
}
export const VossCustomerForm = [
    { inputId: 'name', rule: 'required', message: 'Company name is required' }
]

export const VonEmployeeCheckForm = [
    { inputId: 'email', rule: RegexLib.email, message: 'Incorrect email address format' },
    { inputId: 'email', rule: 'required', message: 'Incorrect email address format' }
]

export const CustomerForm = [
    { inputId: 'group', rule: RegexLib.non0, message: 'Customer group is required' },
    { inputId: 'type', rule: RegexLib.non0, message: 'Customer type is required' }
];

export const CustomerAddressForm = [
    { inputId: 'streetAddress', rule: 'required', message: 'Street address is required' },
    { inputId: 'postalCode', rule: 'required', message: 'Postal code is required' },
    { inputId: 'postalCode', rule: RegexLib.postalCode, message: 'Postal code should be in 5 digits format' },
    { inputId: 'postalCity', rule: 'required', message: 'Postal city is required' }
];

export const CustomerSearchForm = [
    { inputId: 'organizationNumber', rule: RegexLib.organizationNumber, message: 'Organization number should be in 10 digits format' }
]

export const CreateVonAccount = [
    { inputId: 'firstName', rule: 'required', message: 'First name is required' },
    { inputId: 'lastName', rule: 'required', message: 'Last name is required' },
    { inputId: 'emailAddress', rule: 'required', message: 'Email address is required' },
    { inputId: 'contactPerson', rule: 'required', message: 'Contact person is required' },
    { inputId: 'streetAddress', rule: 'required', message: 'Street address is required' },
    { inputId: 'postalCode', rule: 'required', message: 'Postal code is required' },
    { inputId: 'postalCity', rule: 'required', message: 'Postal city is required' },
    { inputId: 'phoneNumber', rule: 'required', message: 'Phone number is required' },
    { inputId: 'emailAddress', rule: RegexLib.email, message: 'Incorrect email address format' },
]

export const NewLeadsForm = [
    { inputId: 'leadType', rule: 'required', message: 'Lead type is required' },
    { inputId: 'leadAmount', rule: RegexLib.onlyNumbers, message: 'Lead amount is required' }
]

export const NewDiscountForm = [
    { inputId: 'discountAgreementId', rule: 'required', message: 'Discount is required' },
    { inputId: 'validToDate', rule: 'required', message: 'Valid date is required' }
]

export const AppointmentForm = [
    { inputId: 'contactPerson', rule: 'required', message: 'Contact person is required' },
    { inputId: 'phoneNumber', rule: 'required', message: 'Phone number is required' },
    { inputId: 'emailAddress', rule: 'required', message: 'Email address is required' },
    { inputId: 'emailAddress', rule: RegexLib.email, message: 'Incorrect email address format' },
    { inputId: 'time', rule: 'required', message: 'Time for appointment is required' },
    { inputId: 'assignee', rule: 'required', message: 'Valid Assign to is required' },
    { inputId: 'subject', rule: 'required', message: 'Subject is required' }
]

export const EmailTemplate = [
    { inputId: 'to', rule: RegexLib.email, message: 'Incorrect email address format for "To"' },
    { inputId: 'from', rule: 'required', message: '"From" is required' },
    { inputId: 'subject', rule: 'required', message: '"Subject" is required' },
    { inputId: 'body', rule: 'required', message: '"Body" is required' },
]

export const VceTemplate = [
    { inputId: 'to', rule: 'required', message: 'Email adress is required' },
    { inputId: 'to', rule: RegexLib.email, message: 'Incorrect email address format for "To"' },
    { inputId: 'body', rule: 'required', message: '"Body" is required' },
]

export const InternalLead = [
    { inputId: 'customerNumber', rule: 'required', message: 'Customer number is required' },
    { inputId: 'phoneNumber', rule: 'required', message: 'Phone number is required' },
    { inputId: 'contactPerson', rule: 'required', message: 'Contact person is required' },
    { inputId: 'contactEmail', rule: 'required', message: 'Email adress is required' },
    { inputId: 'contactEmail', rule: RegexLib.email, message: 'Incorrect email address format' },
    { inputId: 'body', rule: 'required', message: 'Description is required' }
]

export const ExternalLead = [
    { inputId: 'customerNumber', rule: 'required', message: 'Customer number is required' },
    { inputId: 'customerName', rule: 'required', message: 'Customer name is required' },
    { inputId: 'phoneNumber', rule: 'required', message: 'Phone number is required' },
    { inputId: 'contactPerson', rule: 'required', message: 'Contact person is required' },
    { inputId: 'contactEmail', rule: 'required', message: 'Email adress is required' },
    { inputId: 'contactEmail', rule: RegexLib.email, message: 'Incorrect email address format' },
    { inputId: 'body', rule: 'required', message: 'Description is required' },
    { inputId: 'selectedExternalPartner', rule: 'required', message: 'External partner required' }
]

export const OrderForm = [
    { inputId: 'yourReference', rule: 'required', message: '"Name" is required' },
    { inputId: 'emailAddress', rule: 'required', message: '"Confirmation Email" is required' }
]

export const CancelAgreement = [
    { inputId: 'cancellationDate', rule: 'required', message: '"Cancellation Date" is required' },
    { inputId: 'reference', rule: 'required', message: '"Cancelled by" is required' },
    { inputId: 'reasonCode', rule: 'required', message: '"Reason" is required' },
    { inputId: 'reasonCode', rule: RegexLib.non0, message: '"Reason" is required' },
    { inputId: 'to', rule: 'required', message: '"To" is required' },
    { inputId: 'from', rule: 'required', message: '"From" is required' },
    { inputId: 'subject', rule: 'required', message: '"Subject" is required' },
    { inputId: 'body', rule: 'required', message: '"Body" is required' }

]

export const CancelSubscription = [
    { inputId: 'terminationType', rule: 'required', message: '"Time of termination" is required' },
    { inputId: 'reason', rule: 'required', message: '"Reason" is required' },
    { inputId: 'to', rule: 'required', message: '"To" is required' },
    { inputId: 'from', rule: 'required', message: '"From" is required' },
    { inputId: 'subject', rule: 'required', message: '"Subject" is required' },
    { inputId: 'body', rule: 'required', message: '"Body" is required' },
]

export const ConsultingForm = [
    { inputId: 'reference', rule: 'required', message: "Reference is required" },
    { inputId: 'attendeeEmail', rule: RegexLib.email, message: 'Incorrect email address format' }
]

export const LeadForm = [
    { inputId: 'email', rule: RegexLib.email, message: "Incorrect email address format" },
    { inputId: 'type', rule: 'required', message: '"Type" is required' }
]

export const AoLeadForm = [

]

export const LeadPhoneModal = [
    { inputId: 'reasonId', rule: 'required', message: '"Reason" is required' }
]

export const LeadAddInfoModal = [
    { inputId: 'notes', rule: 'required', message: ' is required' }
]

export const AoLeadBuyForm = [
    { inputId: 'amount', rule: 'required', message: '"Amount" is required' }
]

export const EducationForm = [
    { inputId: 'invoiceCustomerNumber', rule: 'required', message: 'Invoice customer number is required' },
    { inputId: 'contactFirstName', rule: 'required', message: 'Contact first name is required' },
    { inputId: 'contactLastName', rule: 'required', message: 'Contact last name is required' },
    { inputId: 'contactMobilenumber', rule: 'required', message: 'Contact mobile number is required' },
    { inputId: 'contactEmail', rule: 'required', message: 'Email address is required' },
    { inputId: 'contactEmail', rule: RegexLib.email, message: 'Incorrect email address format' },
    { inputId: 'participantFirstName', rule: 'required', message: 'Participant first name is required' },
    { inputId: 'participantLastName', rule: 'required', message: 'Participant last name is required' },
    { inputId: 'participantMobilenumber', rule: 'required', message: 'Participant mobile number is required' },
    { inputId: 'participantEmail', rule: 'required', message: 'Email address is required' },
    { inputId: 'participantEmail', rule: RegexLib.email, message: 'Incorrect email address format' },
    { inputId: 'allergiesChecked', rule: 'required', message: 'Alergies option is required' },
    { inputId: 'salesNumber', rule: 'required', message: 'Sales number is required' }
];

export const AllergiesRule = { inputId: 'additionalInfo', rule: 'required', message: 'Fill in allergies in the additional info field' };

export const InternalCustomerCreateForm = [
    { inputId: 'firstName', rule: 'required', message: 'First name is required' },
    { inputId: 'lastName', rule: 'required', message: 'Last name is required' },
    { inputId: 'companyName', rule: 'required', message: 'Company name is required' },
    { inputId: 'removalTime', rule: 'required', message: 'Valid to is required' },
    { inputId: 'emailAddress', rule: RegexLib.internalEmail, message: 'Valid email address must end in @visma.com' }
];

export const ChangeSaasPeriod = [
    { inputId: 'to', rule: 'required', message: '"To" is required' },
    { inputId: 'from', rule: 'required', message: '"From" is required' },
    { inputId: 'subject', rule: 'required', message: '"Subject" is required' },
    { inputId: 'body', rule: 'required', message: '"Body" is required' }
]

export const AppointmentCloseModal = [
    { inputId: 'reason', rule: 'required', message: '"Reason" is required' }
]

export const WebinarCreateForm = [
    { inputId: 'title', rule: 'required', message: '"Title" is required' },
    { inputId: 'targetGroup', rule: 'required', message: '"Target group" is required' },
]

export const editWebinar = [
    { inputId: 'title', rule: 'required', message: '"Title" is required' },
    { inputId: 'targetGroup', rule: 'required', message: '"Target group" is required' },
]

export const BokaMeraForm = [
    { inputId: 'email', rule: RegexLib.email, message: '"Email" is incorrect' },
    { inputId: 'email', rule: 'required', message: '"Email" is required' },
    { inputId: 'contactPerson', rule: 'required', message: '"Contact person" is required' },
    { inputId: 'phone', rule: 'required', message: '"Phone number" is required' },
    { inputId: 'Kommentar', rule: 'required', message: '"Case description is required' },
    { inputId: 'informedCustomer', rule: 'required', message: '"You have to inform the customer about GDPR for BokaMera"' }
]

export const CompanyExpensePartnerCreateForm = [
    { inputId: 'name', rule: 'required', message: '"Name" is required' },
    { inputId: 'customerNumber', rule: 'required', message: '"Customer Number" is required' },
    { inputId: 'email', rule: 'required', message: '"Email" is required' },
    { inputId: 'firstName', rule: 'required', message: '"First Name" is required' },
    { inputId: 'lastName', rule: 'required', message: '"Last Name" is required' }
]

export const CompanyExpensePartnerEditForm = [
    { inputId: 'name', rule: 'required', message: '"Name" is required' },
    { inputId: 'customerNumber', rule: 'required', message: '"Customer Number" is required' }
]

export const CompanyExpensePartnerAdminCreateForm = [
    { inputId: 'lastName', rule: 'required', message: '"Last Name" is required' },
    { inputId: 'firstName', rule: 'required', message: '"First Name" is required' },
    { inputId: 'email', rule: 'required', message: '"Email" is required' }
]

export const CompanyExpenseAdminCreateForm = [
    { inputId: 'email', rule: 'required', message: '"Email" is required' }
]

export const CompensationContactCustomer = [
    { inputId: 'compensationReason', rule: 'required', message: '"Compensation reason" is required' },
    { inputId: 'salesforceTicketLink', rule: 'required', message: '"Salesforce ticket link" is required' },
    { inputId: 'to', rule: 'required', message: '"To" is required' },
    { inputId: 'from', rule: 'required', message: '"From" is required' },
    { inputId: 'subject', rule: 'required', message: '"Subject" is required' },
    { inputId: 'body', rule: 'required', message: '"Body" is required' },
    { inputId: 'compensationComment', rule: 'required', message: '"Comment" is required' }

]

export const CompensationAllFields = [
    { inputId: 'compensationReason', rule: 'required', message: '"Compensation reason" is required' },
    { inputId: 'salesforceTicketLink', rule: 'required', message: '"Salesforce ticket link" is required' },
    { inputId: 'to', rule: 'required', message: '"To" is required' },
    { inputId: 'from', rule: 'required', message: '"From" is required' },
    { inputId: 'subject', rule: 'required', message: '"Subject" is required' },
    { inputId: 'body', rule: 'required', message: '"Body" is required' },
    { inputId: 'compensationMonth', rule: 'required', message: '"Months" is required' },
    { inputId: 'compensationComment', rule: 'required', message: '"Comment" is required' }
]

export const settingsCompensationTicket = [
    { inputId: 'sum', rule: 'required', message: '"Sum" is required' },
    { inputId: 'compensationReasonId', rule: 'required', message: '"Compensation reason" is required' }
]

export const settingsCompensationTicketAllFields = [
    { inputId: 'sum', rule: 'required', message: '"Sum" is required' },
    { inputId: 'compensationResultId', rule: 'required', message: '"Compensation result" is required' },
    { inputId: 'compensationReasonId', rule: 'required', message: '"Compensation reason" is required' },
    { inputId: 'compensationSubResultId', rule: 'required', message: '"Compensation sub result" is required' }
]

export const QuotationFaqForm = [
    { inputId: 'question', rule: 'required', message: '"Question" is required' },
    { inputId: 'answer', rule: 'required', message: '"Answer" is required' }
]


export const InvoiceCreditForm = [
    { inputId: 'invoiceCreditReason', rule: 'required', message: '"Reason" is required' },
    { inputId: 'invoiceCreditReason', rule: RegexLib.non0, message: '"Reason" is required' },
    { inputId: 'invoiceCreditComment', rule: 'required', message: '"Comment" is required' },
    { inputId: 'to', rule: 'required', message: '"To" is required' },
    { inputId: 'subject', rule: 'required', message: '"Subject" is required' }
]

export const InvoiceCreditFormWithSecondReason = [
    { inputId: 'invoiceCreditReason', rule: 'required', message: '"Reason" is required' },
    { inputId: 'invoiceCreditReason', rule: RegexLib.non0, message: '"Reason" is required' },
    { inputId: 'invoiceCreditReason2', rule: 'required', message: '"Reason 2" is required' },
    { inputId: 'invoiceCreditReason2', rule: RegexLib.non0, message: '"Reason 2" is required' },
    { inputId: 'invoiceCreditComment', rule: 'required', message: '"Comment" is required' },
    { inputId: 'to', rule: 'required', message: '"To" is required' },
    { inputId: 'subject', rule: 'required', message: '"Subject" is required' }
]

export const InvoiceCreditFormPartialCredit = [
    { inputId: 'invoiceCreditReason', rule: 'required', message: '"Reason" is required' },
    { inputId: 'invoiceCreditReason', rule: RegexLib.non0, message: '"Reason" is required' },
    { inputId: 'invoiceCreditComment', rule: 'required', message: '"Comment" is required' },
    { inputId: 'to', rule: 'required', message: '"To" is required' },
    { inputId: 'subject', rule: 'required', message: '"Subject" is required' },
    { inputId: 'sum', rule: 'required', message: '"Sum" is required' },
    { inputId: 'sum', rule: RegexLib.onlyNumbers, message: '"Sum" can only be numeric' }
]

export const InvoiceCreditFormPartialCreditWithSecondReason = [
    { inputId: 'invoiceCreditReason', rule: 'required', message: '"Reason" is required' },
    { inputId: 'invoiceCreditReason', rule: RegexLib.non0, message: '"Reason" is required' },
    { inputId: 'invoiceCreditReason2', rule: 'required', message: '"Reason 2" is required' },
    { inputId: 'invoiceCreditReason2', rule: RegexLib.non0, message: '"Reason 2" is required' },
    { inputId: 'invoiceCreditComment', rule: 'required', message: '"Comment" is required' },
    { inputId: 'to', rule: 'required', message: '"To" is required' },
    { inputId: 'subject', rule: 'required', message: '"Subject" is required' },
    { inputId: 'sum', rule: 'required', message: '"Sum" is required' },
    { inputId: 'sum', rule: RegexLib.onlyNumbers, message: '"Sum" can only be numeric' }
]

export const quotationCustomer = [
    { inputId: 'orgNr', rule: 'required', message: '"Org Number" is required' },
    { inputId: 'emailAddress', rule: 'required', message: '"Email Address" is required' }
]

export const convertQuotationForm = [
    { inputId: 'customerNumber', rule: 'required', message: '"Customer number" is required' },
    { inputId: 'customerNumber', rule: RegexLib.non0, message: '"Customer number" can not be 0' }
]

export const AoCustomerInfo = [
    { inputId: 'customerNumber', rule: 'required', message: '"Customernumber" is required' },
]

export const SendMovieMail = [
    { inputId: 'to', rule: RegexLib.email, message: 'Incorrect email address format' },
    { inputId: 'to', rule: 'required', message: 'Incorrect email address format' },
    { inputId: 'subject', rule: 'required', message: '"Subject" is required' },
    { inputId: 'text', rule: 'required', message: '"Body" is required' }
]

export const EditMovieMail = [
    { inputId: 'name', rule: 'required', message: '"Name" is required' },
    { inputId: 'link', rule: 'required', message: '"Link" is required' },
    { inputId: 'description', rule: 'required', message: '"Description" is required' }
]

export const CreateFileBoxTicket = [
    { inputId: 'ticketId', rule: 'required', message: '"Ticket Id" is required' },
    { inputId: 'teamId', rule: 'required', message: '"Owner" is required' },
    { inputId: 'customerNumber', rule: 'required', message: '"Customer Number" is required' },
    { inputId: 'customerName', rule: 'required', message: '"Customer Name" is required' },
    { inputId: 'email', rule: RegexLib.email, message: '"E-mail" is incorrect' },
    { inputId: 'phone', rule: 'required', message: '"Phone Number" is required' }
]

export const CreateDiscount = [
    { inputId: 'name', rule: 'required', message: '"Rabattavtalets namn" is required' },
    { inputId: 'salesPersonEmail', rule: RegexLib.email, message: 'Incorrect email address format', require: true },
    { inputId: 'discountType', rule: 'required', message: '"Rabattsats" is required'}
];

export const UpdateDiscount = [
    { inputId: 'name', rule: 'required', message: '"Rabattavtalets namn" is required' },
    { inputId: 'salesPersonEmail', rule: RegexLib.email, message: 'Incorrect email address format', require: true }
];

export const CreateCustomerCategory = [
    {
        rule: 'multiple', fields: [
            { inputId: 'lastIncludedCustomerCategory' },
            { inputId: 'firstIncludedCustomerCategory' }
        ], message: '"Kundkategori" is required'
    },
    {
        rule: 'multiple', fields: [
            { inputId: 'validFromDate' },
            { inputId: 'validToDate' }
        ], message: '"Giltighetstid" is invalid'
    },
    { inputId: 'notifyEmailAddress', rule: RegexLib.email, message: '"E-postadress för avisering" is required' }
];