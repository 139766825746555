const routes = {
    customer: (customerNumber) => `/customer/${customerNumber}/*`,
    customerMain: (customerNumber) => `/customer/${customerNumber}/main`,
    customerDetails: (customerNumber) => `/customer/${customerNumber}/details`,
    customerEdit: (customerNumber) => `/customer/${customerNumber}/edit`,
    customerAgreements: (customerNumber) => `/customer/${customerNumber}/agreements`,
    customerVonAccounts: (customerNumber) => `/customer/${customerNumber}/von-accounts`,
    customerCollaborations: (customerNumber) => `/customer/${customerNumber}/collaborations`,
    customerDiscounts: (customerNumber) => `/customer/${customerNumber}/discounts`,
    customerVossDiscounts: (customerNumber) => `/customer/${customerNumber}/voss/discounts`,
    customerNewVonAccount: (customerNumber) => `/customer/${customerNumber}/von-accounts/new`,
    customerNewVonEmployee: (customerNumber) => `/customer/${customerNumber}/von-accounts/new-employee`,
    customerEditVonEmployee: (customerNumber, id) => `/customer/${customerNumber}/von-accounts/edit-employee/${id}`,
    customerNewAppointment: (customerNumber) => `/customer/${customerNumber}/new-appointment`,
    customerAppointments: (customerNumber) => `/customer/${customerNumber}/appointments`,
    customerAppointmentDetails: (customerNumber, appointmentId) => `/customer/${customerNumber}/appointment/${appointmentId}`,
    customerAdminForms: (customerNumber) => `/customer/${customerNumber}/admin-forms`,
    customerInvoices: (customerNumber) => `/customer/${customerNumber}/invoices`,
    customerBookEducation: (customerNumber, courseId) => `/customer/${customerNumber}/bookeducation/${courseId}`,
    customerActions: (customerNumber) => `/customer/${customerNumber}/customeractions`,
    customerAttendedEducations: (customerNumber) => `/customer/${customerNumber}/attended-educations`,
    educations: (customerNumber) => `/customer/${customerNumber}/educations`,
    courses: (customerNumber) => `/customer/${customerNumber}/educations/courses`,
    attendedCourses: (customerNumber) => `/customer/${customerNumber}/attended-educations/courses`,
    attendedOnboardings: (customerNumber) => `/customer/${customerNumber}/attended-educations/onboardings`,
    sentMovies: (customerNumber) => `/customer/${customerNumber}/attended-educations/sent-movies`,
    course: (customerNumber, courseId, eventId) => `/customer/${customerNumber}/educations/course/${courseId}/${eventId}`,
    webinars: (customerNumber) => `/customer/${customerNumber}/educations/webinars`,
    onboarding: (customerNumber) => `/customer/${customerNumber}/educations/onboarding`,
    customerConsulting: (customerNumber) => `/customer/${customerNumber}/consulting`,
    customerSendDesktopVersion: (customerNumber) => `/customer/${customerNumber}/send-desktop-version`,
    customerLeads: (customerNumber) => `/customer/${customerNumber}/leads`,
    relatedCustomers: (customerNumber) => `/customer/${customerNumber}/related-customers`,
    customerCompensation: (customerNumber) => `/customer/${customerNumber}/compensation`,
    customerCampaign: (customerNumber) => `/customer/${customerNumber}/campaigns`,
    orderNew: (customerNumber) => `/customer/${customerNumber}/order`,
    orderPlacement: (customerNumber) => `/customer/${customerNumber}/order-placement`,
    inyettdetails: (customerNumber) => `/customer/${customerNumber}/inyettdetails`,
    appointments: '/my-appointments',
    appointmentsNew: '/my-appointments/new',
    appointmentDetails: (appointmentId) => `/my-appointments/appointment/${appointmentId}`,
    appointmentsMySettings: '/my-appointments/my-settings',
    orderConfirmationEmail: (customerNumber, orderId) => `/customer/${customerNumber}/orderconfirmationmail/${orderId}`,
    emailHistory: (emailActionLogId) => `/emailhistory/${emailActionLogId}`,
    search: (term) => `/search/${term}`,
    searchMain: '/search/',
    editSignature: '/edit-signature',
    settings: '/settings/',
    settingsGuidedSelling: '/settings/guided-selling',
    settingsGuidedSellingEdit: (id) => `/settings/guided-selling/edit/${id || ':id'}`,
    settingsEmailTemplates: '/settings/email-templates',
    settingsEmailTemplateEdit: (id) => `/settings/email-template/edit/${id || ':id'}`,
    settingsLeadLists: '/settings/lead-lists',
    internalCustomers: '/internal-customers',
    settingsCustomerInfoTypes: '/settings/customerinfotypes',
    settingsCustomerInfoTypeEdit: (customerNumber) => `/settings/customerinfotypes/edit/${customerNumber || ':customerNumber'}`,
    settingsCompensation: '/settings/compensation',
    settingsCompensationEdit: (id) => `/settings/compensation/edit/${id || ':id'}`,
    settingsQuotationFaq: '/settings/quotationFaq',
    hittaByra: '/settings/hittabyra',
    hittaByraEdit: (id) => `/settings/hittabyra/edit/${id || ':id'}`,
    hittaByraAddDuplicate: (id) => `/settings/hittabyra/addduplicate/${id || ':id'}`,
    hittaPartner: '/settings/hittapartner',
    hittaPartnerEdit: (id) => `/settings/hittapartner/${id || ':id'}`,
    settingsAdminUsers: '/settings/adminusers',
    settingsAdminUsersEdit: (id, type) => `/settings/adminusers/${type || ':type'}/${id || ':id'}`,
    budget: '/settings/budget',
    editBudget: (id, type) => `/settings/editbudget/${id || ':id'}/${type || ':type'}`,
    settingsWorkerJobs: '/settings/worker-jobs',
    settingsFeatureToggles: '/settings/featuretoggles',
    logout: '/logout',
    login: '/login',
    leads: '/leads/',
    internalLeads: '/internal-leads',
    aoleadDetails: (leadId) => `/internal-leads/ao-leads/${leadId}`,
    aoleadEdit: (leadId) => `/internal-leads/ao-leads/edit/${leadId}`,
    internalLeadDetails: (leadId) => `/internal-leads/${leadId}`,
    internalLeadEdit: (leadId) => `/internal-leads/edit/${leadId}`,
    leadDetails: (leadId) => `/leads/${leadId}`,
    leadEdit: (leadId) => `/leads/edit/${leadId}`,
    leadsMySettings: '/leads/my-settings',
    myActions: '/my-actions',
    quotation: '/quotation',
    myAppointments: '/my-appointments',
    newCustomerForm: '/new-customer/form',
    newCustomer: '/new-customer',
    createCustomerForm: '/create-customer/form',
    createCustomer: '/create-customer',
    status: '/status',
    editCustomerLayout: '/edit-layout',
    myStats: '/mystats',
    editOnboarding: (customerNumber, onboardingId) => `/customer/${customerNumber}/attended-educations/editonboarding/${onboardingId}`,
    productOverview: '/productoverview',
    editProductVersions: '/settings/editproductversions',
    orderApprovalRequests: '/settings/orderapprovalrequests',
    orderApprovalRequestsEdit: (id) => `/settings/orderapprovalrequests/${id || ':id'}`,
    versionArchive: '/version-archive',
    releaseNews: '/settings/release-news',
    releaseNewsEdit: (id) => `/settings/release-news-edit/${id}`,
    eloqua: "/settings/eloqua",
    bokameraadmin: "/settings/bokameraadmin",
    versionArchiveDetails: (versionId) => `/version-archive/${versionId}`,
    customerDirectoryContainer: `/customer-directory/`,
    customerDirectoryContainerEdit: (customerId) => `/customer-directory/${customerId}`,
    createFileBoxTicket: '/createfileboxticket',
    fileBoxTicketDetails: (ticketId) =>  `/filebox/${ticketId || ':ticketId'}`,
    discounts: "/discounts/",
    createNewDiscount: `/discounts/createnewdiscount/`,
    editDiscount: `/discounts/editDiscount/`,
    //Internal Customer Admin routes
    icaTerminationReasons: "/settings/internal-customer-admin/termination-reasons",
    icaTerminationReasonsCreate: "/settings/internal-customer-admin/termination-reasons/create",
    icaTerminationReasonsEdit: `/settings/internal-customer-admin/termination-reasons/edit/`,
    icaLog: "/settings/internal-customer-admin/log",
    icaCustomerLog : (customerNumber) => `/customer/${customerNumber}/log`,
}

export default routes;