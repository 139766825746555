import { internalCustomerAdminActionTypes as actionTypes } from "../../ActionTypes/InternalCustomerAdmin/internalCustomerAdminActionTypes";
import { initialState } from "../CommonReducer";

export default function InternalCustomerAdminReducer(state = {initialState}, action) {

    let newState;

    switch (action.type) {
        case actionTypes.STORE_TERMINATION_REASONS:
            newState = { terminationReasons: action.data }
            break;
        case actionTypes.STORE_SERVICES:
            newState = { services: action.data }
            break;
        case actionTypes.STORE_ACTIVITY_LOGS:
            newState = { activityLogs: action.data }
            break;
        default:
            return state;
    }

    if (newState)
        return Object.assign({}, state, newState)

    return state;
}