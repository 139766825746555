import Card from "./Card";
import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../Loader";

export default function EditCard({ title, children, actions, headerContent, formContent, allowEdit }) {
    const { t } = useTranslation();
    const formRef = useRef(null);
    const [isEditing, setIsEditing] = useState(false);

    function editAction(event) {
        event.stopPropagation();
        /*
        setIsEditing(true);

        if (actions && actions.editAction) {
            actions.editAction()
        }*/
    }

    function cancelAction() {
        /*
        formRef.current.reset();
        setIsEditing(false);

        if (actions && actions.cancelAction) {
            actions.cancelAction();
        }*/
    }

    function saveAction() {
        /*
        setIsEditing(false);

        if (actions && actions.saveAction) {
            actions.saveAction();
        }*/
    }
    
    return (
        <Card>
            <Card.CHeader disabled={isEditing}>
                <Card.Header.Title>{title}</Card.Header.Title>    
                {headerContent}
                {/*Card.Header.Buttons><button type="button" className="btn btn-sm btn-default" disabled={isEditing} onClick={editAction}>{t('common.edit')}</button></Card.Header.Buttons>*/}
            </Card.CHeader>
            <Card.Content>
                <form className="skynet form" ref={formRef}>
                    {React.cloneElement(formContent, {isEditing: isEditing,})}
                </form>
                {children}
            </Card.Content>
            {isEditing && <Card.Footer>
                <Card.Footer.Buttons>
                    {<>
                        <Loader />
                        <button type="button" className="btn btn-default" onClick={cancelAction}>{t('common.cancel')}</button>
                        <button type="button" className="btn btn-primary" onClick={saveAction}>{t('common.save')}</button>
                    </>}
                </Card.Footer.Buttons>
            </Card.Footer>
            }
        </Card>
    );
}
