import { Typeahead } from "react-bootstrap-typeahead";
import Card from "../../../Card/Card";
import Checkbox from "../../../Checkbox";
import { useTranslation } from "react-i18next";

function VossTierOptions({ product, onChange, intermediaries }) {
    const { t } = useTranslation();
    
    const handleProductGroupChange = (productGroup, isChecked) => {
        if (isChecked) {
            onChange("optionalProductGroups", [
                ...product.priceCalculation.optionalProductGroups,
                productGroup
            ]);
        } else {
            onChange("optionalProductGroups", product.priceCalculation.optionalProductGroups.filter(x => x.id != productGroup.id));
        }
    };

    var tier = product.offer.tiers.find(tier => tier.id === product.priceCalculation.tierId);
    var selectableProductGroups = tier.productGroups.filter(productGroup => !productGroup.isIncluded);
    var selectablePlans = product.offer.plans.filter(plan => tier.applicablePlans.includes(plan.id));

    return <div className='d-flex flex-column' style={{ gap: "24px" }}>
        <div>
            <h3 className="font-family-ubuntu-semibold mb-12">{t("vosstieroptions.plan")}</h3>
            <div className='d-flex flex-column' style={{ gap: "12px" }}>
                {selectablePlans.map((plan, index) => (
                    <div key={index} className="radio mb-0">
                        <input type="radio" name="plan" value={plan.id} id={index} checked={plan.id === product.priceCalculation.planId} onChange={() => onChange("planId", plan.id)} />
                        <label htmlFor={index}>{plan.name}</label>
                    </div>
                ))}
            </div>
        </div>
        {selectableProductGroups.length > 0 && <div>
            <h3 className="font-family-ubuntu-semibold mb-12">{t("vosstieroptions.addons")}</h3>
            <div className='d-flex flex-column' style={{ gap: "12px" }}>
                {selectableProductGroups
                    .map((productGroup, index) => (
                        <div key={index} className="mb-0">
                            <Checkbox
                                label={productGroup.name}
                                checked={product.priceCalculation.optionalProductGroups?.some(pg => pg.id === productGroup.id)}
                                onChange={(e) => handleProductGroupChange(productGroup, e.checked)}
                            />
                        </div>))}
            </div>
        </div>}
        {intermediaries && <div>
            <h3 className="font-family-ubuntu-semibold mb-12">{t("vosstieroptions.intermediary")}</h3>
            <IntermediarySelector intermediaries={intermediaries} onChange={onChange} product={product} />
        </div>}
    </div>
};

function IntermediarySelector({ intermediaries, onChange, product }) {

    const selectIntemediary = (key, value) => {
        if (value.length) {
            onChange(key, value[0].id);
        }
    }

    const attributes = {
        labelKey: option => `${option.name + " - " + option.invoicingId}`,
        options: intermediaries,
        placeholder: "Välj förmedlare/partner...",
        className: "form-group search-group search-group-button px-0 mb-0",
        onChange: (selected) => selectIntemediary("intermediaryId", selected),
        defaultSelected: intermediaries.filter(x => x.id === product.priceCalculation.intermediaryId)
    };

    return <Typeahead {...attributes} />
}

export function VossTierOptionsCard({...props}) {
    return <Card>
        <Card.Header>
            <Card.Header.Title>Tier Options</Card.Header.Title>
        </Card.Header>
        <Card.Content>
            <VossTierOptions {...props} />
        </Card.Content>
    </Card>
}