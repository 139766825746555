import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import customerActions from '../../../Actions/customerActions'
import { useTranslation, withTranslation } from 'react-i18next';
import Subscriptions from "../../../Components/Products/Subscriptions";
import SubscriptionCard from "../../../Components/Card/SubscriptionCard";
import InactiveProducts from "../../../Components/Products/InactiveProducts";
import helpers from "../../../Utils/helpers";
import WithModalForm from "../../../Components/HOC/withModalForm";
import {SubscriptionTerminationModal} from "../../../Components/Products/SubscriptionTermination";

function SubscriptionContainer({ customer, customerActions, onOpenModal, isModalActive, reasons, terminationForm, onCloseClick, onFieldChange, payload: subscription }) {
    const { t } = useTranslation();

    const renderEndDate = (product) => {
        return helpers.formatDate(product.termination?.scheduledDate)
    }

    const renderPurchaseDate = (product) => {
            return helpers.formatDate(product.created)
    }

    const updateTerminationEmail = (callback) => {
        customerActions.updateVossTerminationEmailTemplate(terminationForm, subscription, callback);
    }

    const renderTermination = (product) => {
        let reason = product.termination?.reason;
        if(!reason){
            return "-"
        }
        return `${reason.reasonName} ${reason.comment ? `(${reason.comment})` : ""}`
    }

    const terminationModalAttr = {
        form: terminationForm,
        subscription: subscription,
        reasons: reasons,
        onChange: onFieldChange,
        onClose: onCloseClick,
        onNextClick: (callback) => updateTerminationEmail(callback),
        onLoad: () => customerActions.getVossTerminationEmailTemplate(subscription),
        onSave: () => customerActions.cancelSubscription(terminationForm, subscription, () => onCloseClick(terminationForm.id))
    }

    return <>
        <SubscriptionCard
            title={t("subscriptionscontainer.header")}
            activeContent={<> <Subscriptions subscriptions={customer.vossProducts.filter((sub) => sub.status === 0)} onButtonClick={onOpenModal} /></>}
            inactiveContent={<InactiveProducts products={customer.vossProducts.filter((sub) => sub.status > 0)} getEndDate={renderEndDate} getPurchaseDate={renderPurchaseDate} getTermination={renderTermination} getProductName={(item) => item.package.name} /> }
        >
        </SubscriptionCard>
        {isModalActive('terminate') && <SubscriptionTerminationModal {...terminationModalAttr} />}
    </>
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.selectedCustomer,
        pendingTerminationRequests: state.CustomerReducer.pendingTerminationRequests,
        terminationForm: state.CommonReducer.forms && state.CommonReducer.forms.CancelVossSubscriptions,
        reasons: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists['SubscriptionTerminationReasons'],
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
    }
}

export default WithModalForm(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SubscriptionContainer)));