import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';
import commonActions from '../Actions/commonActions';
import ReactModal from 'react-modal';

function ConfirmModal({commonActions, showModal, confirmTitle, confirmTextElement, onConfirmed}) {

    const handleModalConfirm = (e) => {
        if(e.target.value === 'true'){
            onConfirmed();
        }
        
        commonActions.showModal(false);
        return;
    }


  return (
    <ReactModal isOpen={showModal} style={
        {overlay: {
            backgroundColor: "transparent",
          }
        }
      } ariaHideApp={false} className='modalCenter modal fade in' >
        <div className='smallModal'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h4 className='modal-title'>{confirmTitle}</h4>
            </div>
            <div className='modalContent modal-body'>
              {confirmTextElement}
              <div className='modalBtns'>
                <button className='btn btn-primary no-margin' value={true} onClick={(e) => handleModalConfirm(e)}>Confirm</button>
                <button className='btn btn-default no-margin' value={false} onClick={(e) => handleModalConfirm(e)}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
  )
}

function mapStateToProps(state) {
    return {
      showModal: state.CommonReducer.showModal,
    }
}
  
function mapDispatchToProps(dispatch) {
    return {
      commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConfirmModal));