import {useTranslation, withTranslation} from "react-i18next";
import EditCard from "../../Components/Card/EditCard";
import {bindActionCreators} from "redux";
import customerActions from "../../Actions/customerActions";
import commonActions from "../../Actions/commonActions";
import {connect} from "react-redux";
import {InternalInfo} from "../../Components/Customer/CustomerComponents/InternalInfo";

function InternalInfoContainer({ customer, dropdownlists, changeEditInternalInfo, user }) {

    const { t } = useTranslation();

    async function onSaveClick(props) {
        const { commonActions, customerForm, isCreation, customerActions } = props;

        if (customerForm.data.mainCustomer == "" || (customerForm.data.subCustomers && customerForm.data.subCustomers.length > 0 && customerForm.data.subCustomers.every(i => i == ""))) {
            commonActions.showWarning("Main customer is either not selected, or every added sub customer is empty, select main customer or remove open sub customer fields. Please validate!");
            return;
        }
        else if (customerForm.data.group === 1001 && (customerForm.data.mainCustomer == null)) {
            commonActions.showWarning("Main customer is missing or isn't selected. Add main customer and try again. Please validate!");
            return;
        }

        await customerActions.saveCustomer(customerForm, isCreation)
        changeEditInternalInfo();
    }

    function onCustomerGroupChange(field, value) { }

    function formatGroupName(group) {
        if (group.id > 0) {
            return `${group.name} / ${group.id}`;
        }
    }


    function getActions() {
        const actions = {
            //editAction: () => toggleEditMode(),
            //cancelAction: () => toggleEditMode(),
            //saveAction: () => onSaveClick()
        }
        return actions;
    }

    return (<>
        <EditCard
            title={t("internalinfocontainer.header")}
            actions={getActions()}
            formContent={<InternalInfo customer={customer} dropdownlists={dropdownlists} user={user} />}
        >
        </EditCard>
    </>)
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.selectedCustomer,
        user: state.CommonReducer.user,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InternalInfoContainer));