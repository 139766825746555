import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomerSearch from '../Search/CustomerSearch';
import { withTranslation } from 'react-i18next';
import customerActions from '../../Actions/customerActions'
import commonActions from '../../Actions/commonActions'
import StaticTextInput from '../StaticTextInput';

export class ConnectedCustomersVon extends Component {

    onRemoveAction = (index, list, customer) => {
        const { onArrayChange, onDelete } = this.props;

        if(customer.id){
            onDelete("subCustomersToDelete", customer)
        }

        onArrayChange(list.field, list.customers, index, '-1')
    }

    onInputChange = (index, list, customerNumber, name) => {
        const { onArrayChange, id} = this.props;
        onArrayChange(list.field, list.customers, index, {customerNumber: customerNumber, name: name, organizationNumber: '', product: id})
    }

    onMainCustomerChange = (mainCustomer, customerNumber, name) => {
        const { onChange, id} = this.props;

        onChange(mainCustomer.field, mainCustomer = {customerNumber: customerNumber, name: name, organizationNumber: '', product: id})
    }

    onMainCustomerRemove = (mainCustomer) => {
        const { onChange, onDelete } = this.props;

        if(mainCustomer.customer && mainCustomer.customer.id){
            onDelete("mainCustomersToDelete", mainCustomer.customer)
        }

        onChange(mainCustomer.field, null)
    }

    onAddAction = (subCustomers) => {
        const { onArrayChange} = this.props;
        onArrayChange(subCustomers.field, subCustomers.customers, subCustomers.customers.length, '')  
    }

    onAddMainCustomer = (mainCustomer) => {
        const { onChange, id } = this.props;

        onChange(mainCustomer.field, '')
    }

    render() {
        const { subCustomers, mainCustomer, t } = this.props;

        return (
            <div>
                    <div className="maincustomer-search-container">
                    <h4><span className="vismaicon vismaicon-sm vismaicon-user" /> {t('connectedcustomersvon.maincustomer') + this.props.name} <small>{t('sublonsmartcustomercontainer.subheader')}</small></h4>
                    {mainCustomer.customer === null || mainCustomer === undefined ? (
                        <span className="vismaicon vismaicon-add-circle" onClick={this.onAddMainCustomer.bind(this, mainCustomer)} />
                    ) : (
                    
                    <CustomerSearch
                        readonly={mainCustomer.customer && typeof mainCustomer.customer === 'object'}
                        onChange={this.onMainCustomerChange.bind(this, mainCustomer)}
                        onRemoveAction={this.onMainCustomerRemove.bind(this, mainCustomer)}
                        defaultSelected={mainCustomer.customer && { name: mainCustomer.customer.name, customerNumber: mainCustomer.customer.customerNumber }}
                    />)
                    }
                    </div>
                    <div className="clearfix" />

                    <div className="subcustomer-search-container">
                        <h4><span className="vismaicon vismaicon-sm vismaicon-users" /> {t('connectedcustomersvon.subcustomers')  + this.props.name} <small>{t('sublonsmartcustomercontainer.subheader')}</small></h4>
                    {subCustomers.customers.map((subCustomer, index) => {
                        return <div className="subcustomer-search" key={index}>
                            <CustomerSearch
                                index={index}
                                readonly={subCustomer && typeof subCustomer === 'object'}
                                onChange={this.onInputChange.bind(this, index, subCustomers)}
                                onRemoveAction={this.onRemoveAction.bind(this, index, subCustomers, subCustomer)}
                                defaultSelected={{ name: subCustomer.name, customerNumber: subCustomer.customerNumber }}
                            />
                        </div>
                    })}
                    <div><span className="vismaicon vismaicon-add-circle" onClick={ this.onAddAction.bind(this, subCustomers) } /></div>
                </div>
            </div>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(null, mapDispatchToProps)(withTranslation()(ConnectedCustomersVon));
