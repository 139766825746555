import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import { Link } from 'react-router-dom'

import StaticTextInput from '../StaticTextInput'

import commonActions from '../../Actions/commonActions'

import BaseSearch from '../BaseSearch';  

class CustomerSearch extends Component {

    saveSearchHistory(customer){
        const storage = localStorage.getItem("CustomerSearchHistory");
        const history = storage ? JSON.parse(storage) : [];
        const customerSearchHistory = {
            customerNumber: customer.customerNumber,
            organizationNumber: customer.organizationNumber,
            name: customer.name
        }
    
        const index = history.findIndex(item => item.customerNumber === customer.customerNumber)
    
        if (index >= 0)
            history.splice(index, 1);
    
        if (history.length === 10)
            history.splice(0, 1);
    
        history.push(customerSearchHistory);
    
        localStorage.setItem("CustomerSearchHistory", JSON.stringify(history))
    }

    
    onChange(object) {
        const customerNumber = object.customerNumber || '';
        const customerName = object.name || '';
        const organizationNumber = object.organizationNumber || '';
        const group = object.group || '';

        this.props.onChange(customerNumber, customerName, organizationNumber, group);
        
        if(this.props.id === "headerCustomerSearch" && (customerNumber != '')){
            this.saveSearchHistory(object)
        }
    }

    renderRemoveAction() {
        return <span className="vismaicon vismaicon-remove-circle cursorpointer" onClick={this.props.onRemoveAction} />
    }

    render() {
        const {t, customClass} = this.props
        if (this.props.readonly) {
            return (
                <StaticTextInput label={this.props.label} 
                    value={`${this.props.defaultSelected.name} / ${this.props.defaultSelected.customerNumber}`} 
                    renderAfter={this.props.onRemoveAction && this.renderRemoveAction()} 
                />
            );
        }

        let attributes = {
            labelKey: option => `${option.name} / ${option.customerNumber} / ${option.organizationNumber} / ${option.phoneNumber}`,                 
            options: this.props.searchResults || [],
            onSearchAttr: (query) => this.props.commonActions.searchCustomer(query),
            placeholder: !this.props.placeholder ? t('customersearch.placeholder') : this.props.placeholder,
            onChangeAttr: this.onChange.bind(this),
            id: this.props.id,
            className: customClass ? customClass : "col-8"
        }

        if(this.props.newCustomerLink) {
            attributes.className = 'col-xs-5';
        }

        if(this.props.customStyle){
            attributes.className = null
        }
        
        if (this.props.defaultSelected && this.props.defaultSelected.name) {
            attributes.defaultSelected = [this.props.defaultSelected];
        }

        if(this.props.id === "headerCustomerSearch") 
            return <>
            <BaseSearch attributes={attributes}/>
            {
                this.props.newCustomerLink &&
                <span className="col-xs-3">{t('customersearch.or')} <Link to={this.props.newCustomerLink}
                                                                          style={{lineHeight: 27 + 'px'}}>{t('customersearch.createnew')}</Link></span>
            }

            {this.props.onRemoveAction &&
                this.renderRemoveAction()
            }
        </>

        return <>
            <div className={'form-group ' + (this.props.label ? 'form-search' : '')}>
                {this.props.label &&
                    <label className="col-xs-4 control-label">{this.props.label}</label>
                }
                <BaseSearch attributes={attributes}/>

                {
                    this.props.newCustomerLink &&
                    <span className="col-xs-3">{t('customersearch.or')}<Link to={this.props.newCustomerLink}
                                                                             style={{lineHeight: 27 + 'px'}}>{t('customersearch.createnew')}</Link></span>
                }

                {this.props.onRemoveAction &&
                    this.renderRemoveAction()
                }
            </div>
        </>
    }
}

function mapStateToProps(state) {
    return {
        searchResults: state.CommonReducer.customerSearchResults && state.CommonReducer.customerSearchResults.results,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerSearch));