import { useEffect } from "react";
import { connect } from "react-redux";

import * as calcTypes from "../../Constants/priceCalculationTypes";
import helpers from "../../Utils/helpers";
import { OrderSummary } from "../../Components/OrderPlacement/OrderComponents/OrderSummary";
import { OrderActions } from "../../Components/OrderPlacement/OrderComponents/OrderActions";
import { bindActionCreators } from "redux";
import orderActions from "../../Actions/orderActions";
import commonActions from "../../Actions/commonActions";

export const CheckoutContainer = ({ ...props }) => {
    const { order } = props;

    useEffect(() => {
        helpers.scrollToBottom()
    }, [order?.orderlines?.length]);

    return <>
        <div className="mb-24">
            <OrderSummary {...props} />
        </div>
        <OrderActions {...props}
            disabled={!order || (order.orderlines && order.orderlines.length === 0)}
            priceCalcType={calcTypes.priceCalculationTypes.order}
            quotationCurrentlyInProgress={order && order.quotationInProgress}
            orderStatus={order && order.status}
        />
    </>
}

const mapDispatchToProps = (dispatch) => ({
    orderActions: bindActionCreators(orderActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
});

const mapStateToProps = (state) => ({
    order: state.OrderReducer.order,
    customer: state.CustomerReducer.selectedCustomer,
    orderForm: state.CommonReducer.forms && state.CommonReducer.forms.orderForm,
    pushedQuotation: state.OrderReducer.pushedQuotation,
    product: state.OrderReducer.product && state.OrderReducer.product,
    quotationCustomerForm: state.CommonReducer.forms && state.CommonReducer.forms.quotationCustomerForm,
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutContainer);