import { useEffect, useState } from "react";
import helpers from "../../../Utils/helpers";
import AlertSmall from "../../AlertSmall";
import { useTranslation } from "react-i18next";

export function OrderSummary ({ order, isApprovalRequest, orderActions, product, customer }) {

    const { t } = useTranslation();
    
    const [alerts, setAlerts] = useState([]);
    
    useEffect(() => {
      setAlerts(getAlerts())
    }, [order, product])
  
    const getAlerts = () => {
      const alerts = [];
  
      if (order?.totalInitialPrice > customer?.creditLimit)
        alerts.push({
          type: 'warning',
          title: 'Orderläggningen är över kreditgränsen och kommer behöva godkännas av ekonomiavdelningen.',
        })
  
      return alerts
    };
  
    const onOrderLineEdit = (orderline) => {
      orderActions.editOrderline(orderline, order.customerNumber, order.orderId);
    }
  
    const onOrderLinesRemove = (orderlineIds) => {
      orderActions.removeOrderLines(order.orderId, orderlineIds, order.customerNumber)
    }
  
    const renderOrderLines = () => {
      return order.orderlines.map((orderline, index) => (
        <tr key={index}>
          <td className="text-left">{orderline.productName}</td>
          <td className="text-left">{orderline.additions}</td>
          <td className="text-left">{helpers.currencyFormatter(orderline.totalInitialPrice)}</td>
          <td className="text-left">{helpers.currencyFormatter(orderline.totalRecurringPrice)}</td>
          {!isApprovalRequest &&
            <>
              <td>
                <span className='vismaicon vismaicon-dynamic vismaicon-edit align-actionIcon icon-neutral cursorpointer' title="Edit" onClick={() => onOrderLineEdit(orderline)} />
              </td>
              <td>
                <span className='vismaicon vismaicon-dynamic vismaicon-delete align-actionIcon icon-neutral cursorpointer' title="Remove" onClick={() => onOrderLinesRemove([orderline.orderlineId])} />
              </td>
            </>}
        </tr>
      ));
    };
  
    return <>
      {alerts.map((alert, index) => (
        <AlertSmall
          key={index}
          alert={alert}
        />
      ))}
      <div className='rounded-8 overflow-hidden'>
        <table className="table table-stripe skynet-table-no-divider mb-0">
          <thead>
            <tr>
              <th>
                {t('ordergrid.product')}
              </th>
              <th >{t('ordergrid.additions')}</th>
              <th>
                {t('ordergrid.initialprice')}
              </th>
              <th>
                {t('ordergrid.recurringprice')}
              </th>
              {!isApprovalRequest &&
                <>
                  <th>{t('ordergrid.edit')}</th>
                  <th>{t('ordergrid.remove')}</th>
                </>
              }
            </tr>
          </thead>
          <tbody>
            {order && order.orderlines && renderOrderLines()}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2} className='bold'>{t('ordergrid.total')}</td>
              <td className='bold'>{helpers.currencyFormatter(order.totalInitialPrice)}</td>
              <td colSpan={3} className='bold'>{helpers.currencyFormatter(order.totalRecurringPrice)}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  };