import {useState} from "react";

export const Radiobutton = ({ item, currentChecked, onChange }) => {

    return <div key={item.label} className="radio my-4">
        <input className="my-0" type="radio" id={item.label} checked={item.value === currentChecked} disabled={item.disabled} value={item.value} onChange={(e) => onChange(e)}/>
        <label className="radio-inline" htmlFor={item.label}>{item.label}</label>
    </div>
}

export const RadioButtons = ({ items, onChange, label, value }) => {
    const [checked, setChecked] = useState(value);

    if (value && value !== checked) {
        setChecked(value)
    }
    
    const onChecked = (e) => {
        setChecked(e.target.value)
        onChange(e.target.value)
    }

    return <>
        {label && <label>{label}</label>}
        {items && items.map((item) => {
                return <Radiobutton key={item.label} item={item} onChange={onChecked} currentChecked={checked}/>
            }
        )}</>
}

