import React, { useEffect} from 'react'
import { connect } from 'react-redux';
import commonActions from '../../Actions/commonActions';
import customerActions from '../../Actions/customerActions';
import routes from '../../Constants/routes'
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import Shortcuts from '../../Components/Layout/Shortcuts';
import {useParams, useNavigate } from 'react-router-dom';
import Panel from "../Panel";
import HorizontalText, {HorizontalRow} from "../HorizontalText";


function MainCustomerTopMenu({t, customer, customerActions, commonActions }) {

    const {customerNumber} = useParams();
    const navigate = useNavigate();

    // useEffect(() => {
    //     //window.addEventListener("popstate", () => checkIfSelectedCustomerChanged(customerNumber, customer));
    //
    // }, [])
    //
    // const checkIfSelectedCustomerChanged = (customerNumber, customer) => {
    //     if (customerNumber != customer.customerNumber) {
    //         window.location.reload()
    //     }
    //     commonActions.setOpenHeader(undefined)
    // }

    const navigateHome = () => {
        navigate(routes.customerMain(customer.customerNumber))
    }

    const customerSearchOnChange = (customerNumber) => {
        if (customerNumber) {
            customerActions.getCustomer(customerNumber)
            navigate(routes.customerMain(customerNumber));
        }
    }

    return <Panel>
                <div className="row">
                    <div className="col-sm-12 p-2">
                        <div className="d-flex align-items-center">
                                      <span className="vismaicon vismaicon-home cursorpointer pr-10"
                                            onClick={() => navigateHome('default')}></span>

                            <h1 className="m-0"
                                style={{display: "inline-block"}}>{(customer.isNonInteractive ? "Non-interactive: " : "") + customer.name}</h1>
                        </div>
                    </div>
                    <div className="col-6">
                        <HorizontalRow>
                            <HorizontalText label={t("customerinfo.customernumber")} value={customer.customerNumber} copyToClipBoard={true} />
                            <HorizontalText label={t("customerinfo.organizationnumber")} value={customer.organizationNumber} copyToClipBoard={true} />
                            <HorizontalText label={t("customerinfo.contactperson")} value={customer.contactPerson} />
                            <HorizontalText label={t("customerinfo.email")} value={customer.emailAddress} copyToClipBoard={true} />
                        </HorizontalRow>
                    </div>
                    <div className="col-12">
                        <Shortcuts/>
                    </div>
                </div>
    </Panel>
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.selectedCustomer,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MainCustomerTopMenu));