import React from 'react';

function FormSearch({children, label, className}) {

    return <div className={'form-group search-group skynet-form-search search-group-button'}>
        <label className="col-xs-4 control-label">{label}</label>
        {children(`col-8 ${className ? className : ''}`)}
    </div>
}

export default FormSearch;
