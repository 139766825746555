import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams, useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Loader from '../Components/Loader';
import SearchResults from '../Components/Search/SearchResults';
import routes from '../Constants/routes';
import searchActions from '../Actions/searchActions';
import SearchHistory from '../Components/Search/SearchHistory';

function SearchContainer({ dropdownlists, t, searchState, searchActions }) {
    const { input } = useParams();
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const [searched, setSearched] = useState(false);

    useEffect(() => {
        if (input) {
            onSearchClick(input);
        }
    }, [input]);

    useEffect(() => {
        if (searchState.searchResult?.length === 1 && searched) {
            navigate(routes.customerMain(searchState.searchResult[0].customerNumber));
        }
    }, [searchState?.searchResult?.length]);

    const onKeyClick = (e) => {
        if (e.key === 'Enter') {
            onSearchClick(e.target.value, routes.search(e.target.value));
        }
    };

    const onInputChange = (e) => {
        setSearchText(e.target.value);
        searchActions.searchInputChanged(e.target.value);
    };

    const onSearchClick = (input, pushHistory) => {
        if (pushHistory) {
            setSearched(true);
            navigate(routes.search(input));
        }
        searchActions.search(input);
    };

    const onCustomerClick = (customerNumber) => {
        navigate(routes.customerMain(customerNumber));
    };

    const onSearchIconClick = () => {
        onSearchClick(searchText, routes.search(searchText));
    };

    if (!dropdownlists) {
        return null;
    }

    const state = searchState;

    return (
        <div className="container-fluid">
            <div className="col-8 p-0 searchInput">
                <div className="form-group search-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search customer"
                        onKeyPress={onKeyClick}
                        onChange={onInputChange}
                        value={state.searchInput || ''}
                        autoFocus
                    />
                    <span className="search-icon cursorpointer" onClick={onSearchIconClick}></span>
                </div>

                <Loader />
            </div>
            <SearchResults state={state} dropdownlists={dropdownlists} clickEvent={onCustomerClick} />
            <SearchHistory clickEvent={onCustomerClick} />
        </div>
    );
}

function mapStateToProps(state) {
    return {
        searchState: state.SearchReducer,
        dropdownlists: state.CommonReducer.dropdownlists,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        searchActions: bindActionCreators(searchActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SearchContainer));
