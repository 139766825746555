import { bindActionCreators } from "redux";
import Card from "../../../../Components/Card/Card";
import { connect } from "react-redux";
import customerActions from "../../../../Actions/customerActions";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import VonEditEmployeeDetailsForm from "../../../../Components/Customer/VON/EditEmployee/VonEditEmployeeDetailsForm";
import VonEditAccessRightsForm from "../../../../Components/Customer/VON/EditEmployee/VonEditAccessRightsForm";
import VonEditEmployeeTypeForm from "../../../../Components/Customer/VON/EditEmployee/VonEditEmployeeTypeForm";
import routes from "../../../../Constants/routes";
import commonActions from "../../../../Actions/commonActions";
import { VonAccessRights } from "../../../../Constants/VON/EmployeeForm/vonAccessRights";
import Loader from "../../../../Components/Loader";

function VonEditEmployeeFormContainer({ ...props }) {
    const { customerActions, commonActions, editVonEmployeeForm, isLoading } = props;
    const { customerNumber, id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        customerActions.getVonEmployeeForEdit(customerNumber, id);

        return () => {
            commonActions.cancelFormEditing('editVonEmployeeForm');
        }
    }, [])

    const onChange = (form, value, field) => {
        commonActions.changeMultiFormField(form, field, value);
    }

    const onSubmit = () => {
        switch (editVonEmployeeForm.data.onlineRoleId) {
            case VonAccessRights.Delete:
                customerActions.deleteVonEmployee(id, () => navigate(routes.customerVonAccounts(customerNumber)));
                break;
            case VonAccessRights.Inactive:
                customerActions.inactivateVonEmployee(id, () => navigate(routes.customerVonAccounts(customerNumber)));
                break;
            default:
                customerActions.updateVonEmployee(editVonEmployeeForm, () => navigate(routes.customerVonAccounts(customerNumber)));
                break;
        }
    }

    return <Card>
        <Card.Header>
            <Card.Header.Title>Editera användare</Card.Header.Title>
        </Card.Header>
        <Card.Content>
            {!isLoading ? <fieldset className="d-grid gap-24 mb-0 pl-0">
                <VonEditEmployeeDetailsForm {...props} onChange={onChange} />
                <VonEditEmployeeTypeForm {...props} onChange={onChange} />
                <VonEditAccessRightsForm {...props} onChange={onChange} />
                <div>
                    <button className='btn btn-default' onClick={() => onSubmit()}>Spara</button>
                    <button className='btn btn-default' onClick={() => navigate(routes.customerVonAccounts(customerNumber))} >Avbryt</button>
                </div>
            </fieldset> : <div className="mb-24"><Loader /></div>}
        </Card.Content>
    </Card>
}

const mapStateToProps = (state) => {
    return {
        editVonEmployeeForm: state.CommonReducer.forms && state.CommonReducer.forms.editVonEmployeeForm,
        isLoading: state.CommonReducer.isLoading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VonEditEmployeeFormContainer);