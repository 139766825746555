import React from 'react';
import { useTranslation } from 'react-i18next';
export default function InyettDetails({ inyettData}) {
    const {t} = useTranslation();

    return <div className="table-responsive">
        <table className={`table table-stripe`}>
            <tbody>
            <tr>
                <th className="bold">{t('inyettdetailscontainer.employees')}</th>
                <td>{inyettData?.employeeAmount}</td>
            </tr>
            <tr>
                <th className="bold">{t('inyettdetailscontainer.revenue')}</th>
                <td>{inyettData?.netRevenue ? inyettData.netRevenue : "-" + " tKr"}</td>
            </tr>
            <tr>
                <th className="bold">{t('inyettdetailscontainer.type')}</th>
                <td>{inyettData?.type}</td>
            </tr>
            <tr>
                <th className="bold">{t('inyettdetailscontainer.branch')}</th>
                <td>{inyettData?.branch}</td>
            </tr>
            </tbody>
        </table>
    </div>
}
