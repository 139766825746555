import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import customerActions from '../../Actions/customerActions'
import actionLogActions from '../../Actions/actionLogActions'
import { withTranslation } from 'react-i18next';

import { Link } from 'react-router-dom'
import routes from '../../Constants/routes'
import Panel from '../../Components/Panel';

class ActionLogContainer extends Component {

    componentDidMount() {
        this.props.actionLogActions.getCustomerActionLog(this.props.customerNumber);
    }

    renderNoData() {
        const {t} = this.props
        return (<p>{t('actionlogcontainer.noentriesfound')}</p>);
    }

    render() {
        const {t} = this.props
        if(!this.props.actionLogs) {
            return null;
        }

        return (
            <Panel title={t('actionlogcontainer.header')}>
                {(this.props.actionLogs.length === 0) ? this.renderNoData() :
                    <table className="table table-stripe table-actionLogs">
                        <thead>
                            <tr>
                                <th className="text-left">{t('actionlogcontainer.time')}</th>
                                <th className="text-left">{t('actionlogcontainer.action')}</th>
                                <th className="text-left">{t('actionlogcontainer.description')}</th>
                                <th className="text-left">{t('actionlogcontainer.salesperson')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.actionLogs.map((actionLog, index) => {
                                return <tr key={index}>
                                    <td>{actionLog.createDate}</td>
                                    {actionLog.emailActionLogId && !actionLog.hideEmailLink
                                        ? <td><Link to={routes.emailHistory(actionLog.emailActionLogId)}>{actionLog.action}</Link></td>
                                        : <td>{actionLog.action}</td>}
                                    <td>{actionLog.description}</td>
                                    <td>{actionLog.salesPersonName}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>}
            </Panel>
        );
    }
}

function mapStateToProps(state) {
    return {
        actionLogs: state.ActionLogReducer.actionLogs,
        customerNumber: state.CustomerReducer.selectedCustomer.customerNumber
    }
}

function mapDispatchToProps(dispatch) {
   return {
       customerActions : bindActionCreators(customerActions, dispatch),
       actionLogActions : bindActionCreators(actionLogActions, dispatch) 
    } 
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ActionLogContainer));