import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Card from "../../Card/Card";
import QuotationModal from "../../QuotationModal";
import Modal from "../../Modal";
import TextInput from "../../FormComponents/Input/TextInput";
import Loader from "../../Loader";
import routes from "../../../Constants/routes";

export function OrderActions ({ ...props }) {
    const { order, customer, orderStatus, quotationCurrentlyInProgress, commonActions, orderActions, disabled } = props;
    const { t } = useTranslation();

    const [showModal, toggleModal] = useState(false);
    const navigate = useNavigate();
  
    const isAboveCreditLimit =
      order && customer && order.totalInitialPrice > customer.creditLimit;
  
    const isQuotation =
      orderStatus === 4 || quotationCurrentlyInProgress;
  
    const onCancel = () => {
      commonActions.setOpenHeader('main');
      orderActions.cancel(
        order.orderId,
        order.customerNumber,
        () => navigate(routes.customerDetails(order.customerNumber))
      );
    };
  
    if (!order) return null;
  
    return <>
      <Card>
        <Card.Content>
          <div className="d-flex justify-content-end">
            <div>
              <div>
                <button type="button" className="btn btn-default mb-0" onClick={() => onCancel()}>
                  {t('orderaction.cancel')}
                </button>
                <button type="button" className="btn btn-primary mb-0" disabled={disabled} onClick={() => toggleModal(true)}>
                  {disabled ? t('orderaction.complete') : isQuotation ? t('orderaction.sendquotation') : isAboveCreditLimit ? t('orderaction.requestapproval') : t('orderaction.completeorder')}
                </button>
              </div>
            </div>
          </div>
          {showModal && <ConfirmModal
            isQuotation={isQuotation}
            isAboveCreditLimit={isAboveCreditLimit}
            onCancel={() => toggleModal(false)}
            {...props}
          />}
        </Card.Content>
      </Card>
    </>
  };
  
  function ConfirmModal ({onCancel, commonActions, orderActions, isQuotation, order, quotationCustomerForm, isAboveCreditLimit, customer, orderForm }) {
    
    const { t } = useTranslation();
    
    const navigate = useNavigate();
  
    const onChange = (form, field, value) => {
      commonActions.changeMultiFormField(form, field, value)
    }
  
    const navigateToConfirmationMail = (customerNumber) => (newOrderId) =>
      navigate(routes.orderConfirmationEmail(customerNumber, newOrderId));
  
    const onSendApprovalRequest = () => {
      orderActions.sendApprovalRequest(
        order.orderId,
        order.customerNumber,
        orderForm,
        () => navigate(routes.customerDetails(order.customerNumber))
      );
    };
  
    const onComplete = () => {
      orderActions.complete(
        order.orderId,
        order.customerNumber,
        orderForm,
        navigateToConfirmationMail(order.customerNumber)
      );
    };
  
    const copyContactInfo = () => {
      onChange(orderForm.id, 'yourReference', customer.contactPerson)
      onChange(orderForm.id, 'emailAddress', customer.emailAddress)
    }
  
    const modalProps = isQuotation ? {
      onChange: onChange,
      onClose: onCancel,
      onLoad: () => orderActions.startQuotationModal(order, quotationCustomerForm, true),
      onSave: (form) => orderActions.sendQuotation(form, onCancel, true, navigate(routes.customerMain(order.customerNumber)))
    } : {
      open: true,
      stateful: true,
      showButtons: false,
      title: t("confirmordermodal.confirm"),
      onCancelClick: onCancel,
    };
  
    return <>
      {isQuotation 
        ? <QuotationModal {...modalProps} />
        : <Modal {...modalProps}>
            <div className="">
              <div className='mb-16'>
                <TextInput
                  label={"*" + t("confirmordermodal.name")}
                  field="yourReference"
                  onChange={(e) => onChange(orderForm.id, 'yourReference', e.target.value)}
                  fieldErrorFlags={orderForm.fieldErrorFlags}
                  value={orderForm.data.yourReference}
                />
              </div>
              <div className='mb-16'>
                <TextInput
                  label={"*" + t("confirmordermodal.confirmationemail")}
                  field="emailAddress"
                  onChange={(e) => onChange(orderForm.id, 'emailAddress', e.target.value)}
                  fieldErrorFlags={orderForm.fieldErrorFlags}
                  value={orderForm.data.emailAddress}
                />
              </div>
              <div className='mb-32'>
                {customer.invoiceEmail && <TextInput label={t("confirmordermodal.invoiceemail")} readonly value={customer.invoiceEmail} disabled />}
              </div>
              <div className='d-flex justify-content-end mb-32'>
                <button type="button" className="btn mb-0" onClick={() => copyContactInfo()}>{t("confirmordermodal.usecustomerinfo")}</button>
              </div>
              <div className='d-flex justify-content-end'>
                <button type="button" className="btn mb-0" onClick={() => onCancel()}>{t("confirmordermodal.cancel")}</button>
                <button type="button" className="btn btn-primary mb-0" onClick={() => isAboveCreditLimit ? onSendApprovalRequest() : onComplete()}>
                  {isAboveCreditLimit ? t('orderaction.requestapproval') : t('orderaction.completeorder')}
                </button>
              </div>
            </div>
            <Loader />
          </Modal>
      }
    </>
  };