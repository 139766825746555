import { connect } from "react-redux";
import Card from "../../../../Components/Card/Card";
import { SubscriptionDiscountsTable } from "../../../../Components/Customer/VOSS/Discounts/SubscriptionDiscountsTable";
import { bindActionCreators } from "redux";
import { useEffect } from "react";
import customerActions from "../../../../Actions/customerActions";
import discountActions from "../../../../Actions/discountActions";
import Loader from "../../../../Components/Loader";
import { useTranslation } from "react-i18next";

function SubscriptionsDiscountContainer({ ...props }) {
    const { customerActions, subscriptions, customer, isLoading } = props;
    const { t } = useTranslation();

    useEffect(() => {
        customerActions.getCustomerSubscriptions(customer.customerId);
    }, []);

    const subscriptionsWithDiscounts = subscriptions?.filter(s => s.discounts.length > 0);

    return <Card>
        <Card.Header>
            <Card.Header.Title>{t("subscriptionsdiscountcontainer.discounts")}</Card.Header.Title>
        </Card.Header>
        <Card.Content>
            <fieldset className="d-grid gap-24 mb-0">
                {isLoading ? (
                    <div className="mb-24">
                        <Loader />
                    </div>
                ) : subscriptionsWithDiscounts && subscriptionsWithDiscounts.length > 0 ? (
                    subscriptionsWithDiscounts.map((subscription, index) => (
                        <div key={index}>
                            <h2 className="mb-12">{subscription.package.name}</h2>
                            <SubscriptionDiscountsTable subscription={subscription} {...props} />
                        </div>
                    ))
                ) : (
                    <i>{t("subscriptionsdiscountcontainer.nodiscounts")}</i>
                )}
            </fieldset>
        </Card.Content>
    </Card>
}

function mapStateToProps(state) {
    return {
        isLoading: state.CommonReducer.isLoading,
        customer: state.CustomerReducer.selectedCustomer,
        subscriptions: state.CustomerReducer.subscriptions,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        discountActions: bindActionCreators(discountActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsDiscountContainer);