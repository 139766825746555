import React, {useState} from 'react';
import { Link, useLocation } from 'react-router-dom'

import BadgeCounter from './BadgeCounter'

function LeftNavigationBarItem ({ link, openHeader}) {
    
    const [isOpen, setIsOpen] = useState(false);
    
    const location = useLocation();
    const path = link.path;
    const title = link.title;
    const badgeNumber = link.badgeNumber
    const activate = link.activate === undefined ? true : link.activate;
    var hasActiveSubItem;
    
    const checkIfActive = (mainItem) => {
        if (mainItem.subItems) {
            mainItem.subItems.map((subItem) => {
                if ((location.pathname === subItem.path || (path !== '/' && location.pathname.indexOf(subItem.path) > -1)) && activate) {
                    hasActiveSubItem = true;
                    if (openHeader === undefined) {
                        link.onClick(link.id)
                    }
                }
            })
        }
        return (((location.pathname === mainItem.path || (path !== '/' && location.pathname.indexOf(mainItem.path) > -1)) && activate))
    }

    const hasSubItems = !!(link.subItems && link.subItems.length > 0);
    
    // TODO Refactor this later...
    var isActive = checkIfActive(link)
    
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }
    
    const getClassList = () => {
        
        const classes = [];
        
        if(hasSubItems) {
            classes.push("has-children");
            
            if(isOpen) {
                classes.push("active-child")
                classes.push("active")
            }
            
        }
        
        return classes.join(" ");
    }
    
    return (
        
        <li className={getClassList()}>
            
            {/* Main menu alternative with path directly */}
            {!link.hidden && path &&
                <Link to={path}>{title} <BadgeCounter badgeNumber={badgeNumber} /></Link>
            }
            
            {!hasSubItems && !path && link.onClick &&
                <a onClick={link.onClick}>{title}<BadgeCounter badgeNumber={badgeNumber}/></a>
            }

            {/*Main menu alternative with subitems*/}
            {hasSubItems && <a onClick={toggleMenu}>{link.title}<BadgeCounter badgeNumber={badgeNumber}/></a>}
            
            {link.subItems &&
                <ul className="nav" aria-hidden={false} aria-expanded={true} aria-controls="subMenu">
                    {link.subItems.map((subItem, index) => {
                        if (!subItem.hidden) {
                            return <LeftNavigationBarItem key={index} link={subItem} location={location} />
                        }
                    })}
                </ul>
            }
        </li>
    );
}

export default LeftNavigationBarItem;