import { useTranslation } from "react-i18next";
import Card from "../../../Card/Card";
import { BlikkTextField } from "./BlikkTextField";

export function BlikkCustomerInfoCard({ ...props }) {

    const { t } = useTranslation();

    return <Card>
        <Card.Header>
            <Card.Header.Title>{t("productdetails.header")}</Card.Header.Title>
        </Card.Header>
        <Card.Content>
            <BlikkCustomerInfo {...props} />
        </Card.Content>
    </Card>
};

function BlikkCustomerInfo({ orderActions, product, customer, order }) {

    const { t } = useTranslation();

    const reloadFromBlikk = () => {
        orderActions.getBlikkProductDetails(product.productId, customer.customerNumber, order.orderId, product.tenantGuid, true);
    }

    return <div className='d-flex flex-column' style={{ gap: "32px" }}>
        <BlikkTextField header={t("blikkcustomerinfo.name")} value={product.customerName} />
        {product.erpCustomerNumber && <BlikkTextField header={t("blikkcustomerinfo.customernumber")} value={product.erpCustomerNumber} />}
        <BlikkTextField header={t("blikkcustomerinfo.maincontact")} value={product.primaryContactEmail} />
        <BlikkTextField header={t("blikkcustomerinfo.edition")} value={product.edition} />
        <button className="btn btn-default" onClick={() => reloadFromBlikk()}>{t("blikkcustomerinfo.reloadfromblikk")}</button>
    </div>
}

