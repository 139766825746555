import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import Modal from '../Modal'

import commonActions from '../../Actions/commonActions'
import customerActions from '../../Actions/customerActions'
import leadActions from '../../Actions/leadActions'
import TextInput from '../TextInput';
import DropDown from '../DropDown';
import {FormEditor} from '../Editor';
import StaticTextInput from '../StaticTextInput';



class LeadSendEmailModal extends Component {
    
    componentDidMount() {
        this.props.customerActions.getAoLeadEmailTemplate(this.props.customer.customerNumber, this.props.internalLeadEmailTemplate);
        this.props.leadActions.getExternalPartners();
    }


    setDefaultAssignee = () => {
        const {emailForm, customer} = this.props;


        if(emailForm.data.salesPersons){
            var defaultAssignee = emailForm.data.salesPersons.find((salesPerson) => salesPerson.name === customer.responsibleSalesPersonName);
            if(defaultAssignee){
                this.props.commonActions.changeMultiFormField("internalLeadEmail", 'assignedUserId', defaultAssignee.id);
            }
        }
    }

    handleCustomAnswer = (question, customAnswerText) => {
        const { externalPartners} = this.props;
        question.answers.find(answer => answer.id === question.selectedAnswer).customAnswerText = customAnswerText
        this.props.leadActions.updateExternalPartners(externalPartners)
    }

    handleFieldChange = (field, value) => {
        this.props.commonActions.changeMultiFormField("internalLeadEmail", field, value);
    }

    handleLeadTypeSelect = (leadList, value) => {
        this.props.commonActions.changeMultiFormField("internalLeadEmail", "selectedLeadType", value);
        this.props.commonActions.changeMultiFormField("internalLeadEmail", "isInternal", leadList.find(lead => lead.id === value).isInternal)
        if (value === 'aoLead')
            this.setDefaultAssignee();
    }

    handleSelectExternalPartner = (field, value) => {
        const { externalPartners} = this.props;
        var selectedPartner;

        if(value === ""){
            selectedPartner = null
        }
        else
        {
            selectedPartner = externalPartners.find(partner => partner.id === value)
        }

        this.props.commonActions.changeMultiFormField("internalLeadEmail", field, selectedPartner);
    }

    handleSelectAnswer = (question, answerId) => {
        const { externalPartners} = this.props;
        question.selectedAnswer = answerId;
        this.props.leadActions.updateExternalPartners(externalPartners)
    }

    handleClickSave = () => {
        const { emailForm, customer, customerActions } = this.props;

        if (emailForm.data.leadType === 'externalLead') {
            customerActions.sendExternalLeadEmail(emailForm, customer.customerNumber, this.handleClickClose)
        }
        else
        {
            customerActions.sendInternalAoLeadEmail(emailForm, customer.customerNumber, this.handleClickClose)
        }
    }

    handleClickClose = () => {
        const { onClose, commonActions } = this.props;

        commonActions.cancelFormEditing("internalLeadEmail", onClose)
    }

    getReceiverEmails() {
        const { emailForm } = this.props;
        return emailForm && emailForm.data && emailForm.data.defaultToAddresses;
    }

    render() {
        const { emailForm, externalPartners, t} = this.props;

        if (!emailForm) {
            return null;
        }

        var selectedExternalPartner;
        if ((emailForm.data.selectedExternalPartner != null)) {
            selectedExternalPartner = externalPartners.find(externalPartner => externalPartner.id === emailForm.data.selectedExternalPartner.id)
        }

        const leadList = [
            { id: 'aoLead', name: t('leadsendemailmodal.aoleads'), isInternal: true },
            { id: 'externalLead', name: t('leadsendemailmodal.externalleads') },
            { id: 'smbLead', name: t('leadsendemailmodal.smbleads')},
            { id: 'tidSmartLead', name: t('leadsendemailmodal.tidsmartleads'), isInternal: true},
            { id: 'supportLead', name: t('leadsendemailmodal.supportleads'), isInternal: true}
        ];

        const leadType = emailForm.data.selectedLeadType;
        emailForm.data.leadType = leadType;

        const isIntroductionPage = leadType === undefined;

        function getModalTitle() {
            if (isIntroductionPage) {
                return t('leadsendemailmodal.sendlead')
            } else {
                return t('leadsendemailmodal.send') + leadList.find(lead => lead.id === leadType).name
            }
        }

        function isCustomAnswer(question) {
            if (emailForm.data.selectedExternalPartner.questions != undefined) {
                var answer = question.answers.find(selectedAnswer => selectedAnswer.id === question.selectedAnswer)
                if (answer != undefined) {
                    if (answer.isCustomAnswer) {
                        return answer.isCustomAnswer
                    }
                }
            }
        }

        const modalProps = {
            mainButtonTitle: t('leadsendemailmodal.send'),
            onCancelClick: this.handleClickClose,
            stateful: true,
            open: true,
            onChange: this.handleFieldChange,
            onSaveClick: this.handleClickSave,
            showButtons: !isIntroductionPage
        }

        const priorityTypes = [
            { id: 'high', name: t('leadsendemailmodal.high')},
            { id: 'medium', name: t('leadsendemailmodal.medium')},
            { id: 'low', name: t('leadsendemailmodal.low')}
        ]

        return (
            <Modal {...modalProps} title={getModalTitle()}>
                <form className="form-horizontal">
                    {isIntroductionPage && <div>
                        <DropDown label={t('leadsendemailmodal.selectleadtype')} blankOption={true} list={leadList} onChange={this.handleLeadTypeSelect.bind(this, leadList)} />
                        <h1>{t('leadsendemailmodal.aoleads')}</h1>
                        <p>{t('leadsendemailmodal.aoleadstext')}</p>
                        <p></p>
                        <h1>{t('leadsendemailmodal.externalleads')}</h1>
                        <p>{t('leadsendemailmodal.externalleadstext')}</p>
                        <h1>{t('leadsendemailmodal.smbleads')}</h1>
                        <p>{t('leadsendemailmodal.smbleadstext')}</p>
                        <h1>{t('leadsendemailmodal.internalleads')}</h1>
                        <p>{t('leadsendemailmodal.internalleadstext')}</p>
                    </div>}
                    {!isIntroductionPage && <fieldset>
                        {leadType === "externalLead" ? <div>
                            <DropDown label={t('leadsendemailmodal.choosecompany')} blankOption={true} list={externalPartners} onChange={this.handleSelectExternalPartner.bind(this, "selectedExternalPartner")} />
                            {selectedExternalPartner ? <div>
                                <StaticTextInput label={t('leadsendemailmodal.companydescription')} value={selectedExternalPartner && selectedExternalPartner.companyDescription} />
                                <StaticTextInput label={t('leadsendemailmodal.companyemail')} value={selectedExternalPartner && selectedExternalPartner.companyEmail} />
                                {selectedExternalPartner.questions.map(question =>
                                    <div>
                                        <DropDown label={question.text + " *"} value={question.selectedAnswer} blankOption={true} list={question.answers} onChange={this.handleSelectAnswer.bind(this, question)} />
                                        {isCustomAnswer(question) ? <TextInput value={question.answers.find(selectedAnswer => selectedAnswer.id === question.selectedAnswer).customAnswerText} onChange={this.handleCustomAnswer.bind(this, question)} /> : <></>}
                                    </div>)}
                            </div> : <></>
                            }
                        <TextInput label={t('leadsendemailmodal.customername')} value={emailForm.data.customerName} onChange={this.handleFieldChange.bind(this, "customerName")} />
                        </div> : <></>}
                        <TextInput label={t('leadsendemailmodal.customernumber')} value={emailForm.data.customerNumber} onChange={this.handleFieldChange.bind(this, "customerNumber")} />
                        <TextInput label={t('leadsendemailmodal.phonenumber')} value={emailForm.data.phoneNumber} onChange={this.handleFieldChange.bind(this, "phoneNumber")} />
                        <TextInput label={t('leadsendemailmodal.contactperson')} value={emailForm.data.contactPerson} onChange={this.handleFieldChange.bind(this, "contactPerson")} />
                        <TextInput label={t('leadsendemailmodal.email')} value={emailForm.data.contactEmail} onChange={this.handleFieldChange.bind(this, "contactEmail")} />
                        {leadType === "aoLead" ? <DropDown label={t('leadsendemailmodal.assignto')} list={emailForm.data.salesPersons} value={emailForm.data.assignedUserId} onChange={this.handleFieldChange.bind(this, "assignedUserId")} /> : <></>}
                        {leadList.find(lead => lead.id === leadType).isInternal ? <DropDown label={t('leadsendemailmodal.priority')} blankOption={true} list={priorityTypes} value={emailForm.data.priorityType} onChange={this.handleFieldChange.bind(this, "priorityType")}/> : <></>}
                        <FormEditor label={t('leadsendemailmodal.description')} toolbar={false} value={emailForm.data.body} onChange={value => this.handleFieldChange("body", value)} />
                    </fieldset>}
                </form>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.selectedCustomer,
        emailForm: state.CommonReducer.forms && state.CommonReducer.forms.internalLeadEmail,
        internalLeadEmailTemplate: state.CustomerReducer.internalLeadEmailTemplate,
        externalPartners: state.LeadsReducer.externalPartners
    }
}

function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch),
        leadActions: bindActionCreators(leadActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadSendEmailModal));