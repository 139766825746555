import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import helpers from '../../Utils/helpers';
import Tooltip, {TooltipContent, TooltipTrigger} from '../../Components/Overlays/Tooltip';
import { InfoLabel } from '../../Components/Badges/InfoLabel';

function CustomerInfoFlag({type, title, onClick, style}) {
    return (
        <div className="d-inline-block position-relative infolabel mr-8">
            <InfoLabel type={type} title={title} onClick={onClick}/>
        </div>
    )

}

function CustomerInfoFlagsContainer({ customer, relatedCustomers, onClick }) {
    
    const aoPartnerDates = customer.aoPartnerDates
    const aoPartnerRequest = customer.aoPartnerRequest
    const growthProgramDates = customer.growthProgramDates
    const growthProgramRequest = customer.growthProgramRequest
    const flags = customer.customerInfoFlags
    const eekonomiCollaborations = customer.eekonomiCollaborations
    const partnerLevel = customer.partnerLevel    
    const hasAOSimplicity = customer.hasAOSimplicity

    const flagType = {
        GrowthProgram: 1,
        AoPartner: 2,
        AOPartnerCertified: 4
    }

    const templateTypes = {
        GrowthProgramLead: "GrowthProgramLead",
        AOPartnerLead: "AOPartnerLead"
    }
    const isPartnerCustomer = (customer) => {
        if ((customer.type === 4 || customer.type === 5)
            || (customer.group === 3000 || customer.group === 3020 || customer.group === 3050)) {
            return true
        }
    }

    const aoHasPartnerInfo = () => {
        return relatedCustomers && helpers.isAoCustomer(customer) && relatedCustomers.some(c => isPartnerCustomer(c));
    }

    const partnerHasAoInfo = () => {
        return relatedCustomers && isPartnerCustomer(customer) && relatedCustomers.some(c => helpers.isAoCustomer(c));
    }

    const hasValidAoDates = () => {
        if(aoPartnerDates && (aoPartnerDates.validFrom != null && aoPartnerDates.validTo != null && (new Date(aoPartnerDates.validFrom) <= Date.now() && new Date(aoPartnerDates.validTo) >= Date.now()) || aoPartnerDates.validFrom != null && aoPartnerDates.validTo == null))
            return true
    }

    const renderGrowthProgram = (customerInfoFlags, growthProgramRequest) => {
        
        const growthProgramCertified = customerInfoFlags.filter(flag => {
            return flag.bitValue === flagType.GrowthProgram
        })

        if (growthProgramCertified[0].hasFlag && (growthProgramDates && growthProgramDates.validFrom != null && growthProgramDates.validTo != null && (new Date(growthProgramDates.validFrom) <= Date.now() && new Date(growthProgramDates.validTo) >= Date.now())))
            return <CustomerInfoFlag type="success" title="Growth Program" />
        
        if (growthProgramCertified[0].hasFlag && (growthProgramDates && growthProgramDates.validFrom != null && growthProgramDates.validTo == null))
            return <CustomerInfoFlag type="success" title="Growth Program" />
        
        if (growthProgramRequest)
            return <CustomerInfoFlag type="warning" title="Growth Program sent" />

        if (growthProgramDates && growthProgramDates.validFrom != null && growthProgramDates.validTo != null && (new Date(growthProgramDates.validFrom) <= Date.now() && new Date(growthProgramDates.validTo) <= Date.now()))
            return <CustomerInfoFlag type="danger" title="Growth Program" />
        
        if (!growthProgramRequest)
            return <CustomerInfoFlag type="danger" title="Growth Program" />
    }

    const getPartnerColor = (eekonomiCount) => {

        if ((eekonomiCount >= 0 && eekonomiCount <= 6) || (eekonomiCount >= 13 && eekonomiCount <= 16) || (eekonomiCount >= 23 && eekonomiCount <= 46) || eekonomiCount == 52) {
            return {
                backgroundColor: '#7FB435',
                borderColor: '#7FB435',
                color: "#000"
            }
        }

        if ((eekonomiCount >= 7 && eekonomiCount <= 9) || (eekonomiCount >= 17 && eekonomiCount <= 19) || (eekonomiCount >= 47 && eekonomiCount <= 49)) {
            return {
                backgroundColor: '#4D8400',
                borderColor: '#4f8800',
                color: "#000"
                
            }
        }

        if ((eekonomiCount >= 10 && eekonomiCount <= 12) || (eekonomiCount >= 20 && eekonomiCount <= 22) || (eekonomiCount >= 50 && eekonomiCount <= 52)) {
            return {
                backgroundColor: '#f0ad4e',
                borderColor: '#eea236',
                color: "#000"
            }
        }
    }

    const renderPartnerLevelFlag = (eekonomiCount, partnerLevel) => {
        if (hasValidAoDates()){

            const attributes = {
                className: "d-inline-block position-relative infolabel mr-8"
            };

            return (<Tooltip>
                <TooltipTrigger attributes={attributes}>
                    <InfoLabel title={`Partnernivå ${partnerLevel}`} type="success" style={getPartnerColor(eekonomiCount)} />
                </TooltipTrigger>
                <TooltipContent>
                    {`${eekonomiCount} ${t('customerinfoflags.eekonomicollaborations')}`}
                </TooltipContent>
            </Tooltip>)
        }
    }

    const renderAoPartner = (customerInfoFlags, aoPartnerRequest) => {
        
        const aoPartnerCertified = customerInfoFlags.filter(flag => {
            return flag.bitValue === flagType.AOPartnerCertified
        })

        if (aoPartnerCertified[0].hasFlag)
            return <CustomerInfoFlag type="success" title={aoPartnerCertified[0].friendlyName} />

        if (hasValidAoDates())
            return <CustomerInfoFlag type="success" title="AO Partner" />
        
        if (aoPartnerRequest && !aoPartnerCertified[0].hasFlag)
            return <CustomerInfoFlag type="warning" title="AO Partner sent" onClick={() => onClick("showInternalLeadEmailModal", templateTypes.AOPartnerLead)}  />
        
        if (aoPartnerDates && aoPartnerDates.validFrom != null && aoPartnerDates.validTo != null && (new Date(aoPartnerDates.validFrom) <= Date.now() && new Date(aoPartnerDates.validTo) <= Date.now()))
            return <CustomerInfoFlag type="danger" title="AO Partner" onClick={() => onClick("showInternalLeadEmailModal", templateTypes.AOPartnerLead)} />
        
        if (!aoPartnerCertified[0].hasFlag && !aoPartnerRequest)
            return <CustomerInfoFlag type="warning" title="AO Partner" onClick={() => onClick("showInternalLeadEmailModal", templateTypes.AOPartnerLead)} />
    }

    const renderAOSimplicity = (hasAOSimplicity) => {
        if (hasAOSimplicity)
            return <CustomerInfoFlag type="success" title="Smarta Byrån" />
        else
            return <CustomerInfoFlag type="danger" title="Smarta Byrån" />
    }
    
    const { t } = useTranslation();
    
    return (
        <>
            {!!customer.customerInfoFlags.length && <>
                {renderGrowthProgram(flags, growthProgramRequest)}
                {renderAoPartner(flags, aoPartnerRequest)}
                {renderPartnerLevelFlag(eekonomiCollaborations, partnerLevel)}
                {renderAOSimplicity(hasAOSimplicity)}
            </>}
            {aoHasPartnerInfo() && <CustomerInfoFlag type="warning" title={t('maininfocontainer.customeralsoreseller')}/>}
            {partnerHasAoInfo() && <CustomerInfoFlag type="warning" title={t('maininfocontainer.customeralsoao')}/>}
            {customer.isTestCustomer && <CustomerInfoFlag type="info" title={t('maininfocontainer.testcustomer')} />}
            {customer.isNonInteractive && <CustomerInfoFlag type="success" title={t('maininfocontainer.noninteractive')} />}
        </>
    )
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.selectedCustomer,
        relatedCustomers: state.CustomerReducer.relatedCustomers,
    }
}

export default connect(mapStateToProps)(withTranslation()(CustomerInfoFlagsContainer));