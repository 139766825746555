import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import helpers from '../../Utils/helpers'

const CollaborationsList = ({ collaborations, sortListByKey, listOfServiceNames, showConsultant }) => {
    const { t } = useTranslation();
    const filter = localStorage.getItem("CollaborationsFilter");
    const [filterOptions, setFilterOptions] = useState(false);

    const sortList = () => {
        const jsonSort = localStorage.getItem("sortOrder_collaborations");
        return jsonSort ? JSON.parse(jsonSort) : [];
    }

    const filterCollaborationsByProduct = (filterId) => {
        localStorage.setItem("CollaborationsFilter", filterId);

        filterChoices();
    }

    const filterChoices = () => {
        setFilterOptions(!filterOptions);
    }

    const productNames = () => {
        const filterList = [];
        listOfServiceNames.map(item => {
            filterList.push({
                id: item,
                name: item
            })
        })
        filterList.push({
            id: "all",
            name: t("all")
        }
        )
        return filterList;
    }

    const filterIsUsed = () => filter && filter !== 'all';

    if (filterIsUsed())
        collaborations = collaborations.filter(c => c.product === filter);

    const sort = sortList();
    const sortKey = sort.key;
    const sortOrder = sort.sortOrder === "asc" ? "pull-right vismaicon vismaicon-sm vismaicon-arrow-up-circle" : "pull-right vismaicon vismaicon-sm vismaicon-arrow-down-circle";
    const productNamesList = productNames();

    const filterUsed = filterIsUsed() && <span className="badge badge-default">{'\u2714'}</span>

    return <div className="table-responsive mt-10">
        <table className="table table-stripe">
            <thead>
                <tr>
                    {showConsultant ? <>
                        <th className="text-left" onClick={() => sortListByKey('consultantNumber')}>{t('collaboration.consultantnumber')} {sortKey === 'consultantNumber' && <i className={sortOrder}></i>}</th>
                        <th className="text-left" onClick={() => sortListByKey('consultantName')}>{t('collaboration.consultantname')} {sortKey === 'consultantName' && <i className={sortOrder}></i>}</th>
                    </> : <>
                    <th className="text-left" onClick={() => sortListByKey('customerNumber')}>{t('collaboration.customernumber')} {sortKey === 'customerNumber' && <i className={sortOrder}></i>}</th>
                    <th className="text-left" onClick={() => sortListByKey('name')}>{t('collaboration.customername')} {sortKey === 'name' && <i className={sortOrder}></i>}</th>
                    
                    </>}
                    
                    
                    <th className="text-left sortable" >{t('collaboration.product')} {<i onClick={filterChoices} className="vismaicon vismaicon-sm vismaicon-filter" title={t('internalleadslist.filter')}></i>} {filterUsed}
                        {filterOptions && <div className="table-dropdown">{productNamesList.map((choice, index) => { return <span key={index} onClick={() => filterCollaborationsByProduct(choice.id)}>{choice.name}<br></br> </span> })}</div>}</th>
                    <th className="text-left" onClick={() => sortListByKey('moduleName')}>{t('collaboration.module')} {sortKey === 'moduleName' && <i className={sortOrder}></i>}</th>
                    <th className="text-left" onClick={() => sortListByKey('invoiceParty')}>{t('collaboration.invoicereceiver')} {sortKey === 'invoiceParty' && <i className={sortOrder}></i>}</th>
                    <th className="text-left" onClick={() => sortListByKey('created')}>{t('created')} {sortKey === 'created' && <i className={sortOrder}></i>}</th>
                </tr>
            </thead>
            <tbody>
                {collaborations.map((collaboration, index) => {
                    return <tr key={index}>
                        {showConsultant ? <>
                            <td>{collaboration.consultantNumber} </td>
                            <td>{collaboration.consultantName} </td>
                        </> : <>
                            <td>{collaboration.customerNumber}</td>
                            <td>{collaboration.name}</td>
                        </>
                        }
                        <td>{collaboration.product}</td>
                        <td>{collaboration.moduleName ? collaboration.moduleName : "n/a"}</td>
                        <td>{collaboration.invoiceParty}</td>
                        <td>{helpers.formatDate(collaboration.created)}</td>
                    </tr>
                })}
            </tbody>
        </table>
    </div>
}

export default CollaborationsList;