import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import commonActions from '../Actions/commonActions'
import './Alert.css'

class Alert extends Component {
    constructor() {
        super();

        this.delay = 7000;
    }

    setTimer() {
        if(this.timer) {
            clearTimeout(this.timer)
        }
        
        const timeout = this.props.alert.timeout;
        const delay = (timeout > 0 && timeout) || this.delay;
        
        this.timer = setTimeout(function(){
            this.timer = null;
            this.props.commonActions.hideAlert();
        }.bind(this), delay);
    }

    onCloseClick() {
        clearTimeout(this.timer);
        this.timer = null;
        this.props.commonActions.hideAlert();
    }
  
    render() {
        let key = 1;
        const alert = this.props.alert;

        if(!alert) {
            return null;
        }

        this.setTimer();
        
        return (
            <div className="alertWrapper">
                <div className={`alert alert-${alert.type}`}> 
                    <button type="button" className="close" aria-label="Close" onClick={ this.onCloseClick.bind(this) }><span>&times;</span></button>
                    <div><strong>{ alert.title }</strong></div>

                    {alert.message && alert.message.split("\n").map(item => {
                        return <span key={key++}>{item}
                            <br/>
                                            </span>
                    })}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        alert: state.CommonReducer.alert
    }
}

function mapDispatchToProps(dispatch) {
   return {
       commonActions: bindActionCreators(commonActions, dispatch)
    } 
}

export default connect(mapStateToProps, mapDispatchToProps)(Alert);