export function TierStepper({ product, orderActions }) {
    
    const tier = product.offer.tiers.find(tier => tier.id === product.priceCalculation.tierId)
    
    return <div>
        <ol className="breadcrumb mb-0">
            <li>
                <a onClick={() => orderActions.getVossPriceCalculations(product.offer)}>Tiers</a>
            </li>
            <li className='active'>
                <a>{tier.name}</a>
            </li>
        </ol>
    </div>
}