import { useTranslation } from "react-i18next";
import helpers from "../../../../Utils/helpers";
import SkynetIcon from "../../../Fundamentals/SkynetIcon";

export function SubscriptionDiscountsTable({ subscription: { discounts, id }, discountActions, customerActions, customer }) {

    const { t } = useTranslation();

    const onTerminateDiscount = (discount) => {
        discountActions.terminateSubscriptionDiscount(id, discount, () => customerActions.getCustomerSubscriptions(customer.customerId))
    }

    return <table className="table table-stripe skynet-table-no-divider">
        <thead>
            <tr>
                <th>{t("subscriptionsdiscountstable.discounts")}</th>
                <th>{t("subscriptionsdiscountstable.period")}</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {discounts.map((discount, index) => (
                <tr key={index}>
                    <td>{discount.name}</td>
                    <td>{helpers.formatDate(discount.startDate)} - {helpers.formatDate(discount.endDate)}</td>
                    <td><SkynetIcon icon={'vismaicon-cancel-circle float-right'} title={t("subscriptionsdiscountstable.terminate")} onClick={() => onTerminateDiscount(discount)} /></td>
                </tr>
            ))}
        </tbody>
    </table>
}