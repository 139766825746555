import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

import CustomerSearch from '../Search/CustomerSearch';

class MainCustomerEditForm extends Component {
    
    onInputChange = (customerNumber, name, group) => {
        const { title, t, commonActions, customer, onChange } = this.props;
        if(customer != undefined && customerNumber === customer.customerNumber){
            commonActions.showWarning(t("maincustomercontainer.owncompanywarning"));
            return;
        }
        if(group === 1001){
            commonActions.showWarning(t("maincustomercontainer.1001selectedwarning"));
            return;
        }    
        onChange(title, {customerNumber: customerNumber, name: name, organizationNumber: '', group: group})
    }

    onAddAction= () => {
        const { title, onChange } = this.props;
        onChange(title, '')
    }

    onRemoveAction = () => {
        const { title, onChange } = this.props;
        onChange(title, null)
    }

    render() {
        const { title, mainCustomer, t, customer } = this.props;

        return <div className="customerConnections">
                    <h4><span className="vismaicon vismaicon-sm vismaicon-user" /> {t(title.toLowerCase() + 'container.header')}</h4>
                    { mainCustomer === null || mainCustomer === undefined ? ( 
                            <span className="vismaicon vismaicon-add-circle" onClick={ this.onAddAction } />
                        ) : (
                            <CustomerSearch 
                                onChange={this.onInputChange}
                                readonly={mainCustomer && typeof mainCustomer === 'object'}
                                onRemoveAction={this.onRemoveAction}
                                defaultSelected={{name: mainCustomer.name, customerNumber: mainCustomer.customerNumber }}
                                customer={customer}
                            />
                        )
                    }
                </div>
    }
}

export default withTranslation()(MainCustomerEditForm);