import React, { useState } from 'react';
import DateTimeReact from 'react-datetime';
import StaticTextInput from '../../StaticTextInput';
import moment from 'moment';
import '../../DateTimeInput.css';

const DateTimeInput = (props) => {

  const [showTooltip, setShowTooltip] = useState(false);

  const error = props.fieldErrorFlags && props.fieldErrorFlags[props.field];

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const onBlur = (datetime) => {
    const newValue = (!datetime || !datetime._isAMomentObject)
      ? ""
      : (props.dateFormat === false)
        ? datetime.format("HH:mm")
        : datetime.format('YYYY MM DD HH:mm');
    props.onChange(newValue);
  };

  moment.updateLocale('en', {
    week: {
      dow: 1,
    },
  });

  if (props.readonly) {
    return <StaticTextInput label={props.label} value={props.value && props.value.toString()} />;
  }

  const timeFormat = (props.showTime === false) ? "" : "HH:mm";
  const dateFormat = (props.dateFormat === undefined) ? "YYYY-MM-DD" : (props.dateFormat === false) ? false : "YYYY-MM-DD";
  const placeHolderDateFormat = props.placeholderText !== undefined
    ? props.placeholderText
    : (props.dateFormat === undefined && timeFormat === "HH:mm")
      ? "YYYY-MM-DD HH:mm"
      : (props.dateFormat === false)
        ? "HH:mm"
        : "YYYY-MM-DD";
  const closeOnSelect = props.closeOnSelect === false ? false : true;
  const value = props.dateFormat === false && props.value
    ? props.value.toString("HH:mm")
    : props.value
      ? moment(props.value)
      : undefined;

  const inputProps = {
    dateFormat: dateFormat,
    timeFormat: timeFormat,
    inputProps: { placeholder: placeHolderDateFormat },
    onChange: onBlur,
    closeOnSelect: closeOnSelect,
    value: value,
    initialValue: props.initialValue
  };


  var showErrorIcon = (error && !props.hideErrorIcon)

  return (
    <div className={`mb-12 form-group ${error ? 'has-error' : ''}`}>
      {props.label && <label>{props.label}</label>}
      <DateTimeReact
        {...inputProps}
        key={props.value} // Add key prop here
        renderInput={(props) => {
          return (
            <div className='d-flex'>
              <input {...props} value={props.value || ''} className='form-control rounded-5' />
              {showErrorIcon && (
                <div className='position-relative'>
                  <span
                    className="vismaicon vismaicon-sm vismaicon-filled vismaicon-error"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  />
                  {showTooltip && (
                    <div className="tooltip tooltip-error text-input-error-tooltip" type="tooltip">
                      <div className="tooltip-arrow"></div>
                      <div className="tooltip-inner">{error}</div>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        }}
      />
    </div>
  );
}


export default DateTimeInput;
