import React from 'react'
import BaseSearch from '../BaseSearch';
import { useTranslation } from 'react-i18next';

import './ProductSearch.css'

const TrialSearch = ({results, onSearch, onTrialClick}) => {
    const { t } = useTranslation();

        const attributes = {
            labelKey: option => `${option.companyName} ${option.userEmail} ${option.organizationNumber || ""}`, 
            options: results|| [],
            onSearch: (query) => onSearch(query),
            placeholder: t('trialsearch.placeholder'),
            onChange: (options) => options && options[0] && onTrialClick(options[0].id, options[0].companyName),
            onChangeAttr: () => {}
        };

        return (
            <div className='row'>
                <BaseSearch attributes={attributes} />
            </div>
        );
    }

export default TrialSearch;