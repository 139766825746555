import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormInput } from "../../FormComponents/Input/Input";
import SkynetIcon from "../../Fundamentals/SkynetIcon";
import routes from "../../../Constants/routes";

export function TerminationReasonForm({ terminationReasonForm, terminationReasons, terminationReasonsActions, isEdit, commonActions }) {
    const [subReasonForm, setSubReasonForm] = useState({ name: '', externalId: null });
    const navigate = useNavigate();

    const onChange = (value, key) => {
        commonActions.changeMultiFormField('terminationReasonForm', key, value);
    };

    const onSave = () => {
        const action = isEdit ? terminationReasonsActions.updateTerminationReason : terminationReasonsActions.createTerminationReason;
        action(terminationReasonForm, () => navigate(routes.icaTerminationReasons));
    };

    const onAddSubreason = () => {
        if (!subReasonForm.name) return;

        const newSubReason = { name: subReasonForm.name, externalId: subReasonForm.externalId };
        if (isEdit) {
            terminationReasonsActions.createTerminationReason(
                { data: { ...newSubReason, parentId: terminationReasonForm.data.id } },
                () => terminationReasonsActions.getTerminationReasonForEdit(terminationReasonForm.data.id)
            );
        } else {
            onChange([...terminationReasonForm.data.subReasons, newSubReason], "subReasons");
        }

        setSubReasonForm({ name: '', externalId: null });
    };

    const onDeleteSubreason = (index) => {
        const updatedSubReasons = terminationReasonForm.data.subReasons.filter((_, i) => i !== index);
        if (isEdit) {
            terminationReasonsActions.deleteSubTerminationReason(
                terminationReasonForm.data.subReasons[index].id,
                terminationReasonForm.data.id,
                () => terminationReasonsActions.getTerminationReasonForEdit(terminationReasonForm.data.id)
            );
        } else {
            onChange(updatedSubReasons, "subReasons");
        }
    };

    const renderSubReasonsTable = () => (
        <div className="col-5 pl-0">
            <table className="table table-stripe skynet-table-no-divider">
                <thead>
                    <tr>
                        <td>*Underorsak</td>
                        <td>Externt ID</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    {terminationReasonForm.data.subReasons.map((subReason, index) => (
                        <tr key={index}>
                            <td>{subReason.name}</td>
                            <td>{subReason.externalId}</td>
                            <td>
                                <div className="float-right">
                                    <SkynetIcon icon={'vismaicon-delete'} onClick={() => onDeleteSubreason(index)} />
                                </div>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td className="p-12">
                            <FormInput
                                value={subReasonForm.name}
                                placeholder={"Underorsak..."}
                                onChange={(value) => setSubReasonForm({ ...subReasonForm, name: value })}
                            />
                        </td>
                        <td className="p-12">
                            <FormInput
                                value={subReasonForm.externalId}
                                placeholder={"Externt ID..."}
                                onChange={(value) => setSubReasonForm({ ...subReasonForm, externalId: value || null })}
                            />
                        </td>
                        <td>
                            <div className="float-right">
                                <SkynetIcon icon={'vismaicon-add'} onClick={onAddSubreason} />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );

    const renderMainReasonDropdown = () => (
        <div className="form-control skynet-form-input">
            <label>Huvudorsak</label>
            <select
                className="form-control"
                onChange={(e) => onChange(e.target.value, 'parentId')}
                value={terminationReasonForm.data.parentId}
            >
                <option value="">Välj huvudorsak...</option>
                {terminationReasons.map((reason, index) => (
                    <option key={index} value={reason.id}>{reason.name}</option>
                ))}
            </select>
        </div>
    );

    return (
        <div>
            <div className="col-12">
                <div className="d-flex flex-column" style={{ gap: "24px" }}>
                    <div className="d-flex flex-column col-4 pl-0" style={{ gap: "24px" }}>
                        <FormInput
                            label="*Namn"
                            value={terminationReasonForm.data.name}
                            onChange={(value) => onChange(value, 'name')}
                        />
                        <FormInput
                            label="Externt ID"
                            value={terminationReasonForm.data.externalId}
                            onChange={(value) => onChange(value || null, 'externalId')}
                        />
                        {terminationReasons && !isEdit && !terminationReasonForm.data.subReasons.length && renderMainReasonDropdown()}
                    </div>
                    {!terminationReasonForm.data.parentId && renderSubReasonsTable()}
                </div>
            </div>
            <div className="mt-24">
                <button className="btn btn-primary" onClick={onSave}>Spara</button>
                {isEdit && (
                    <button className="btn btn-primary btn-destructive" onClick={() => terminationReasonsActions.deleteTerminationReason(terminationReasonForm.data.id, () => navigate(routes.icaTerminationReasons))}>Radera</button>
                )}
                <button className="btn btn-secondary" onClick={() => navigate(routes.icaTerminationReasons)}>Avbryt</button>
            </div>
        </div>
    );
}
