import commonActions from '../Actions/commonActions'

const checkStatus = (response) => {

  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  throw {response: response};
};

const addCredentialsAndHeader = (httpRequest, noHeaders) => {
  if (httpRequest)
    httpRequest.credentials = 'include'
  else
    httpRequest = {
      method: "GET",
      credentials: 'include'
    }
  if (!httpRequest.headers && !noHeaders)
    httpRequest.headers = { 'Content-Type': 'application/json' }
  return httpRequest;
}

export const httpFetchNoEncode = (url, dispatch, httpRequest, noHeaders) => _httpFetch(url, dispatch, httpRequest, noHeaders);
export const httpFetch = (url, dispatch, httpRequest, noHeaders) => _httpFetch(encodeURI(url), dispatch, httpRequest, noHeaders);

const _httpFetch = (url, dispatch, httpRequest, noHeaders) => {
  httpRequest = addCredentialsAndHeader(httpRequest, noHeaders);


  return fetch(url, httpRequest)
    .then(checkStatus)
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.Error) {
        throw json //throw BusinessExceptions
      } else {
        if (dispatch) {
          dispatch(commonActions.progressLoader(false))
        }
        return json;
      }
    })
    .catch(error => {

      if (dispatch) {
        dispatch(commonActions.progressLoader(false))
      }

      if (error.Error) {
        if (error.ErrorMessage) {
          const title = error.Title || 'Business error';
          if (error.Error === 'BusinessWarningException')
            dispatch(commonActions.showWarning(error.ErrorMessage, title, -1));
          else
            dispatch(commonActions.showError(error.ErrorMessage, title));
        }

        if (error.RedirectTo) {
          window.location.href = error.RedirectTo;
        }
        throw error.Error; //rethrow BusinessExceptions and terminate promise chain
      }

      if ((error.response && error.response.status === 401) || error.toString().indexOf('TypeError') !== -1) {
        dispatch(commonActions.reset());
      }
      else if (error.response && error.response.status === 403) {
        dispatch(commonActions.showError("You are not allowed to proceed. Please contact Business Automation due Selfservice-portal.", "Access denied"))
      } else {
        if (error.response) {
          dispatch(commonActions.showError(error.response.statusText))
        }
      }

      throw error;
    })
};