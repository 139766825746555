import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import orderActions from '../../Actions/orderActions';
import commonActions from '../../Actions/commonActions';
import * as calcTypes from '../../Constants/priceCalculationTypes';
import Grid from '../../Components/Grid/Grid';
import { BlikkCustomerInfoCard } from '../../Components/OrderPlacement/ProductComponents/Blikk/BlikkCustomerInfoCard';
import { BlikkProductInfoCard } from '../../Components/OrderPlacement/ProductComponents/Blikk/BlikkProductInfoCard';
import { BlikkTransactionsCard } from '../../Components/OrderPlacement/ProductComponents/Blikk/BlikkTransactionsCard';
import { BlikkAdditionalUsersCard } from '../../Components/OrderPlacement/ProductComponents/Blikk/BlikkAdditionalUsersCard';
import { BlikkTrialSearch } from '../../Components/OrderPlacement/ProductComponents/Blikk/BlikkTrialSearch';
import { BlikkProductSummaryCard } from '../../Components/OrderPlacement/ProductComponents/Blikk/BlikkProductSummaryCard';

function BlikkProductContainer({...props}){
  const { product } = props;

  return (
    <>
      {(product.blikkAccountFound || product.isTrial) ? <>
        <Grid.Row>
          <Grid.Row.Column width={4}>
            <BlikkCustomerInfoCard {...props} />
          </Grid.Row.Column>
          <Grid.Row.Column>
            <BlikkProductInfoCard {...props} />
          </Grid.Row.Column>
          <Grid.Row.Column>
            <BlikkTransactionsCard {...props} />
          </Grid.Row.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Row.Column width={4}>
            <BlikkAdditionalUsersCard {...props} />
          </Grid.Row.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Row.Column>
            <BlikkProductSummaryCard {...props} priceCalcType={calcTypes.priceCalculationTypes.order} />
          </Grid.Row.Column>
        </Grid.Row>
      </>
        : <Grid.Row>
          <Grid.Row.Column>
            <BlikkTrialSearch {...props} />
          </Grid.Row.Column>
        </Grid.Row>}
    </>
  );
};

const mapStateToProps = (state) => ({
  orderId: state.OrderReducer.orderId,
  order: state.OrderReducer.order,
  customer: state.CustomerReducer.selectedCustomer,
  user: state.CommonReducer.user,
  product: state.OrderReducer.product && state.OrderReducer.product,
  licenseTypes: state.OrderReducer.blikkLicenseTypes,
  editOrderline: state.OrderReducer.editOrderline,
  trialSearchResults: state.OrderReducer.trialSearchResults,
});

const mapDispatchToProps = (dispatch) => ({
  commonActions: bindActionCreators(commonActions, dispatch),
  orderActions: bindActionCreators(orderActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(BlikkProductContainer);