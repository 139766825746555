import { VonAccessRights } from "../../../../Constants/VON/EmployeeForm/vonAccessRights";
import { VonEmployeeTypes } from "../../../../Constants/VON/EmployeeForm/vonEmployeeTypes";
import { RadioButtons } from "../../../FormComponents/RadioButtons";

export default function VonEditAccessRightsForm({ editVonEmployeeForm, onChange }) {

    if (!editVonEmployeeForm) return null;
    const { data } = editVonEmployeeForm;

    const onChangeAccessRight = (value) => {

        if (value !== VonAccessRights.Administrator) {
            onChange('editVonEmployeeForm', null, "newMainContactId");
        }
        onChange('editVonEmployeeForm', value, 'onlineRoleId');
    }

    let radioButtons = [
        { id: 'access-type-1', label: 'Administratör - Logga in till tjänsterna, administrerar användare, ser fakturainformation, kontaktperson', disabled: data.isPrimaryContact || data.employeeTypeId === VonEmployeeTypes.Auditor.id, value: VonAccessRights.Administrator },
        { id: 'access-type-2', label: 'Användare - Logga in till tjänsterna', disabled: data.isPrimaryContact, value: VonAccessRights.User },
        { id: 'access-type-3', label: 'Inaktiv - Kan inte logga in på Visma Spcs webbplaters', disabled: data.isPrimaryContact, value: VonAccessRights.Inactive },
        { id: 'access-type-4', label: 'Radera - Användaren kommer markeras som SoftDelete', disabled: data.isPrimaryContact, value: VonAccessRights.Delete }
    ]

    return <div>
        <fieldset className="d-grid col-lg-6 gap-16 mb-0">
            <legend>Behörighet på Visma Spcs webbplatser</legend>
            <div className="d-grid gap-8">
                <RadioButtons items={radioButtons} onChange={(value) => onChangeAccessRight(value)} value={data.onlineRoleId} />
            </div>
        </fieldset>
    </div>
}