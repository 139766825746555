import React from 'react';
import {useTranslation} from "react-i18next";
import {Dropdown} from "../../FormComponents/Dropdown"
import SkynetIcon from "../../Fundamentals/SkynetIcon";
import {FormInput} from "../../FormComponents/Input/Input";

export function InternalInfoEditContent({ dropdownlists, commonActions, form, actionType }) {
    const { t } = useTranslation();
    const [selectedTargetGroup, setSelectedTargetGroup] = React.useState();

    const onDropdownChange = (field, e) => {
        commonActions.changeMultiFormField(form.id, field, e.target.value);
    }

    const onDelete = (index) => {
        commonActions.changeFormArrayField(form.id, "targetGroups", form.data.targetGroups, index, '-1');
    }

    const onAdd = () => {
        if (!selectedTargetGroup)
            return;

        let name = dropdownlists?.TargetGroup.find(x => x.guid === selectedTargetGroup)?.name;
        const newTargetGroup = { name: name, id: selectedTargetGroup};

        commonActions.changeFormArrayField(form.id, "targetGroups", form.data.targetGroups, form.data.targetGroups.length, newTargetGroup);
    }

    const onTargetGroupChange = (value) => {
        setSelectedTargetGroup(value);
    }

    return (<fieldset className="d-grid col-lg-8 gap-16">
            <legend>{t("internalinfocontainer.header")}</legend>
            <Dropdown label={t("internalinfocontainer.customergroup")} options={dropdownlists?.CustomerGroup}
                      placeholder={t("internalinfocontainer.selectcustomergroup")}
                      onChange={onDropdownChange.bind(this, 'customerGroup')}/>
            <Dropdown label={t("internalinfocontainer.customertype")} options={dropdownlists?.CustomerType}
                      placeholder={t("internalinfocontainer.selectcustomertype")} onChange={onDropdownChange.bind(this, 'customerType')}/>
            <div>
            <label>{t("internalinfocontainer.targetgroup")}</label>
            <table className="table table-stripe skynet-table-no-divider">
                <tbody>
                {form.data?.targetGroups?.map((targetGroup, index) => (
                    <tr key={index}>
                        <td>{targetGroup.name}</td>
                        <td>
                            <div className="float-right">
                                <SkynetIcon icon={'vismaicon-delete'} onClick={() => onDelete(index)}/>
                            </div>
                        </td>
                    </tr>
                ))}
                <tr>
                    <td className="p-12">
                        <Dropdown options={dropdownlists?.TargetGroup} valueProperty={"guid"}
                                  property={"name"} placeholder={t("internalinfocontainer.selecttargetgroup")}
                                  onChange={onTargetGroupChange.bind(this)}/>
                    </td>
                    <td>
                        <div className="float-right">
                            <SkynetIcon icon={'vismaicon-add'} onClick={onAdd}/>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table></div>
        </fieldset>
    )
}