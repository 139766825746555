import { commonActionTypes as actionTypes } from '../ActionTypes/commonActionTypes'

export const initialState = {
    alert: null,
    isLoading: false,
    selectedHeader: undefined,
    webNotification: { message: null, isShown: false },
    user: null,
    authenticating: true,
    forms: null,
    userSearchResults: null,
    customerSearchResults: null,
    shortcuts: undefined,
    pongToggle: false,
    sideMenuOpen: true,
    isProduction: false,
    externalUrls: {}
}

export default function CommonReducer(state = initialState, action) {

    let newState;

    switch (action.type) {

        case actionTypes.SHOW_ALERT:
            newState = { alert: { type: action.alertType, title: action.title, message: action.message, timeout: action.timeout } };
            break;

        case actionTypes.HIDE_ALERT:
            newState = { alert: null };
            break;

        case actionTypes.LOADER_COMPONENT_VISIBILITY:
            newState = { isLoading: action.showComponent };
            break;

        case actionTypes.STORE_USER_INFO:
            newState = { user: action.user };
            break;

        case actionTypes.FINISH_AUTHENTICATION:
            newState = { authenticating: false }
            break;

        case actionTypes.USER_SEARCH_RESULTS:
            newState = { userSearchResults: action.data }
            break;

        case actionTypes.CUSTOMER_SEARCH_RESULTS:
            newState = { customerSearchResults: action.data }
            break;

        case actionTypes.RESELLER_SEARCH_RESULTS:
            newState = { resellerSearchResults: action.data }
            break;

        case actionTypes.COMPANY_EXPENSE_PARTNER_SEARCH_RESULTS:
            newState = { companyExpensePartnerSearchResults: action.data }
            break;

        case actionTypes.LOAD_DROPDOWN_LIST:
            newState = { dropdownlists: { ...state.dropdownlists, [action.listname]: action.list } }
            break;

        case actionTypes.PUSH_NOTIFICATION:
            newState = { webNotification: { message: action.message, isShown: action.isShown } }
            break;

        case actionTypes.RESET_COMMON:
            newState = initialState;
            break;

        case actionTypes.SHOW_MODAL:
            newState = { showModal: action.isShown }
            break;

        case actionTypes.SET_STARTUP_PARAMETERS:
            newState = { isProduction: action.payload.isProduction }
            break;

        case actionTypes.SET_EXTERNAL_URLS:
            newState = { externalUrls: action.payload }
            break;

        case actionTypes.SET_OPEN_HEADER:
            newState = { openHeader: action.headerTitle }
            break;
        
        case actionTypes.TOGGLE_PONG:
            newState = { pongToggle: action.pongToggle}
            break;

        case actionTypes.START_EDITING_FORM:
            if (action.data.body && action.data.rules) { //email template
                action.data.body_copy = action.data.body

                action.data.rules.forEach(({ id, rule, label }) => {
                    if (!action.data[id])
                        action.data[id] = ""

                    if (rule === "required") {

                        const newRule = {
                            "inputId": id,
                            rule,
                            "message": `"${label.replace('*', '').trim()}" is required/invalid`
                        }
                        action.validationRules = [newRule, ...action.validationRules]
                    }
                    action.data.body = action.data.body.replace(`[${id}]`, action.data[id] || '')
                });
            }

            newState = {
                forms:
                    { ...state.forms, [action.form]: { id: action.form, changed: false, data: action.data, validationRules: action.validationRules } }
            }
            break;

        case actionTypes.CANCEL_EDITING_FORM:
            newState = { forms: { ...state.forms } }
            delete newState.forms[action.formId]
            break;

        case actionTypes.CHANGE_FORM_FIELD_VALUE:
            newState = {
                forms:
                {
                    ...state.forms, [action.form]:
                    {
                        ...state.forms[action.form], changed: true, data:
                            { ...state.forms[action.form].data, [action.field]: action.value }
                    }
                }
            }
            break;

        case actionTypes.STORE_FIELD_ERROR_FLAGS:
            newState = {
                forms:
                {
                    ...state.forms, [action.form]:
                    {
                        ...state.forms[action.form], fieldErrorFlags: action.fieldErrorFlags
                    }
                }
            }
            break;
            
        case actionTypes.CHANGE_BUDGET_FORM_FIELD_VALUE:
            newState = {
                forms:
                {
                    ...state.forms, [action.form]:
                    {
                        ...state.forms[action.form], changed: true
                    }
                }
            }
            break;
        case actionTypes.SHOW_VON_WARNING:
            newState = { vonWarning: action.isShown }
            break;

        case actionTypes.SAVE_STATUS:
            newState = { status: action.payload }
            break;

        case actionTypes.STORE_SELECTED:
            newState = { selectedCustomerAndType: action.data }
            break;

        case actionTypes.STORE_DEMO_PRODUCT:
            newState = { selectedDemoProductId: action.value }
            break;
            
        case actionTypes.STORE_TRANSACTION_SERVICES:
            newState = { transactionServices: action.data }
            break;

        case actionTypes.STORE_USER_SHORTCUTS:
            newState = { userShortcuts: action.shortcuts }
            break;

        case actionTypes.STORE_USER_SIGNATURE:
            newState = { userSignature: action.userSignature }
            break;
        case actionTypes.STORE_DESKTOP_VERSION:
            newState = { selectedDesktopVersionId: action.value }
            break;
        case actionTypes.STORE_TRANSACTION_SERVICES_TEAM:
            newState = { transactionServicesTeam: action.data }
            break;
        case actionTypes.STORE_NOTIFICATIONS:
            newState = { notifications: action.data }
            break;
        case actionTypes.STORE_RELEASE_VERSION:
            newState = { releaseVersion: action.data }
            break;
        case actionTypes.STORE_RELEASE_VERSION_ALL:
            newState = { releaseVersionList: action.data }
            break;
        case actionTypes.STORE_NOTIFICATIONS_COUNT:
            newState = { notificationsCount: action.data }
            break;
        case actionTypes.STORE_MOVIE_MAILS:
            newState = { movieMails: action.data }
            break;
        case actionTypes.TOGGLE_SIDE_MENU:
            if(action.value !== undefined) {
                if(state.sideMenuOpen !== action.value)
                    newState = { sideMenuOpen: !state.sideMenuOpen}
            }
            else
              newState = { sideMenuOpen: !state.sideMenuOpen}
            break;
        default:
            return state
    }

    if (newState) {
        return { ...state, ...newState }
    }

    return state;
}
