import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import customerActions from '../../Actions/customerActions'
import commonActions from '../../Actions/commonActions'

import withModalForm from '../../Components/HOC/withModalForm'

import BlikkProducts from '../../Components/Customer/BlikkProducts'
import ProductTerminationModal from '../../Components/Customer/ProductTerminationModal'
import CompanyExpenseAddAdminUserModel from '../../Components/Customer/CompanyExpenseAddAdminUserModel'
import AlertSmall from '../../Components/AlertSmall';

class ThirdPartyProductsContainer extends Component {

    componentDidMount() {
        const { customerActions, customer } = this.props;

        customerActions.getPendingTerminationRequests(customer.customerNumber);
        customerActions.getBlikkProducts(customer.customerNumber);
    }

    refreshProducts() {
        const { companyExpenseProductLoading, companyExpenseCustomerNumber, blikkLoading, blikkCustomerNumber } = this.props;
        const { customerActions, customer } = this.props;

        if (!blikkLoading && customer.customerNumber != blikkCustomerNumber && blikkCustomerNumber != undefined) {
            customerActions.getBlikkProducts(customer.customerNumber);
        }

        if (!companyExpenseProductLoading && customer.customerNumber != companyExpenseCustomerNumber && companyExpenseCustomerNumber != undefined) {
            customerActions.getCompanyExpenseProducts(customer.customerNumber, customer.organizationNumber);
        }
    }
    
    render() {

        const { blikkProducts, blikkResponse, blikkLoading } = this.props;
        const { customer, terminationForm, reasons, customerActions, pendingTerminationRequests, adminUserForm } = this.props;
        const { payload, isModalActive, onFieldChange, onCloseClick, onOpenModal } = this.props;

        const { t } = this.props;


        let showBlikkProducts = true;
        let showBlikkNoResonseMessage = false;
        this.refreshProducts()
        const renderLoadingbar = () => {
            return <div className='loader'>Loading...</div>
        }

        const renderThirdPartyProductsNoResponse = (productName) => {
            return <AlertSmall alert={{ title: t("thirdpartyproductscontainer.cantaccess") + productName, type: "warning", message: t("thirdpartyproductscontainer.message") }}></AlertSmall>
        }

        if (!blikkLoading && !blikkResponse)
            showBlikkNoResonseMessage = true;

        if (!blikkProducts || blikkProducts.length === 0 || blikkLoading)
            showBlikkProducts = false;

        if (!showBlikkProducts && !blikkLoading) {
            return <h4>{t("thirdpartyproductscontainer.nothirdpartyproducts")}</h4>
        }

        if (!showBlikkProducts && !blikkLoading)
            return null;


        if (blikkLoading)
            return renderLoadingbar();

        var terminationModalAttr = {
            form: terminationForm,
            reasons: reasons,
            onChange: onFieldChange,
            onClose: onCloseClick,
            onLoad: () => customerActions.getCompanyExpenseTerminationTemplate(customer.customerNumber, payload),
            onSave: () => customerActions.cancelCompanyExpenseAgreement(terminationForm, () => onCloseClick(terminationForm.id))
        }

        var addAdminUserData = {
            customerNumber: customer.customerNumber,
            email: "",
            firstName: "",
            lastName: "",
            langCode: "sv",
            languageList: [{ id: "sv", name: "Swedish" }, { id: "en", name: "English" }]
        };

        var addAdminUserModalAttr = {
            form: adminUserForm,
            reasons: reasons,
            onChange: onFieldChange,
            onClose: onCloseClick,
            onLoad: () => customerActions.showAddCompanyExpenseAdminUserEditForm(addAdminUserData),
            onSave: () => customerActions.addCompanyExpenseAdminUser(adminUserForm, () => onCloseClick(adminUserForm.id))
        }

        return <>
            {isModalActive('terminate') && <ProductTerminationModal {...terminationModalAttr} />}
            {isModalActive('adminuser') && <CompanyExpenseAddAdminUserModel {...addAdminUserModalAttr} />}
            {showBlikkProducts && <BlikkProducts products={blikkProducts}></BlikkProducts>}
            {showBlikkNoResonseMessage && renderThirdPartyProductsNoResponse("Visma Tid Smart or Pro")}

            {(blikkLoading) && (showBlikkProducts) && renderLoadingbar()}
        </>
    }
}

function mapStateToProps(state) {
    return {
        terminationForm: state.CommonReducer.forms && state.CommonReducer.forms.CancelCompanyExpenseAgreement,
        reasons: state.CommonReducer.dropdownlists && state.CommonReducer.dropdownlists['SaasTerminationReasons'],
        customer: state.CustomerReducer.selectedCustomer,
        pendingTerminationRequests: state.CustomerReducer.pendingTerminationRequests,
        adminUserForm: state.CommonReducer.forms && state.CommonReducer.forms.addCompanyExpenseAdminUser,
        blikkProducts: state.CustomerReducer.blikkProducts,
        blikkResponse: state.CustomerReducer.blikkResponse,
        blikkLoading: state.CustomerReducer.blikkLoading,
        blikkCustomerNumber: state.CustomerReducer.blikkCustomerNumber,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators(customerActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    }
}

export default withModalForm(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ThirdPartyProductsContainer)));