import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next';

import actionLogActions from '../Actions/actionLogActions'
import routes from '../Constants/routes'

class ActionLogContainer extends Component {

    componentDidMount() {
        this.props.actionLogActions.getMyActionLog();
    }

    renderNoData() {
        const {t} = this.props
        return (<p>{t('myactionlogcontainer.noentriesfound')}</p>);
    }

    render() {
        const {t} = this.props
        if(!this.props.actionLogs) {
            return null;
        }

        if(this.props.actionLogs.length === 0) {
            return this.renderNoData();
        }
    
        return (
                <div className="col-xs-12">
                    <table className="table table-stripe table-actionLogs">
                        <thead>
                            <tr>
                                <th className="text-left">{t('myactionlogcontainer.time')}</th>
                                <th className="text-left">{t('myactionlogcontainer.action')}</th>
                                <th className="text-left">{t('myactionlogcontainer.description')}</th>
                                <th className="text-left">{t('myactionlogcontainer.customernumber')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.props.actionLogs.map((actionLog, index) => {
                                return <tr key={index}>
                                            <td style={{whiteSpace: "nowrap"}}>{actionLog.createDate}</td>
                                            {actionLog.emailActionLogId && !actionLog.hideEmailLink
                                                ? <td><Link to={routes.emailHistory(actionLog.emailActionLogId)}>{actionLog.action}</Link></td>
                                                : <td>{actionLog.action}</td>}
                                            <td>{actionLog.description}</td>
                                            <td><span><Link to={routes.customerDetails(actionLog.customerNumber)}>{actionLog.customerNumber}</Link></span></td>
                                        </tr>
                            })}
                        </tbody>
                    </table>
                </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        actionLogs: state.ActionLogReducer.actionLogs
    }
}

function mapDispatchToProps(dispatch) {
   return {
       actionLogActions : bindActionCreators(actionLogActions, dispatch) 
    } 
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ActionLogContainer));