import { useTranslation } from "react-i18next";
import { ProductSummaryCard } from "../ProductSummary";
import helpers from "../../../../Utils/helpers";

export function VossProductSummaryCard({ ...props }) {
    const { product } = props;
    const { t } = useTranslation();

    return <ProductSummaryCard product={product}>
                <ProductSummaryCard.Table>
                    <ProductSummaryCard.Table.THeader>
                        <th className="col-6">{t('productsummary.main')}</th>
                        <th className='col-2'>{t('productsummary.initialprice')}</th>
                        <th className='col-2'>{t('productsummary.discountamount')}</th>
                        <th className='col-2'>{t('productsummary.totalprice')}</th>
                    </ProductSummaryCard.Table.THeader>
                    <ProductSummaryCard.Table.TBody>
                        {product.priceCalculation.includedProductGroups.map((product) => (
                            <tr>
                                <td scope="row">{product.description}</td>
                                <td>{helpers.currencyFormatter(product.initialPrice ?? 0)}</td>
                                <td>{helpers.currencyFormatter(product.discountAmount ?? 0)}</td>
                                <td>{helpers.currencyFormatter(product.totalAmount ?? 0)}</td>
                            </tr>
                        ))}
                    </ProductSummaryCard.Table.TBody>
                </ProductSummaryCard.Table>
                {(product.priceCalculation.optionalProductGroups && product.priceCalculation.optionalProductGroups.length > 0) && <ProductSummaryCard.Table>
                    <ProductSummaryCard.Table.THeader>
                        <th className="col-6">{t('productsummary.addons')}</th>
                        <th className='col-2'></th>
                        <th className='col-2'></th>
                        <th className='col-2'></th>
                    </ProductSummaryCard.Table.THeader>
                    <ProductSummaryCard.Table.TBody>
                        {product.priceCalculation.optionalProductGroups.map((productGroup) => (
                            <tr>
                                <td scope="row">{productGroup.description}</td>
                                <td>{helpers.currencyFormatter(productGroup.initialPrice ?? 0)}</td>
                                <td>{helpers.currencyFormatter(productGroup.discountAmount ?? 0)}</td>
                                <td>{helpers.currencyFormatter(productGroup.totalAmount ?? 0)}</td>
                            </tr>
                        ))}
                    </ProductSummaryCard.Table.TBody>
                </ProductSummaryCard.Table>}
                <ProductSummaryCard.Table className="border-0 shadow-0">
                    <ProductSummaryCard.Table.THeader>
                        <th className="col-6">{t('productsummary.total')}</th>
                        <th className='col-2'></th>
                        <th className='col-2'></th>
                        <th className='col-2'>{helpers.currencyFormatter(product.priceCalculation.totalInitialPrice)}</th>
                    </ProductSummaryCard.Table.THeader>
                </ProductSummaryCard.Table>
                <div className="d-flex d-flex flex-row justify-content-end my-24 mr-24 align-items-center">
                    <ProductSummaryCard.Actions {...props} />
                </div>
            </ProductSummaryCard>
}